.main-categories-filter {
    .main-category-item {
        text-decoration: none !important;
        position: relative;
            transition: all 300ms ease;
        h2 {
            position: relative;
            z-index: 2;
            max-width: 140px;
            color: $light-font-color;
            font-size: 30px;
            font-weight: 700;
            transition: all 300ms ease;
        }
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $dark-bg-color;
            opacity: .8;
        }
        &:after {
            position: absolute;
            z-index:3;
            content: "";
            bottom:0;
            left: 0;
            height: 3px;
            width: 0;
            transition: all 300ms ease;
            display: block;
            background-color: $main-color;
        }
        &:hover, &.active {
            &:after {
                width: 100%;
            }
            h2 {
                color: $main-color;
            }
        }
    }
}

.category-filter {

    .category-filter-item-lvl-1, .category-filter-item  {
        color: $font-color;
        transition: all 300ms ease;
        text-decoration: none !important;
        h3 {
            font-size: 22px;
            font-weight: 700;
            color: $font-color;
            transition: all 300ms ease;
        }
        &:hover, &.active {
            color: $brown;
            h3 {
                color: $main-color;
            }
        }
    }
    .category-filter-item {

        .subcategories-list {
            margin: 0;

            li.sub-cat {
                padding-left: 10px;
            }
        }
    }

}

.category-header {
    position: relative;
    background: {
        color: $pastel-1;
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 100%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 45%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
    h1 {
        position: relative;
        z-index: 2;
        display: inline-block;
        font-size: 48px;
        font-weight: 900;
        position: relative;
    }
}

.category-detail {
    position: relative;
    /*overflow-x: hidden;*/

    .category-detail-main {

        #overview-products .product-square .product-info {
            padding: 10px 0 15px 0 !important;

            .product-price {
                /*font-family: $main-font;*/
                font-family: 'Calibri', sans-serif;
            }

            .product-title,
            .productpricespan {
                font-size: 18px !important;
                font-weight: 300;
            }
        }

        .category-filters-container {

            h4 {
                /*font-family: $main-font;*/
                font-family: $main-font2;
                color: $brown;
                font-weight: 400;
                margin-bottom: 0 !important;
                padding-bottom: 0;

                > div {

                    > div:first-child {
                        font-family: $main-font2;
                        color: $brown;
                        font-weight: 400;
                    }

                    > div:last-child {
                        display: none;
                    }
                }
            }

            .category-filter {

                .category-filter-item > div {
                    flex-wrap: inherit;
                    gap: 10px;
                }

                .category-filter-item[aria-expanded="true"] > div > svg {
                    transform: rotate(180deg);
                    transition: 0.25s ease-in-out;
                }

                .category-filter-item[aria-expanded="false"] > div > svg {
                    transform: rotate(0deg);
                    transition: 0.25s ease-in-out;
                }

                a.main-cat {
                    font-family: $main-font3;
                    font-size: 20px;
                    line-height: 42px;
                }

                .sub-cat > a  {
                    padding-left: 0;
                    padding-right: 0;

                    > div {
                        /*font-family: $main-font;*/
                        font-family: 'Calibri', sans-serif;
                        line-height: 18px;
                        font-size: 16px;
                    }
                }

                .filter-form label {
                    font-family: $main-font;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 300;
                }
            }

            .category-filter:last-child {

                > div:nth-last-child(1),
                > div:nth-last-child(2) {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(-25%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(20%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-right.png");
                repeat: repeat-y;
                position: right;
            }
        }
    }
}

.home-contact-section .home-contact {

    .title-top {
        font-family: $main-font;
    }

    .title-bottom {
        font-family: $main-font;
    }
}

.prevprice {
    display: inline-block;
    margin-right: 10px;
}

.product-price-div {
    text-align: left;
}

@include media-breakpoint-up(md){
    .collapse.collapse-on-mobile{
        display: block;
        height: auto !important;
        visibility: visible;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .category-detail .category-detail-main > div > div:first-child {
        padding-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .category-detail > .category-detail-main .category-filters-container {
        padding-left: 0;
    }
}
