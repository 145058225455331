@import 'vendor/bootstrap/bootstrap';
@import '~uikit/src/scss/variables';
@import '~uikit/src/scss/uikit';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/slick-slider/slick',
'vendor/slick-slider/slick-theme';

@import "variables/sizes",
"variables/colors",
"variables/fonts";

@import "global/global",
"global/form/checkbox",
"global/form/slider";
@import "layout/header",
"layout/nav",
"layout/footer";

@import "pages/home",
"pages/product",
"products/categories",
"products/product-list",
"products/detail",
"products/related";

@import 'checkout/cart';


button:focus {
    outline: none !important;
}

$font1: snell-regular, sans-serif;
$font2: roboto-condensed, sans-serif;
$font3: lato, sans-serif;

[class*=uk-animation-] {
    animation-duration: 0.5s !important;
}

html {
    overflow-x:hidden;
}
html, body {
    font-family: $main-font3;
    color: $black;
    font-size: 20px;
    line-height: 34px;
    /*text-align: justify;*/
    overflow-x: hidden;
    font-weight: 400;
    /*background: #000;*/
}

.offerte-container .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #000;
}

.offerte-container .select2-container, .offerte-container .select2-dropdown {
    background-color: transparent !important;
}

body .alert-success {
    color: #fff;
    background-color: #af8a4e;
    border-color: #af8a4e;
}

.alert .btn-outline-success {
    color: #fff;
    border-color: #fff;
}

.alert .btn-outline-success:hover,
.alert .btn-outline-success:focus {
    box-shadow: none;
}

.alert .btn-outline-success:hover,
.alert .btn-outline-success:not(:disabled):not(.disabled):active:focus {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
}

body h1, body h2, body h3, body h4, body h5, body h6,
.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6,
h1, h2, h3, h4, h5, h6 {
    font-family: $main-font2;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    color: $brown;
}

.over-ons-bottom h2, .over-ons-bottom h2 span {
    color: #000;
}


body h1 {
    font-size: 32px;
    line-height: 44px;
}

.home-header .header-col-2 p {

}

.home-header > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-aanbod-titel h2 {
    text-align: center;
    font-size: 40px;
    line-height: 55px;
    padding-bottom: 20px;
}

.home-inspiration h2 {
    text-align: center;
    font-size: 32px;
    line-height: 44px;
    padding-bottom:40px;
}

.home-familybusiness-col2 h2 {
    font-size: 32px;
    line-height: 44px;
}

.home-familybusiness-col2 h3 {
    font-family: $main-font4;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 49px;
    color: $black;
    margin-top: 10px;
    margin-bottom: 30px;
    text-transform: none;
}

body h3, body h4, body h5, body h6,
.uk-h3, .uk-h4, .uk-h5, .uk-h6,
h3, h4, h5, h6 {
    text-transform: none;
}

.home-familybusiness-col2 h3 span {
    display: block;
    padding-left: 40px;
}

.home-offer-slider-desktop-inner .txt-container .row {
    margin-left: 0px;
    margin-right: 0px;
}

.home-offer-slider-desktop-inner .title {
    font-family: $main-font2;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    padding-top: 10px;
}

.home-offer-slider-desktop-inner .price {
    font-family: $main-font1;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    color: $black;
}

.home-offer-slider-desktop-inner .image-container,
.home-offer-slider-desktop-inner .txt-container {
    position: relative;
}

.home-offer-slider-desktop-inner .image-container .productlink,
.home-offer-slider-desktop-inner .txt-container .productlink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.home-inspirerende-merken p img + img {
    margin-left: 60px;
}

.home-inspirerende-merken-col1 p {
    font-family: $main-font4;
    font-style: normal;
    font-weight: 400;
    font-size: 69px;
    line-height: 78.5%;
    color: $black;
}

.home-aanbod-cta {
    text-align: center;
}

.home-aanbod-cta a.cta,
.home-snelle-levering a.cta,
.home-familybusiness a.cta,
.over-ons-bottom-col1 a.cta {
    font-family: $main-font1;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    /*background-color: $black;*/
    padding: 15px 55px;
    color: $white;
    display: inline-block;
    margin-top: 20px;
    position: relative;
    z-index: 0;
}

.home-aanbod-cta a.cta {
    margin-top: 40px;
    display:inline-block;
}

.home-aanbod-cta a.cta:hover,
.home-aanbod-cta a.cta:focus,
.home-snelle-levering a.cta:hover,
.home-snelle-levering a.cta:focus,
.home-familybusiness a.cta:hover,
.home-familybusiness a.cta:focus,
.over-ons-bottom-col1 a.cta:hover,
.over-ons-bottom-col1 a.cta:focus {
    /*background-color: $brown;*/
    color: $white;
    text-decoration: none;
}

.over-ons-bottom-col1 h2 {
    /*font-family: 'Allison';*/
    font-family: $main-font4;
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 55px;
    text-transform: none;
}

.over-ons-bottom-col1 h2 span {
    padding-left: 100px;
    display: block;
}

.over-ons-bottom-col1 h2 + p {
    max-width: 530px;
    margin-top: 40px;
}

.cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $black;
    transition: all 0.3s;
}

.cta:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
}
.cta::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: $brown;
    border: 1px solid $brown;
    transform: scale(1.2,1.2);
    /*color: $white;*/
}
.cta:hover::after {
    opacity: 1;
    transform: scale(1,1);
}



.home-aanbod .uk-slidenav {
    padding: 20px 40px;
    top: calc(50% - 35px);
}

.home-inspiration .uk-slidenav {
    padding: 20px 40px;
    top: calc(50% - 0px);
}

.home-aanbod .uk-slidenav svg, .home-inspiration .uk-slidenav svg {
    width: 18px;
    height: 30px;
}


.home-image-slider > div.container {
    max-width: none;
    padding-left: 0px;
    padding-right: 0px;
}

.home-inspiration-slider-desktop-inner li a img {
    max-height: 330px;

}


.home-fashion-slider-desktop {
    /*max-width: 84.30vw;*/
    max-width: 100vw;
}

.home-fashion-slider-desktop .uk-position-bottom-right {
    width: 128px;
    height: 64px;
    background: $black;
}

.home-fashion-slider-desktop .uk-position-bottom-right a.uk-slidenav {
    background: $black;
    padding: 5px 20px;
}

.home-fashion-slider-desktop .uk-position-bottom-right a.uk-slidenav svg {
    width: 18px;
    height: 30px;
}

.home-fashion-slider-desktop .uk-position-bottom-right a.uk-slidenav svg polyline {
    stroke: rgba(255, 255, 255, 0.75) !important;
}

.home-fashion-slider-desktop .uk-position-bottom-right a.uk-slidenav:hover svg polyline {
    stroke: rgba(255, 255, 255, 1) !important;
}

section {
    background: $white;
}

.checkout-page .checkout-page-form {
    margin-top: 30px !important;
}


.home-header {
 /*   position: relative;
    z-index: 0;*/
    /*background: url('/images/home-header-img1.jpg') no-repeat 50% 0%;*/
    /*background: url('/images/home-header-v1.jpg') no-repeat 50% 100%;*/
    /*background: url('/images/header-brood-pizza.jpg') no-repeat 50% 100%;*/
  /*  background: url('/images/header-mavhek-v2.jpg') no-repeat 50% 100%;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 648px;*/
    /*background-size: contain;*/
   /* background-size: cover;*/
}

.home-header,
#product-detail,
.category-detail,
.over-ons-main,
.cart-page .cart-section,
.sp-checkout-section,
.privacy-policy-main,
.disclaimer-main,
.algemene-voorwaarden-main,
.contact-main,
.cart-section {
    position: relative;
    z-index: 0;
    /*overflow: hidden;*/
}
/*
.over-ons-main {
    overflow: auto;
}*/

.home-header::before,
#product-detail::before,
.category-detail::before,
.over-ons-main::before,
.cart-page .cart-section::before,
.sp-checkout-section::before,
.privacy-policy-main::before,
.disclaimer-main::before,
.algemene-voorwaarden-main::before,
.contact-main::before,
.cart-section::before {
    content: '';
    position: absolute;
    top: -225%;
    left: 0%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  /*  top: 0;
    left: 0;
    width: 100%;
    height: 100%;*/
    background: #F9F5EF;
    border-radius: 847.676px;
    transform: rotate(-60deg);
    width: 100%;
    height: 100%;
    min-width: 1720px;
    min-height: 2271.15px;
}

.sp-checkout-section::before {
    top: -75%;
}

.privacy-policy-main::before, .disclaimer-main::before {
    top: -50%;
    z-index: -1;
}

.algemene-voorwaarden-main::before {
    top: -150%;
    z-index: -1;
    /*min-height: 571.15px;*/
}


.contact-main::before {
    top: -170%;
    z-index: -1;
}

.over-ons-main::before {
    top: -210%;
}

#product-detail::before,
.category-detail::before {
    top: -1400px;
}

.category-detail::before {
    top: -1500px;
}

   .checkout-page-form-intro {
       background: #bf873c;
       color: #FFF;
       padding: 10px 15px;
       border-radius: 10px;
       margin-left: 0px;
       margin-right: 0px;
       margin-bottom: 40px;
   }

   .checkout-page-form-intro p {
       margin-bottom: 0px;
       text-align: center;
   }


   .leadix-form .success-message {
       padding: 10px 20px;
       background-color: #000 !important;
       border-color: #bf873c !important;
       border: 1px solid #bf873c !important;
   }


   .home-inspiration-slider-desktop .uk-slider-items li,
   .home-inspiration-slider-desktop .uk-slider-items li > div,
   .home-inspiration-slider-desktop .uk-slider-items li img {
       height: 330px;
   }

.home-inspiration-slider-desktop .uk-slider-items li img {
    object-position: 50% 50%;
}

.home-offer-slider-desktop-inner ul li img {
    opacity: 0.9;
}

.home-offer-slider-desktop-inner ul li:hover img,
.home-offer-slider-desktop-inner ul li:focus img{
    opacity: 1;
}

.home-inspiration-slider-desktop-inner ul.uk-dotnav,
.home-offer-slider-desktop-inner ul.uk-dotnav,
.home-fashion-slider-desktop-inner ul.uk-dotnav {
    margin-top: 40px;
}

.home-logos-slider-desktop {
    display: none !important;
}

.home-inspiration-slider-desktop-inner ul.uk-dotnav>*>*,
.home-offer-slider-desktop-inner ul.uk-dotnav>*>*,
.home-fashion-slider-desktop-inner ul.uk-dotnav>*>*,
.home-logos-slider-desktop-inner ul.uk-dotnav>*>* {
    width: 14px;
    height: 14px;
    border: 1px solid $brown;
}

.home-inspiration-slider-desktop-inner ul.uk-dotnav>*>:hover,
.home-offer-slider-desktop-inner ul.uk-dotnav>*>:hover,
.home-fashion-slider-desktop-inner ul.uk-dotnav>*>:hover,
.home-logos-slider-desktop-inner ul.uk-dotnav>*>:hover {
    background-color: $brown;
    border-color: $brown;
}

.home-inspiration-slider-desktop-inner ul.uk-dotnav>.uk-active>*,
.home-offer-slider-desktop-inner ul.uk-dotnav>.uk-active>*,
.home-fashion-slider-desktop-inner ul.uk-dotnav>.uk-active>*,
.home-logos-slider-desktop-inner ul.uk-dotnav>.uk-active>* {
    background-color: $brown;
    border-color: $brown;
}


.header-mobile-col8 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.whatsappa {
    margin-left: 15px;
}


.whatsappam {
    margin-right: 15px;
}


.footer-col3 .whatsappa, .footer-col3 .whatsappa svg, .footer-col3 .whatsappa svg path {
    color: #000;
    fill: #000;
}

.footer-col3 .whatsappa:hover, .footer-col3 .whatsappa:hover svg, .footer-col3 .whatsappa:hover svg path {
    color: #AF8A4E;
    fill: #AF8A4E;
}

.leadix-form .fa-check-square:before, .leadix-form input[type=checkbox] + label:before {
    display: none !important;
}

.leadix-form .fa-check-square:before, .leadix-form input[type=checkbox]:checked + label:before {
    display: none !important;
}






@media (min-width: 1720px) {
       .home-inspirerende-merken > div.container::before {
           left: 40px !important;
       }

       .home-fashion-slider-desktop li,
       .home-fashion-slider-desktop li > div,
       .home-fashion-slider-desktop li img {
           height: 839px;
       }

     /*  .home-inspirerende-merken-col1 {
           padding-left: 90px;
       }*/

       .home-inspirerende-merken-col2 {
           padding-left: 65px;
       }
   }


@media (min-width: 1720px) and (max-width: 2000px) {
   /* .home-inspirerende-merken-col1 {
        padding-left: 120px;
    }*/
}


@media (min-width: 960px) and (max-width: 1719px) {
    .home-inspirerende-merken > div.container::before {
        left: 40px !important;
    }
}


@media (min-width: 960px) and (max-width: 1440px) {
  /*  .home-inspirerende-merken-col1 {
        position: relative;
        left: 55px !important;
    }*/
}

@media (min-width: 1441px) and (max-width: 1719px) {
    /*.home-inspirerende-merken-col1 {
        position: relative;
        left: 20px !important;
    }*/
}

@media (max-width: 1719px) {
    .home-fashion-slider-desktop .uk-slider-items li,
    .home-fashion-slider-desktop .uk-slider-items li > div,
    .home-fashion-slider-desktop .uk-slider-items li img {
        height: 839px;
    }
}


@media (min-width: 960px) and (max-width: 1719px) {
    /*.home-inspirerende-merken-col1 {
        width: 33.72%;
        padding-left: 16.28%;
    }*/
    .home-inspirerende-merken-col1 {
       /* width: 100%;*/
        /*padding-left: 16.28%;*/
     /*   flex: 0 0 100%;
        max-width: 100%;*/
    }

    .home-inspirerende-merken > div.container::before {
        width: 16.28% !important;
    }

    .home-inspirerende-merken-col2 {
        padding-left: 65px;
    }
}

   /*

   @media (min-width: 1920px) {

       .home-header {
           position: relative;
           z-index: 0;
           background: url('/images/header-mavhek-v2.jpg') no-repeat 50% 66%;
       }

   }

   */

/*.home-header > div {
    position: relative;
    z-index: 0;
    !*background: url('/images/home-header-img1.jpg') no-repeat 50% 0%;*!
    background: url('/images/home-header-v1.jpg') no-repeat 50% 0%;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 648px;
    !*background-size: contain;*!
    background-size: cover;
}*/

.home-header > div::before {
    /*  content: '';*/
    /*background: url('/images/top-view-bread-concep-with-copy-space.png') no-repeat 50% 0%;*/
    /* background: url('/images/home-header-img1.jpg') no-repeat 50% 0%;
    position: absolute;
    z-index: 2;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: homeheaderflower 3s linear infinite;
    -moz-animation: homeheaderflower 3s linear infinite;
    -o-animation: homeheaderflower 3s linear infinite;
    animation: homeheaderflower 3s linear infinite;
    min-height: 648px;
    background-size: contain;*/
}

.home-header-img img {
    display: none;
}

.home-header-img {
    position: relative;
}

.home-header-img > div:nth-child(2) {
    position: absolute !important;
    bottom: 0;
    right: 0;
}

.home-header-img .home-header-button-webshop a {
    background: #bf873c;
    border: 1px solid #bf873c;
    color: #efefef;
    font-family: roboto-condensed, sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    padding: 9px 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
}

.home-header-img .home-header-button-webshop a:hover,
.home-header-img .home-header-button-webshop a:focus {
    background: #efefef;
    border: 1px solid #efefef;
    color: #bf873c;
    text-decoration: none;
}

.page-home .home-header img {
    /*max-height: 289px;*/
  /*  max-width: 250px;
    max-height: 250px;*/
    object-fit: cover;
}

.category-detail-main {
    position: relative;
    z-index: 0;
}
.category-detail-main::before {
    content: '';
    background: url('/images/shop-flower.png') no-repeat 50% 0%;
    position: absolute;
    z-index: -1;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: shopflower 30s linear infinite;
    -moz-animation: shopflower 30s linear infinite;
    -o-animation: shopflower 30s linear infinite;
    animation: shopflower 30s linear infinite;
    min-height: 676px;
    background-size: contain;
}

/*
@-webkit-keyframes shopflower {
    0%, 100% { -webkit-transform: translateX(-200px) translateY(-200px); }
    50% { -webkit-transform:  translateX(200px) translateY(200px); }
}

@-moz-keyframes shopflower {
    0%, 100% { -moz-transform: translateX(-200px) translateY(-200px); }
    50% { -moz-transform:  translateX(200px) translateY(200px); }
}

@-o-keyframes shopflower {
    0%, 100% { -o-transform: translateX(-200px) translateY(-200px); }
    50% { -o-transform:  translateX(200px) translateY(200px); }
}

@keyframes shopflower {
    0%, 100% { transform: translateX(-200px) translateY(-200px); }
    50% { transform:  translateX(200px) translateY(200px); }
}
*/


.language-switcher-div ul {
    margin-right: 0px;
}

.language-switcher-div ul.menu-language-items > li {
    padding-left: 3px;
}

.language-switcher-div ul.menu-language-items > li > a {
    padding: 0px;
}

@-webkit-keyframes shopflower {
    0%, 100% { -webkit-transform: translateX(-200px) translateY(0px); }
    50% { -webkit-transform:  translateX(200px) translateY(250px); }
}

@-moz-keyframes shopflower {
    0%, 100% { -moz-transform: translateX(-200px) translateY(0px); }
    50% { -moz-transform:  translateX(200px) translateY(250px); }
}

@-o-keyframes shopflower {
    0%, 100% { -o-transform: translateX(-200px) translateY(0px); }
    50% { -o-transform:  translateX(200px) translateY(250px); }
}

@keyframes shopflower {
    0%, 100% { transform: translateX(-200px) translateY(0px); }
    50% { transform:  translateX(200px) translateY(250px); }
}




@-webkit-keyframes homeheaderflower {
    0%, 100% { -webkit-transform: translateX(-2px); }
    50% { -webkit-transform:  translateX(2px) ; }
}

@-moz-keyframes homeheaderflower {
    0%, 100% { -moz-transform: translateX(-2px); }
    50% { -moz-transform:  translateX(2px) ; }
}

@-o-keyframes homeheaderflower {
    0%, 100% { -o-transform: translateX(-2px); }
    50% { -o-transform:  translateX(2px) ; }
}

@keyframes homeheaderflower {
    0%, 100% { transform: translateX(-2px); }
    50% { transform:  translateX(2px) ; }
}


.header-logo {
    width: 160px;
    height: 141px;
}

.header-logo > div:nth-child(2),
.header-logo h1 {
    position: relative;
    z-index: 2;
}


.searchpage-content .search-field-input input {border-radius: 0px;}

.webshop-page-main {
    background: url('/images/background-large.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.btn {
    border-radius: 0px;
}

.filterspan {
    font-family: $font1;
}


.cart-content-section {
    padding-top: 30px !important;
}

.cart-content-section *, .checkout-page-form *, .confirm-container * {
    /*color: $darkgrey-v2;*/
    color: #000;
}

.cart-content-section .cart-qty-select-options div {
    color: #000;
}

.cart-content-section h2 {
    color: $brown-v1;
}

.qty-selector .qty-select-options {
    border: 1px solid #bf873d;
}

.qty-selector .qty-select-options::-webkit-scrollbar-thumb {
    background: #bf873d;
}

.fa-square:before, input[type=checkbox] + label:before,
.fa-circle:before, input[type=radio] + label:before {
    /*color: $brown-v1;*/
    color: #C4C4C4;
}

.qty-selector .qty-select-options div:hover {
    background-color: #bf873c;
    color: #FFF;
}

.algemene-voorwaarden-header, .disclaimer-header, .privacy-policy-header, .contact-header {
    /* background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background: #74C6C7;
    background-size: cover;
}

.page-contact .contact-header {
    display: none;
}

/*
.algemene-voorwaarden-header > div, .disclaimer-header > div, .privacy-policy-header > div, .contact-header > div {
    padding-left: 0px;
    padding-right: 0px;
}*/

.algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1, .contact-header h1 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    color: $brown-v2;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}

.algemene-voorwaarden-main, .disclaimer-main, .privacy-policy-main, .contact-main {
    padding: 70px 20px;
    box-sizing:border-box;
    padding-top: 10px;
}

.algemene-voorwaarden-main h1, .disclaimer-main h1, .privacy-policy-main h1, .contact-main h1 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}

.privacy-policy-main h3 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    /*color: #bf873c;*/
    color: #AF8A4E;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}



.algemene-voorwaarden-main p, .disclaimer-main p, .privacy-policy-main p, .privacy-policy-main th, .privacy-policy-main td {
    color: #000;
}

.header-icons {
    position: relative;
    z-index: 99;
}

.header-icons img:hover {
    /*filter: invert(81%) sepia(8%) saturate(813%) hue-rotate(349deg) brightness(91%) contrast(89%);*/
    /*filter: invert(84%) sepia(12%) saturate(504%) hue-rotate(349deg) brightness(86%) contrast(93%);*/
    /*filter: invert(98%) sepia(2%) saturate(7155%) hue-rotate(307deg) brightness(88%) contrast(78%);*/
    /*filter: invert(80%) sepia(1%) saturate(5760%) hue-rotate(329deg) brightness(89%) contrast(106%);*/
    filter: brightness(0) invert(1);
}

strong, b {
    font-family: $font2;
}

.alert {
    margin-bottom: 0;
}

.pastel-3-border-bottom {
    border-bottom: 1px solid $pastel-3-accent;
}

h2 {
    /*font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;*/
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #bf873c;
}

header {
    /*position: static;*/
    /*position: absolute;*/
    position: relative;
    /*margin-bottom: -102px;*/
    /*margin-bottom: -82px;*/
    width: 100%;
    left: 0;
    right: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    background-color: transparent !important;
    box-shadow: none !important;
}

header.uk-animation-slide-top,
header.uk-animation-enter,
header.uk-animation-leave,
header.uk-animation-reverse {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    animation-duration: 0s !important;
}

.uk-sticky[class*=uk-animation-],
.uk-animation-reverse {
    animation-duration: 0s !important;
}

header.uk-sticky,
header.uk-sticky-below,
header.uk-sticky-fixed {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    animation-duration: 0s !important;
}

header.uk-sticky-fixed {
    background-color: #fff !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0 !important;
}

.header-logo > div {
    padding-top: 140px;
}

.page-home .home-header > div:nth-child(2) {
    padding-top: 50px;
}


.header {
    background: url();
}

header > div {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

/*.over-ons-header {
    background: url('/images/textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
}*/


.home-header {
    /*background: url('/images/Cornet-Seating-Systems.jpg') no-repeat 50% 50%;
    background-size: cover;*/
}



.home-header > div {
    padding-top: 0px;
    padding-bottom: 90px;
}

.page-home .home-header > div {
    padding-top: 0px;
    padding-bottom: 20px;
}


.home-header .col-12 > div {
    position: relative;
    z-index: 0;
}



.header-logo > div:nth-child(1) {
    /*position: relative;
    z-index: ;*/
}

.header-logo > div:nth-child(2),
.header-logo h1 {
    position: relative;
    z-index: 2;
}

/*
.home-header h1 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 76px;
    color: $white;
    text-transform: none;
    text-align: left;
}
.home-header h1 span {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 1.2;
    text-transform: uppercase;
}*/

.title-bottom {
    text-transform: uppercase;
    font-size: 29px;
    line-height: 1.2;
}

.home-header .title-bottom {
    font-size: 29px;
    color: rgb(239, 239, 239);
    line-height: 1.2;
    text-shadow: 0px 4px 8.28px rgba(0, 0, 0, 0.004);
    display: inline-block;
    text-transform: none;
}

/*.home-header p {
    max-width: 630px;
    font-family: $font1;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: $white;
}*/
/*
.page-home .home-header-logo a {
    display: none !important;
}
*/
.col-5 .language-switcher-div {
    display: inline-block;
}
/*
header {
    background-color: rgb(191, 135, 60, 0.91);
}*/

.home-header-buttons {
    max-width: 500px;
    margin-top: 10px;
}

.home-header-buttons > div:nth-child(1) a {
    width: 233px;
    height: 45px;
    border: 2px solid $white;
    display: inline-block;
    color: $black;
    background: $white;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-header-buttons > div:nth-child(1) a:hover,
.home-header-buttons > div:nth-child(1) a:focus {
    color: $white;
    background: $black;
    text-decoration: none;
}

.home-header-buttons > div:nth-child(2) a {
    width: 233px;
    height: 45px;
    border: 2px solid $white;
    display: inline-block;
    color: $white;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-header-buttons > div:nth-child(2) a:hover,
.home-header-buttons > div:nth-child(2) a:focus {
    color: $black;
    background: $white;
    text-decoration: none;
}

/*.home-header .col-12 > div::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.36);
        z-index: 1;
    }*/

.home-header img {
    max-height: 670px;
    object-fit: cover;
}


.mini-cart a {
    /*background: $main-color;*/
    padding: 10px;
    /*box-shadow: 0px 0px 8px 3px rgba(23, 158, 172, 0.26);*/
    display: inline-block;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    line-height: 25px;
}

header .search-field, .user-login-link {
    margin-right: 20px;
}

header .search-field svg {
    position: relative;
    top: 2px;
}

.user-login-link {
    margin-right: 0px;
}

.top-menu-container-top {
    margin-top: 40px;
    margin-bottom: 20px;
}


ul.menu-main-menu > li > a {
    font-family: $main-font1;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: $black !important;
    font-weight: 300;
}

ul.menu-main-menu > li > span {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

ul.menu-main-menu > li > span > a {
    margin-left: 0 !important;
}

ul.menu-main-menu > li svg,
ul.menu-main-menu > li svg path {
    fill: #000 !important;
}

ul.menu-main-menu > li svg:hover,
ul.menu-main-menu > li svg:hover path {
    color: #AF8A4E !important;
}

ul.menu-main-menu > li > a:hover,
ul.menu-main-menu > li > a:focus {
    /*color: $main-color !important;*/
    color: $brown !important;
}


.menu-mobile-menu .whatsappa {
    margin-left: 0 !important;
}

.contact-header img {
    object-position: 50% 80%;
}

.over-ons-header .col-12, .contact-header .col-12, .algemene-voorwaarden-header .col-12, .disclaimer-header .col-12, .privacy-policy-header .col-12 {
    position: relative;
    z-index: 0;
}

.over-ons-header {
    background: #74C6C7;
}

/*
.over-ons-header .col-12::before, .contact-header .col-12::before, .algemene-voorwaarden-header .col-12::before, .disclaimer-header .col-12::before, .privacy-policy-header .col-12::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    background: rgba(0,0,0,0.36);
    z-index: 1;
}*/


.over-ons-header img, .contact-header img, .algemene-voorwaarden-header img, .disclaimer-header img, .privacy-policy-header img {
    max-height: 670px;
    object-fit: cover;
}


.header-home > div.py-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.info-header {
    /* background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.info-header .search-field {
    margin-bottom: -140px;
    position: relative;
    z-index: 9;
}

.header-home > div {
    padding-top: 0px !important;
}

/*.home-header-logo {
    margin-bottom: -230px;
    position: relative;
    z-index: 9;
    top: -40px;
}*/

.home-header-logo > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.header-icons .search-field {
    margin-left: 45px;
    margin-right: 15px;
}

.header-icons .menutext {
    font-family: $font2;
    font-size: 22px;
    line-height: 38px;
    color: $brown-v1;
    text-transform: uppercase;
    position: relative;
    top: 2px;
}

.header-icons .menutext:hover,
.header-icons .menutext:focus {
    color: $brown-v2;
    text-decoration: none;
}

.home-usps {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;*/
    /*background: linear-gradient(90.06deg, #74C6C7 0.05%, #1C9596 99.98%);*/
    background: #2B2B2B;
}

.home-usps h2 {
    /*background: -webkit-linear-gradient(#f0e9e3, #fff 75%);
    background: linear-gradient(#f0e9e3, #fff 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
    font-family: $font2;
    font-size: 195.39px;
    line-height: 183.60px;
    letter-spacing: 20px;
    margin-left: -10px;
    color: rgba(255, 255, 255, 0.039);
}

.home-usps-col1, .home-categories-title, .home-merken h2, .webshop-categories-title {
    animation-duration: 1.0s;
}

.webshop-categories .txt {
    text-align: center;
}

.home-usps ul, .over-ons-usps ul, .categorie-top-usps ul, .product-top-usps ul {text-align: center;list-style: none;/*margin-right: -60px;padding-right: 40px;*/

    margin-top: 22px;
    margin-bottom: 22px;
    margin-right: -40px;
    margin-left: 0px;
    padding-left: 0px;
}

.home-usps ul li, .over-ons-usps ul li, .categorie-top-usps ul li, .product-top-usps ul li {
    padding-right: 40px;
    font-family: $font1;
    color: $white;
    display: inline-block;
    padding-left: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;

}

.home-usps ul li a, .over-ons-usps ul li a, .categorie-top-usps ul li a, .product-top-usps ul li a {
    font-family: $font1;
    color: $white;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
}

.home-usps ul li a:hover, .over-ons-usps ul li a:hover, .categorie-top-usps ul li a:hover, .product-top-usps ul li a:hover,
.home-usps ul li a:focus, .over-ons-usps ul li a:focus, .categorie-top-usps ul li a:focus, .product-top-usps ul li a:focus {
    color: #74C6C7;
    text-decoration: none;
}

.home-usps ul li, .over-ons-usps ul li, .categorie-top-usps ul li, .product-top-usps ul li {
    position: relative;
}

.home-usps ul li::before, .over-ons-usps ul li::before, .categorie-top-usps ul li::before, .product-top-usps ul li::before {
    position: absolute;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.home-usps ul li:nth-child(1)::before,
.over-ons-usps ul li:nth-child(1)::before,
.categorie-top-usps ul li:nth-child(1)::before,
.product-top-usps ul li:nth-child(1)::before {
    content: '';
    background: url('/images/check-mark.svg');
    display: inline-block;
    width: 16px;
    height: 16px;
}
.home-usps ul li:nth-child(2)::before,
.over-ons-usps ul li:nth-child(2)::before,
.categorie-top-usps ul li:nth-child(2)::before,
.product-top-usps ul li:nth-child(2)::before {
    content: '';
    background: url('/images/phone.svg');
    display: inline-block;
    width: 16px;
    height: 16px;
}
.home-usps ul li:nth-child(3)::before,
.over-ons-usps ul li:nth-child(3)::before,
.categorie-top-usps ul li:nth-child(3)::before,
.product-top-usps ul li:nth-child(3)::before {
    content: '';
    background: url('/images/pointer.svg');
    display: inline-block;
    width: 16px;
    height: 16px;
}


.home-main {
    background: url('/images/home-main-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.home-main > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-main img {
    max-height: 500px;
    object-fit: contain;
}

.home-main h2 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    color: $main-color;
}
.home-main h2 span {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
}

.home-main p {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $black;
    max-width: 560px;
}

.home-main-bttn {
    width: 233px;
    height: 45px;
    border: 2px solid $black;
    display: inline-block;
    color: $black;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 10px;
}

.home-main-bttn:hover,
.home-main-bttn:focus {
    color: $white;
    background: $black;
    text-decoration: none;
}


.home-main > div {
    padding-top: 85px;
    padding-bottom: 78px;
}


/*.home-popular-products {
    background: linear-gradient(180deg, #74C6C7 0%, #1C9596 100%);
}*/

.home-popular-products {
    background: linear-gradient(180deg, #F0F0F0 0%, rgba(240, 240, 240, 0) 100%);
}

.home-popular-products > div {
    padding-top: 70px;
    padding-bottom: 70px;
}

.home-popular-products > div > div:nth-child(1) {
    padding-bottom: 40px;
}

.home-popular-products > div > div:nth-child(1) > div:nth-child(2) {
    text-align: right;
}

.home-popular-products > div.container > div.row:nth-child(2) {
    position: relative;
}

.home-popular-products > div.container > div.row:nth-child(2)::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -50px;
    /*left: 50%;*/
    right: 15px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    background: url('/images/union.svg') no-repeat 50% 50%;
    width: 100%;
    height: 100%;
    max-width: 1021px;
    max-height: 157px;
}

.full-offer {
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    text-decoration-line: underline;
    color: $black;
    position: relative;
    z-index: 2;
}

.full-offer:hover,
.full-offer:focus {
    color: #74c6c7;
}

.home-popular-products h2 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    color: $black;
    text-transform: none;
}

.home-popular-products h2 span {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
}



.home-bakovens-products-desktop .image-container,
.home-popular-products-slider-inner .image-container {
    position: relative;
    z-index: 0;
}

.home-bakovens-products-desktop {
    margin-left: -40px;
}

.home-bakovens-products-desktop > div {
    padding-left: 40px;
}

.home-bakovens-products-desktop .image-container .productlink,
.home-popular-products-slider-inner .image-container .productlink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}

.home-bakovens-products-desktop .txt-container,
.home-popular-products-slider-inner .txt-container {
    /*background: $black;*/
    padding: 20px 25px;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
}

.home-bakovens-products-desktop .txt-container .productlink,
.home-popular-products-slider-inner .txt-container .productlink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}


/*    .home-popular-products-nav {
        display: none;
    }
*/
.page-contact h2, .page-offerteaanvraag h2 {
    /*font-family: $font1;*/
    /*font-family: $font2;
    font-style: normal;
    font-weight: 300;*/
    /*font-family: aviano-serif, serif;*/
    font-family: $main-font2;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.3;
    /*color: $main-color;*/
    color: $brown;
}

.page-contact h2 span, .page-offerteaanvraag h2 span {
    font-weight: bold;
}

/*
    .home-popular-products-slider-inner .txt-container .col-10 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }*/

.home-bakovens-products-desktop .txt-container .col-10 > div,
.home-popular-products-slider-inner .txt-container .col-10 > div {
    flex: 0 1 auto;
    text-align: left;
}

.home-bakovens-products-desktop .txt-container .col-2,
.home-popular-products-slider-inner .txt-container .col-2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}
/*
.home-popular-products-slider-inner .txt-container .col-2 img {
    min-width: 30px;
}*/


.home-bakovens-products-desktop .txt-container .col-2 img,
.home-popular-products-slider-inner .txt-container .col-2 img {
    width: 24px;
    position: relative;
    top: -1px;
}

/*.home-popular-products-slider-inner .txt-container .col-2 .deegkneder-right-chevron-span img {
    min-width: 9px;
}*/
.home-bakovens-products-desktop .txt-container .col-2 .deegkneder-right-chevron-span img,
.home-popular-products-slider-inner .txt-container .col-2 .deegkneder-right-chevron-span img {
    width: 11px;
    position: relative;
    left: 2px;
}

.home-bakovens-products-desktop .txt-container .title,
.home-popular-products-slider-inner .txt-container .title {
    font-family: $font2;
    font-size: 23px;
    line-height: 1.2;
    color: $white;
}

.home-bakovens-products-desktop .txt-container .price,
.home-popular-products-slider-inner .txt-container .price {
    font-family: $font3;
    font-size: 16px;
    line-height: 1.2;
    color: $white-v2;
}

.home-popular-products-slider-inner > ul {
    margin-left: -20px;
}

.home-popular-products-slider-inner > ul > li {
    padding-left: 20px;
}

.home-bakovens-products-desktop .image-container img,
.home-popular-products-slider-inner .image-container img {
    width: 100%;
    object-fit: contain;
    max-height: 197px;

}


.home-categories {
    background: $main-color;
    text-align: center;
}

.home-categories ul {
    list-style: none;
    margin-left: -100px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.home-categories ul li {
    display: inline-block;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: $white;
    padding-left: 100px;
}

.home-categories ul li a {
    color: $white;
}

.home-categories ul li a:hover, .home-categories ul li a:focus {
    color: #2B2B2B;
    text-decoration: none;
}


.home-unieke-collectie-homewear {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 100px;
}

.home-unieke-collectie-homewear img {
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 662px;
    max-width: 557px;
    object-fit: cover;
    object-position: 25% 50%;
}

.home-unieke-collectie-homewear-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-unieke-collectie-homewear-col2 > div {
    max-width: 466px;
    padding: 20px;
    box-sizing: content-box;
}

.home-bottom {
    position: relative;
}

.home-bottom::before {
    position: absolute;
    display: inline-block;
    content: "";
    background: url('/images/union2.svg') no-repeat 0% 50%;
    left:0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 128px;
    max-height: 613px;
    object-fit: contain;
}

.home-bottom > div {
    padding-top: 90px;
}

.home-bottom .col-md-6 > div > img {
    max-height: 500px;
}


.home-categories-section {
    padding-top: 90px;
    padding-bottom: 200px;
}


.home-bottom > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-bottom h2 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    color: $main-color;
}

.home-bottom h2 span {
    font-weight: bold;
}

.home-bottom p {

    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $black;

}

.home-bottom-bttn {
    width: 233px;
    height: 45px;
    border: 2px solid $main-color;
    display: inline-block;
    color: $main-color;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 10px;
}

.home-bottom-bttn:hover,
.home-bottom-bttn:focus {
    background: $main-color;
    color: $white;
    text-decoration: none;
}


.home-main-bttn-green {
    width: 233px;
    height: 45px;
    border: 2px solid $main-color;
    display: inline-block;
    color: $main-color;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 10px;
}

.home-main-bttn-green:hover,
.home-main-bttn-green:focus {
    background: $main-color;
    color: $white;
    text-decoration: none;
}


.home-logos > div {
    padding-top: 60px;
    padding-bottom: 90px;
}

.home-logos > div > div > div {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-evenly;
    -webkit-justify-content: space-evenly;

}


.home-logos img {
    width: auto !important;
}

.home-categories-title h2, .webshop-categories-title h2 {
    background: -webkit-linear-gradient(#f0e9e3, #fff 75%);
    background: linear-gradient(#f0e9e3, #fff 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "GothamNarrow-Bold", sans-serif;
    font-size: 195.39px;
    line-height: 183.60px;
    letter-spacing: 20px;
    text-align: right;
    margin-bottom: 0px;
}

.webshop-categories-title h2 {
    background: -webkit-linear-gradient(#aaa, #eee 75%);
    background: linear-gradient(#aaa, #eee 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "GothamNarrow-Bold", sans-serif;
    font-size: 195.39px;
    line-height: 183.60px;
    letter-spacing: 20px;
    text-align: right;
    margin-bottom: 0px;
}

.home-categories-title + .container {
    margin-top: -50px;
    padding-left: 0px;
    padding-right: 0px;
}

.webshop-categories-title + .container {
    margin-top: -75px;
}

.home-categories .brown-bg a, .webshop-categories .brown-bg a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    background: $brown-v1;
    color: $darkgrey-v1;
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    font-size: 36px;
    line-height: 36.65px;
    text-transform: uppercase;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    min-height: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.home-categories .brown-bg a:hover,
.home-categories .brown-bg a:focus,
.webshop-categories .brown-bg a:hover,
.webshop-categories .brown-bg a:focus {
    text-decoration: none;
    background: $darkgrey-v1;
    color: $brown-v2;
}

.home-categories .brown-bg .shop-btn,
.webshop-categories .brown-bg .shop-btn {
    font-family: $font1;
    font-size: 17px;
    color: $darkgrey-v1;
    border: 1px solid $darkgrey-v1;
    display: inline-block;
    padding: 0 24px;
    margin-top: 20px;
}

.home-categories .brown-bg a:hover .shop-btn,
.home-categories .brown-bg a:focus .shop-btn,
.webshop-categories .brown-bg a:hover .shop-btn,
.webshop-categories .brown-bg a:focus .shop-btn {
    background: $darkgrey-v1;
    color: $brown-v2;
    border: 1px solid $brown-v2;
}

.home-categories .brown-bg a .shop-btn:hover,
.home-categories .brown-bg a .shop-btn:focus,
.webshop-categories .brown-bg a .shop-btn:hover,
.webshop-categories .brown-bg a .shop-btn:focus {
    background-color: $brown-v2;
    color: $darkgrey-v1;
}

.home-categories .darkgrey-bg a,
.webshop-categories .darkgrey-bg a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    background: $darkgrey-v1;
    color: $brown-v2;
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    font-size: 36px;
    line-height: 36.65px;
    text-transform: uppercase;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    min-height: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.home-categories .darkgrey-bg a:hover,
.home-categories .darkgrey-bg a:focus,
.webshop-categories .darkgrey-bg a:hover,
.webshop-categories .darkgrey-bg a:focus {
    text-decoration: none;
    background: $brown-v1;
    color: $darkgrey-v1;
}

.home-categories .darkgrey-bg .shop-btn,
.webshop-categories .darkgrey-bg .shop-btn {
    font-family: $font1;
    font-size: 17px;
    color: $brown-v2;
    border: 1px solid $brown-v2;
    display: inline-block;
    padding: 0 24px;
    margin-top: 20px;
}

.home-categories .darkgrey-bg a:hover .shop-btn,
.home-categories .darkgrey-bg a:focus .shop-btn,
.webshop-categories .darkgrey-bg a:hover .shop-btn,
.webshop-categories .darkgrey-bg a:focus .shop-btn {
    background: $brown-v1;
    color: $darkgrey-v1;
    border: 1px solid $darkgrey-v1;
}

.home-categories .darkgrey-bg a .shop-btn:hover,
.home-categories .darkgrey-bg a .shop-btn:focus,
.webshop-categories .darkgrey-bg a .shop-btn:hover,
.webshop-categories .darkgrey-bg a .shop-btn:focus {
    background-color: $darkgrey-v1;
    color: $white;
}
.webshop-categories .webshop-categories-img a {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
}
.webshop-categories .webshop-categories-img a::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    display: inline-block;
}

.webshop-categories .webshop-categories-img a:hover::before {
    background: rgba(240, 233, 227, 0.2);
}

.webshop-categories-container {
    padding-bottom: 70px;
}

.checkout-page-form input[type=radio] + label:before {
    content: "\F0C8";
}

.checkout-page-form  input[type=radio]:checked + label:before {
    content: "\F14A";
}

.checkout-form.p-4, .payment-form .pl-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.checkout-form input,
.checkout-form textarea {
    color: #000 !important;
    background-color: transparent !important;
}

.over-ons-categorieen {
    background: #74C6C7;
    /*padding-top: 40px;
    padding-bottom: 20px;*/
    padding-top: 0px;
    padding-bottom: 0px;
}

.over-ons-categorieen * {
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.over-ons-categorieen .col-12 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-categorieen ul {
    list-style: none;
    margin-left: -100px;
}
.over-ons-categorieen ul li {
    display: inline-block;
    padding-left: 100px;
}

.over-ons-uniek-verdeler .over-ons-rust-col1 p:last-child a {
    margin-top: 10px;
}

.over-ons-uniek-verdeler a {
    width: 233px;
    height: 45px;
    line-height: 42px;
    display: inline-block;
    border: 2px solid #74C6C7;
    text-align: center;
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #74C6C7;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.over-ons-uniek-verdeler a:hover,
.over-ons-uniek-verdeler a:focus {
    text-decoration: none;
    background-color: #74C6C7;
    color: #FFF;
}

.detail-tab-title {
    background: #74C6C7;
}

.detail-tab-title a {
    font-family: Effra;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 20px 45px;
    box-sizing: border-box;
    display: inline-block;
}

/*.detail-tab-title li.active a {
    background: #2B2B2B;
    font-weight: bold;
}*/
.detail-tab-title li a.active {
    background: #2B2B2B;
    font-weight: bold;
}

.detail-tab-title a:hover,
.detail-tab-title a:focus {
    background: #2B2B2B;
    text-decoration: none;
}

.detail-tab-title ul {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.detail-tab-content {
    background: url('/images/tab-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.detail-tab-content h2 {
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
}

.detail-tab-content p,
.detail-tab-content td,
.detail-tab-content li {
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #2B2B2B;
}

.detail-tab-desc p:last-child,
.detail-tab-extra-info p:last-child,
.detail-tab-docs p:last-child {
    margin-bottom: 0px;
}

.detail-tab-desc *,
.detail-tab-extra-info *,
.detail-tab-docs * {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.detail-tab-desc,
.detail-tab-extra-info,
.detail-tab-docs {
    padding: 45px 20px;
    box-sizing: border-box;
    min-height: 450px;
}

.detail-tab-docs a {
    font-family: Effra;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 15px 45px;
    box-sizing: border-box;
    display: inline-block;
    background: #74c6c7;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 45px;
}

.detail-tab-docs a:hover,
.detail-tab-docs a:focus {
    background: #2B2B2B;
    text-decoration: none;
}


.slick-list {
    margin-left: 0;
    margin-right: 0;
}

/*
.slick-slider {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}
*/
/*
#product-detail .slick-list > div {
    max-width: 90px;
}
*/

/*.slick-list .slick-track {
    width: 90px;
}*/

.form-steps li a, .form-steps li span,
.form-steps li.enabled a, .form-steps li.enabled span, .form-steps li.current a, .form-steps li.current span {
    /*color: #000;*/
    color: #000;
}

.cart-totals .total-amount {
    background-color: transparent;
}

.home-podium-op-maat img {
    max-width: 552px;
    max-height: 500px;
}

.home-podium-op-maat {
    padding-bottom: 55px;
    position: relative;
}

.home-podium-op-maat::before {
    position: absolute;
    display: inline-block;
    content: "";
    background: url('/images/union3.svg') no-repeat 100% 50%;
    right: 0;
    top: -66%;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 128px;
    max-height: 537px;
    object-fit: contain;
}


.leadix-form input[type=radio] + label:before {
    display: none;
}

/*.mobile-menu-block.open .close-menu {
    display: none;
}*/

.mobile-menu-block.open .close-menu {
    position: relative;
    top: 20px;
    right: 20px;
    text-align: right;
}
.mobile-menu-block.open .close-menu svg {
    font-size: 33px;
}


.mobile-menu-block-open-bottom {
    position: absolute;
    /*bottom: 20px;*/
    bottom: 80px;
    left: 20px;
}

.mobile-menu-block ul {
    list-style: none;
}

.mobile-menu-block ul li {
    text-align: left;
}

.mobile-menu-block ul li + li {
    margin-top: 20px;
}

.mobile-menu-block.open ul li + li {
    margin-top: 20px;
}

.mobile-menu-block.open .language-switcher-div ul.menu-language-items > li {
    padding-left: 15px;
    margin-top: 0
}

.mobile-menu-block ul li a {
    font-family: effra, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #2B2B2B;
}

.mobile-menu-block ul li a:hover,
.mobile-menu-block ul li a:focus {
    /*color: #74C6C7;*/
    color: #bf873c;
    text-decoration: none;
}

.no-products-in-cart a {
    /*background: #74c6c7;
    border: 1px solid #74c6c7;
    color: #FFF;*/
    /*background: #bf873c;
    border: 1px solid #bf873c;
    color: #efefef;
    font-family: roboto-condensed, sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    padding: 9px 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;*/
    font-family: aviano-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    padding: 15px 55px;
    color: #FFF;
    display: inline-block;
    margin-top: 20px;
    position: relative;
    z-index: 0;
}

.no-products-in-cart a:hover,
.no-products-in-cart a:focus {
   /* background: #2b2b2b;
    border: 1px solid #2b2b2b;
    color: #FFF;*/
 /*   background: #efefef;
    border: 1px solid #efefef;
    color: #bf873c;
    text-decoration: none;*/
    color: #FFF;
    text-decoration: none;
}

.no-products-in-cart a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000;
    transition: all 0.3s;
}


.no-products-in-cart a:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
}

.no-products-in-cart a:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: #AF8A4E;
    border: 1px solid #AF8A4E;
    transform: scale(1.2, 1.2);
}

.no-products-in-cart a:hover::after {
    opacity: 1;
    transform: scale(1, 1);
}


.title-top {
    font-family: $font1;
    font-size: 48px;
    position: relative;
    left: 15px;
}

.title-bottom {
    font-family: $font2;
}

.brown {
    color: $brown;
}

.white {
    color: $white;
}

.black {
    color: $black;
}

.home-maatwerk {
    text-align:center;
}

.home-maatwerk img {
    max-width: 580px;
}

.home-over-mavhek {
    position: relative;
}

.home-over-mavhek::before {
    content: '';
    background: url('/images/ambachtelijk-bakplezier-before.png') no-repeat 100% 50%;
    position: absolute;
    top: calc(100% - 202px);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 194px;
    max-height: 152px;
    background-size: cover;
}

.home-over-mavhek > div {
    padding-top: 140px;
    padding-bottom: 100px;
}

.home-over-mavhek .title-top {
    font-size: 48px;
}

.brown-button-p {
    margin-top: 40px;
}

.handleidingen-main .manuals a {
    min-width: 260px;
}

.brown-button-p a, .handleidingen-main .manuals a {
    background: $brown;
    border: 1px solid $brown;
    color: $white-v2;
    font-family: $font2;
    font-size: 18px;
    text-transform: uppercase;
    padding: 9px 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
}

.brown-button-p a:hover,
.brown-button-p a:focus,
.handleidingen-main .manuals a:hover,
.handleidingen-main .manuals a:focus {
    background: $white-v2;
    border: 1px solid $white-v2;
    color: $brown;
    text-decoration: none;
}

.black-button-p {
    margin-top: 40px;
}

.black-button-p a {
    background: $black;
    border: 1px solid $black;
    color: $white-v2;
    font-family: $font2;
    font-size: 18px;
    text-transform: uppercase;
    padding: 9px 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
}

.black-button-p a:hover,
.black-button-p a:focus {
    background: $white-v2;
    border: 1px solid $white-v2;
    color: $black;
    text-decoration: none;
}

.header-logo img {
    max-width: 290px;

}

.home-over-mavhek-col1 img {
    max-width: 559px;
    max-height: 373px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-over-mavhek-col1, .home-over-mavhek-col2 {
    position: relative;
    z-index: 0;
}

.home-over-mavhek-col1::before {
    content: '';
    background: url('/images/home-ambachtelijk-bakplezier-flower-left.png') no-repeat 100% 50%;
    position: absolute;
    z-index: -1;
    /*top:-50%;*/
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: homeovermavhekcol2flower 30s linear infinite;
    -moz-animation: homeovermavhekcol2flower 30s linear infinite;
    -o-animation: homeovermavhekcol2flower 30s linear infinite;
    animation: homeovermavhekcol2flower 30s linear infinite;
    min-height: 714px;
}


.home-over-mavhek-col2::before {
    content: '';
    background: url('/images/home-ambachtelijk-bakplezier-flower-right.png') no-repeat 100% 50%;
    position: absolute;
    z-index: -1;
    /*top:-50%;*/
    top: 0%;
    right: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: homeovermavhekcol2flower 30s linear infinite;
    -moz-animation: homeovermavhekcol2flower 30s linear infinite;
    -o-animation: homeovermavhekcol2flower 30s linear infinite;
    animation: homeovermavhekcol2flower 30s linear infinite;
    min-height: 714px;
}



@-webkit-keyframes homeovermavhekcol2flower {
    0%, 100% { -webkit-transform: translateY(-50px); }
    50% { -webkit-transform:  translateY(200px) ; }
}

@-moz-keyframes homeovermavhekcol2flower {
    0%, 100% { -moz-transform: translateY(-50px); }
    50% { -moz-transform:  translateY(200px) ; }
}

@-o-keyframes homeovermavhekcol2flower {
    0%, 100% { -o-transform: translateY(-50px); }
    50% { -o-transform:  translateY(200px) ; }
}

@keyframes homeovermavhekcol2flower {
    0%, 100% { transform: translateY(-50px); }
    50% { transform:  translateY(200px) ; }
}

.home-our-ovens, .over-ons-offerte {
    background: url('/images/ambachtelijk-bakplezier-bg.png') no-repeat 50% 50%;
    background-color: $black;
    background-size: cover;
}

.home-our-ovens > div {
    padding-top: 70px;
    padding-bottom: 70px;
}

.over-ons-offerte {
    text-align:center;
}

.over-ons-offerte > div {
    padding-top: 85px;
    padding-bottom: 85px;
}

.over-ons-offerte .title-bottom {
    display: inline-block;
    margin-top: 10px;
}

.home-our-ovens img {
    max-width: 499px;
    max-height: 356px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.home-our-ovens .title-top {
    font-size: 48px;
}




.home-deegkneders {
    text-align: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.home-deegkneders .container > .row:nth-child(1) img {
    max-width: 580px;
}

.home-deegkneders .container > .row:nth-child(2) {
    text-align: left;
    padding-bottom: 80px;
}

.home-deegkneders h3 {
    font-family: $font2;
    font-size: 30px;
    line-height: 40px;
    color: $brown;
    max-width: 210px;
}

.home-bakovens-slider {
    padding-top: 40px;
}

.home-bakovens-slider-li-div {
    background: #202020;
    border-radius: 10px;
}

.home-bakovens-slider-li-div:hover,
.home-bakovens-slider-li-div:focus {
    background: #b07c37;
}

.home-bakovens-slider-li-div .image-container


.home-deegkneders {
    position: relative;
    z-index: 0;
}

.home-deegkneders::before {
    content: '';
    display: inline-block;
    background: url('/images/brood-img2.png') no-repeat 50% 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 224px;
    max-height: 439px;
}

.home-deegkneders::after {
    content: '';
    background: url('/images/home-assortiment-deegkneders-flower.png') no-repeat 100% 100%;
    position: absolute;
    z-index: -1;
   /* top:-50%;*/
    /*top:0%;*/
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: homedeegknedersflower 30s linear infinite;
    -moz-animation: homedeegknedersflower 30s linear infinite;
    -o-animation: homedeegknedersflower 30s linear infinite;
    animation: homedeegknedersflower 30s linear infinite;
    min-height: 762px;
}



/*@-webkit-keyframes homedeegknedersflower {
    0%, 100% { -webkit-transform: translateY(-50px); }
    50% { -webkit-transform:  translateY(200px) ; }
}

@-moz-keyframes homedeegknedersflower {
    0%, 100% { -moz-transform: translateY(-50px); }
    50% { -moz-transform:  translateY(200px) ; }
}

@-o-keyframes homedeegknedersflower {
    0%, 100% { -o-transform: translateY(-50px); }
    50% { -o-transform:  translateY(200px) ; }
}

@keyframes homedeegknedersflower {
    0%, 100% { transform: translateY(-50px); }
    50% { transform:  translateY(200px) ; }
}*/

@-webkit-keyframes homedeegknedersflower {
    0%, 100% { -webkit-transform: translateY(-250px); }
    50% { -webkit-transform:  translateY(0px) ; }
}

@-moz-keyframes homedeegknedersflower {
    0%, 100% { -moz-transform: translateY(-250px); }
    50% { -moz-transform:  translateY(0px) ; }
}

@-o-keyframes homedeegknedersflower {
    0%, 100% { -o-transform: translateY(-250px); }
    50% { -o-transform:  translateY(0px) ; }
}

@keyframes homedeegknedersflower {
    0%, 100% { transform: translateY(-250px); }
    50% { transform:  translateY(0px) ; }
}

.home-deegkneders > div.container > div.row:nth-child(1) {
    padding-top: 70px;
    padding-bottom: 70px;
}

.home-contact {
    background: transparent;
}

.home-contact label {
    color: $brown;
    font-family: $font3;
    font-size: 16px;
}

.home-contact input {
    height: 50px !important;
    border-radius: 0px !important;
    border: 1px solid $brown !important;
    background: transparent !important;
    color: $black !important;
}

.home-contact input.info-opt-in-checkbox {
    height: 26px !important;
}

.home-contact input.info-opt-in-checkbox:checked:after {
    color: #000 !important;
}

.leadix-form .fa-check-square:before, .leadix-form input[type=checkbox]:checked + label:before {
    display: none !important;
}


.leadix-form input[type=checkbox] + label {
    line-height: 18px !important;
}

#form-group-text-1647247564545,
#form-group-text-1647247569454 {
    width: 49%;
    margin-right: 1%;
    float: left;
}

#form-group-text-1647247561992,
#form-group-text-1647247570317 {
    width: 49%;
    margin-left: 1%;
    float: left;
}

.home-contact textarea {
    height: 140px !important;
    border-radius: 0px !important;
    border: 1px solid $brown !important;
    background: transparent !important;
    color: $black !important;
}

.privacy-notice-paragraph, .privacy-notice-paragraph a {
    font-size: 12px;
    line-height: 18px;
}

.privacy-notice-paragraph a {
    color: $brown;
}

.privacy-notice-paragraph a:hover,
.privacy-notice-paragraph a:focus {
    color: $white;
    text-decoration: none;
}

.home-contact form > div:last-child {
    text-align: right;
}

.home-contact .submit {
    font-family: aviano-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    padding: 15px 55px;
    color: #FFF;
    display: inline-block;
    margin-top: 20px;
    position: relative;
    z-index: 0;
}

.home-contact .submit:hover,
.home-contact .submit:focus {
    color: #FFF;
    text-decoration: none;
}

.home-contact .submit::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000;
    transition: all 0.3s;
}

.home-contact .submit:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
}


.home-contact .submit::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: #AF8A4E;
    border: 1px solid #AF8A4E;
    transform: scale(1.2, 1.2);
}


.home-contact .submit:hover::after {
    opacity: 1;
    transform: scale(1, 1);
}



.home-contact-col2 {
    position: relative;
    z-index: 0;
}

.home-contact-col2::before {
    content: '';
    background: url('/images/home-contact-flower.png') no-repeat 100% 50%;
    position: absolute;
    z-index: -1;
    /*top:-50%;*/
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: homecontactflower 30s linear infinite;
    -moz-animation: homecontactflower 30s linear infinite;
    -o-animation: homecontactflower 30s linear infinite;
    animation: homecontactflower 30s linear infinite;
    min-height: 768px;
}


.contact-main-col2 a, .klantenservice-main a {
    color: $brown;
}

.contact-main-col2 a:hover,
.contact-main-col2 a:focus,
.klantenservice-main a:hover,
.klantenservice-main a:focus {
    color: #000;
    text-decoration: none;
}



@-webkit-keyframes homecontactflower {
    0%, 100% { -webkit-transform: translateY(-50px); }
    50% { -webkit-transform:  translateY(200px) ; }
}

@-moz-keyframes homecontactflower {
    0%, 100% { -moz-transform: translateY(-50px); }
    50% { -moz-transform:  translateY(200px) ; }
}

@-o-keyframes homecontactflower {
    0%, 100% { -o-transform: translateY(-50px); }
    50% { -o-transform:  translateY(200px) ; }
}

@keyframes homecontactflower {
    0%, 100% { transform: translateY(-50px); }
    50% { transform:  translateY(200px) ; }
}


.home-levensduur {
    padding-bottom: 150px;
    position: relative;
    z-index: 0;
}

.home-levensduur::before {
    content: '';
    background: url('/images/home-levensduur-flower.png') no-repeat 0% 50%;
    position: absolute;
    z-index: -1;
    /*top:-50%;*/
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: homelevensduurflower 30s linear infinite;
    -moz-animation: homelevensduurflower 30s linear infinite;
    -o-animation: homelevensduurflower 30s linear infinite;
    animation: homelevensduurflower 30s linear infinite;
    min-height: 762px;
}




@-webkit-keyframes homelevensduurflower {
    0%, 100% { -webkit-transform: translateY(-50px); }
    50% { -webkit-transform:  translateY(200px) ; }
}

@-moz-keyframes homelevensduurflower {
    0%, 100% { -moz-transform: translateY(-50px); }
    50% { -moz-transform:  translateY(200px) ; }
}

@-o-keyframes homelevensduurflower {
    0%, 100% { -o-transform: translateY(-50px); }
    50% { -o-transform:  translateY(200px) ; }
}

@keyframes homelevensduurflower {
    0%, 100% { transform: translateY(-50px); }
    50% { transform:  translateY(200px) ; }
}

.home-levensduur-col1 {
    padding-top: 60px;
}

.home-levensduur-col2 img {
    max-width: 473px;
    max-height: 338px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.home-contact-col1 > div {
    padding-top: 60px;
}

.home-contact-col1 img {
    max-width: 559px;
    max-height: 524px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.home-text-right .languages-flags {
    display: inline-block;
    list-style: none;
}

.home-text-right .search-field,
.home-text-right .mini-cart {
    display: none !important;
}

.home-bakovens-cat-nav {
    bottom: -60px;
    z-index: 9;
}

.home-bakovens-cat-nav a svg {
    display: none;
}

.home-bakovens-cat-nav a:nth-child(1) {
    padding-right: 2px;
    margin-right: 2px;
}
.home-bakovens-cat-nav a:nth-child(2) {
    padding-left: 2px;
    margin-left: 2px;
}


.home-bakovens-slider-li-div:hover, .home-deegkneder-block:hover,
.home-bakovens-slider-li-div:focus, .home-deegkneder-block:focus {
    cursor: pointer;
}

.home-deegkneder-block .image-container img {
    padding-top: 40px;
    padding-bottom: 20px;
}

.home-deegkneders > div > div:nth-child(2) > div:nth-child(2) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding-left: 75px;
}


.header-general-top {
    background-color: $black;
    padding-top: 125px;
    padding-bottom: 60px;
}

.header-general-top .col-2 img {
    width: 151px;
}

.header-general-top .col-10 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.header-general-top .col-5 {
     display: -webkit-box;
     display: -moz-box;
     display: -ms-flexbox;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-align-content: center;
     align-content: center;
     -webkit-align-items: center;
     align-items: center;
     justify-content: flex-start;
     -webkit-justify-content: flex-start;
 }



.header-general-top-categories .category-filter {width: 100%;}
.header-general-top-categories .category-filter-item {flex: 0;}
.header-general-top-categories .category-filter-item a {
    background: #bf873c;
    border: 1px solid #bf873c;
    color: #efefef;
    font-family: roboto-condensed, sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    padding: 9px 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
}

.header-general-top-categories .category-filter-item a:hover,
.header-general-top-categories .category-filter-item a:focus {
    background: #efefef;
    border: 1px solid #efefef;
    color: #bf873c;
    text-decoration: none;
}


.category-detail-main-title {
    text-align: center;
}

.category-detail-main-title .category-name {
    font-family: roboto-condensed, sans-serif;
    text-transform: uppercase;
    font-size: 29px;
    line-height: 1.2;
}

.category-detail-main-title img {max-width: 580px;margin-top: 25px;margin-bottom: 40px;}

.category-detail-main .product-list-item {
    border: 1px solid #202020;
    overflow: hidden;
    background: #202020;
}

.category-detail-main .product-list-item .image-container {
    background: #202020;
    border: none !important;
}

.category-detail .category-detail-main .product-list-item:hover {
    background-color: #b07c37;
}

.category-detail .category-detail-main .product-list-item:hover .image-container {
    background-color: #b07c37;
}

.category-detail-main .product-list-item .image-container img {
    width: 100%;
    object-fit: contain;
    max-height: 197px;
}

.category-detail-main .catprod .product-info {
    padding: 15px 20px;
}

.category-detail-main .catprod .product-info {
    background: #202020 !important;
}

.category-detail .category-detail-main .product-list-item:hover .product-info {
    background: #b07c37 !important;
}

.category-detail .category-detail-main .product-list-item:hover .more-info {
    background: #202020;
    color: #FFF;
}




.category-detail-main .catprod .product-info .product-spec {
    text-align: left;
    color: #efefef;
}

.category-detail-main .catprod .product-info .more-info {
    position: relative;
    color: #FFF;
    left: -10px;
}

.header-general-top h1 {
    font-family: $font1;
    font-size: 48px;
    color: $brown;
    text-transform: none;
}

.over-ons-main-col1 img {
   /* max-width: 559px;
    max-height: 373px;
    border-radius: 10px;*/
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.over-ons-main-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-main-col2 > div {
    width: 549px;
}

/*
.over-ons-main {
    position: relative;
    z-index: 0;
    padding-bottom: 90px;
}

.over-ons-main::before {
    content: '';
    background: url('/images/over-ons-main-before.png') no-repeat 0 100%;
    position: absolute;
    left: 0;
    bottom: -10px;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: inline-block;
}

.over-ons-main > div.container {
    position: relative;
    z-index: 2;
}*/

#product-detail {
    position: relative;
    z-index: 0;
}

#product-detail .breadcrumbs p a:last-child {
    font-weight: 900;
}

    /*
    #product-detail::before {
        content: '';
        background: url('/images/over-ons-main-before.png') no-repeat 0 100%;
        position: absolute;
        left: 0;
        bottom: -10px;
        z-index: -1;
        width: 100%;
        height: 100%;
        display: inline-block;
    }
    */

#product-detail > div.container {
    position: relative;
    z-index: 2;
}


#product-detail::after {
    content: '';
    background: url('/images/product-main-flower.png') no-repeat 50% 50%;
    position: absolute;
    z-index: -1;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: productmainflower 30s linear infinite;
    -moz-animation: productmainflower 30s linear infinite;
    -o-animation: productmainflower 30s linear infinite;
    animation: productmainflower 30s linear infinite;
    min-height: 676px;
    background-size: contain;
}



@-webkit-keyframes productmainflower {
    0%, 100% { -webkit-transform: translateX(-200px) translateY(-200px); }
    50% { -webkit-transform:  translateX(200px) translateY(200px); }
}

@-moz-keyframes productmainflower {
    0%, 100% { -moz-transform: translateX(-200px) translateY(-200px); }
    50% { -moz-transform:  translateX(200px) translateY(200px); }
}

@-o-keyframes productmainflower {
    0%, 100% { -o-transform: translateX(-200px) translateY(-200px); }
    50% { -o-transform:  translateX(200px) translateY(200px); }
}

@keyframes productmainflower {
    0%, 100% { transform: translateX(-200px) translateY(-200px); }
    50% { transform:  translateX(200px) translateY(200px); }
}


.product-contact-section {
    position: relative;
    z-index: 0;
}

.product-contact-section::after {
    content: '';
    background: url('/images/toebehoren-main-flower.png') no-repeat 100% 50%;
    position: absolute;
    z-index: -1;
    top: -50%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: productcontactflower 30s linear infinite;
    -moz-animation: productcontactflower 30s linear infinite;
    -o-animation: productcontactflower 30s linear infinite;
    animation: productcontactflower 30s linear infinite;
    min-height: 706px;
    background-size: contain;
}



@-webkit-keyframes productcontactflower {
    0%, 100% { -webkit-transform: translateX(-200px) translateY(-200px); }
    50% { -webkit-transform:  translateX(200px) translateY(200px); }
}

@-moz-keyframes productcontactflower {
    0%, 100% { -moz-transform: translateX(-200px) translateY(-200px); }
    50% { -moz-transform:  translateX(200px) translateY(200px); }
}

@-o-keyframes productcontactflower {
    0%, 100% { -o-transform: translateX(-200px) translateY(-200px); }
    50% { -o-transform:  translateX(200px) translateY(200px); }
}

@keyframes productcontactflower {
    0%, 100% { transform: translateX(-200px) translateY(-200px); }
    50% { transform:  translateX(200px) translateY(200px); }
}




.faq-main {
    position: relative;
    z-index: 0;
    /*padding-bottom: 90px;*/
}

/*
.faq-main::before {
    content: '';
    background: url('/images/over-ons-main-before.png') no-repeat 0 100%;
    position: absolute;
    left: 0;
    bottom: 160px;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: inline-block;
}

*/

.page-over-ons .header-general-top, .page-galerij .header-general-top, .page-faq .header-general-top  {
    position: relative;
    /*z-index: 1;*/
    z-index: 0;
}

/*.page-over-ons .header-general-top::before,*/ .page-galerij .header-general-top::before /*, .page-faq .header-general-top::before*/ {
    content: '';
    background: url('/images/over-ons-flower1.png') no-repeat 50% 0%;
    position: absolute;
    z-index: -1;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: overonsflower1 30s linear infinite;
    -moz-animation: overonsflower1 30s linear infinite;
    -o-animation: overonsflower1 30s linear infinite;
    animation: overonsflower1 30s linear infinite;
    min-height: 674px;
    background-size: contain;
}

/*.page-over-ons .header-general-top::after,*/ .page-galerij .header-general-top::after /*, .page-faq .header-general-top::after*/ {
    content: '';
    background: url('/images/over-ons-flower2.png') no-repeat 66% 0%;
    position: absolute;
    z-index: -1;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: overonsflower2 30s linear infinite;
    -moz-animation: overonsflower2 30s linear infinite;
    -o-animation: overonsflower2 30s linear infinite;
    animation: overonsflower2 30s linear infinite;
    min-height: 563px;
    background-size: contain;
}


.over-ons-main::after {
    content: '';
    background: url('/images/over-ons-flower2.png') no-repeat 50% -50%;
    position: absolute;
    z-index: -1;
    top: -50%;
    left: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: overonsflower2 30s linear infinite;
    -moz-animation: overonsflower2 30s linear infinite;
    -o-animation: overonsflower2 30s linear infinite;
    animation: overonsflower2 30s linear infinite;
    min-height: 563px;
    background-size: contain;
}


@-webkit-keyframes overonsflower1 {
    0%, 100% { -webkit-transform: translateX(-200px) translateY(-200px); }
    50% { -webkit-transform:  translateX(2px) translateY(2px); }
}

@-moz-keyframes overonsflower1 {
    0%, 100% { -moz-transform: translateX(-200px) translateY(-200px); }
    50% { -moz-transform:  translateX(2px) translateY(2px); }
}

@-o-keyframes overonsflower1 {
    0%, 100% { -o-transform: translateX(-200px) translateY(-200px); }
    50% { -o-transform:  translateX(2px) translateY(2px); }
}

@keyframes overonsflower1 {
    0%, 100% { transform: translateX(-200px) translateY(-200px); }
    50% { transform:  translateX(2px) translateY(2px); }
}



@-webkit-keyframes overonsflower2 {
    0%, 100% { -webkit-transform: translateX(-2px) translateY(-2px); }
    50% { -webkit-transform:  translateX(200px) translateY(200px); }
}

@-moz-keyframes overonsflower2 {
    0%, 100% { -moz-transform: translateX(-2px) translateY(-2px); }
    50% { -moz-transform:  translateX(200px) translateY(200px); }
}

@-o-keyframes overonsflower2 {
    0%, 100% { -o-transform: translateX(-2px) translateY(-2px); }
    50% { -o-transform:  translateX(200px) translateY(200px); }
}

@keyframes overonsflower2 {
    0%, 100% { transform: translateX(-2px) translateY(-2px); }
    50% { transform:  translateX(200px) translateY(200px); }
}

/*
.accordion {
    cursor: pointer;
    padding: 5px 45px 5px 10px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    position: relative;
    z-index: 0;
    background: transparent;
    border-bottom: 2px solid $brown;
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-family: "bagnardregular", serif;
    font-style: normal;
    font-weight: 400;
    color: #AF8A4E;
}*/

.accordion {
    cursor: pointer;
    padding: 8px 45px 8px 10px !important;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    position: relative;
    z-index: 0;
    background: transparent;
    border-bottom: 1px solid rgb(175 138 78 / 50%);
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-family: 'calibri', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #AF8A4E;
}

.accordion + .panel {
    padding: 10px;
    display: none;
    /*background-color: #1a1a1a;*/
    background-color: rgba(255, 255, 255, 0.102);
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: calibri, sans-serif;
    color: #000;
    font-size: 20px;
    line-height: 34px;
}

.panel + .accordion {
    /*margin-top: 30px;*/
}

.panel a {
    text-decoration: underline;
    text-underline-position: under;
    color: #AF8A4E;
}

.accordion::after {
    content: '\f067';
    position: absolute;
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    color: var(--white-v1);
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    color: $brown;
}

.accordion.active::after {
    content: '\f068';
    position: absolute;
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    color: var(--white-v1);
    font-family: "Font Awesome 5 Pro";
    font-size: 24px;
    color: $brown;
}

.accordion + .panel p:last-child {
    margin-bottom: 0px;
}





.page-galerij .galerij-main > .container {
    position: relative;
    z-index: 0;
}

.page-galerij .galerij-main  > .container::before {
    content: '';
    background: url('/images/galerij-main-flower.png') no-repeat 50% 0%;
    position: absolute;
    z-index: -1;
    bottom: 10%;
    right: 10%;
    width: 100%;
    height: 100%;
    -webkit-animation: galerijmainflower 30s linear infinite;
    -moz-animation: galerijmainflower 30s linear infinite;
    -o-animation: galerijmainflower 30s linear infinite;
    animation: galerijmainflower 30s linear infinite;
    min-height: 706px;
    max-height: 706px;
    max-width: 689px;
    background-size: contain;
}

.page-galerij .galerij-main  > .container::after {
    content: '';
    background: url('/images/galerij-main-after.png') no-repeat 50% 0%;
    position: absolute;
    z-index: -1;
    bottom: 10%;
    right: 10%;
    width: 100%;
    height: 100%;
    -webkit-animation: galerijmainflowerafter 30s linear infinite;
    -moz-animation: galerijmainflowerafter 30s linear infinite;
    -o-animation: galerijmainflowerafter 30s linear infinite;
    animation: galerijmainflowerafter 30s linear infinite;
    min-height: 706px;
    max-height: 706px;
    max-width: 689px;
    background-size: contain;
}




@-webkit-keyframes galerijmainflowerafter {
    0%, 100% { -webkit-transform: translateX(-344px) translateY(-353px); }
    50% { -webkit-transform:  translateX(344px) translateY(353px); }
}

@-moz-keyframes galerijmainflowerafter {
    0%, 100% { -moz-transform: translateX(-344px) translateY(-353px); }
    50% { -moz-transform:  translateX(344px) translateY(353px); }
}

@-o-keyframes galerijmainflowerafter {
    0%, 100% { -o-transform: translateX(-344px) translateY(-353px); }
    50% { -o-transform:  translateX(344px) translateY(353px); }
}

@keyframes galerijmainflowerafter {
    0%, 100% { transform: translateX(-344px) translateY(-353px); }
    50% { transform:  translateX(344px) translateY(353px); }
}

.page-galerij .galerij-main .gallery {
    position: relative;
    z-index: 99;
}

/*
.page-galerij .galerij-main > .container {
    position: relative;
    z-index: 2;
}*/





.faq-main > div.container {
    position: relative;
/*    z-index: 0;*/
    z-index: 1;
}

.faq-main > div.container::after {
    content: '';
    background: url('/images/galerij-main-flower.png') no-repeat 50% 0%;
    position: absolute;
    /*z-index: 1;*/
    z-index: -1;
    bottom: 10%;
    right: 10%;
    width: 100%;
    height: 100%;
    -webkit-animation: galerijmainflower 30s linear infinite;
    -moz-animation: galerijmainflower 30s linear infinite;
    -o-animation: galerijmainflower 30s linear infinite;
    animation: galerijmainflower 30s linear infinite;
    min-height: 706px;
    max-height: 706px;
    max-width: 689px;
    background-size: contain;
}



@-webkit-keyframes galerijmainflower {
    0%, 100% { -webkit-transform: translateX(-200px) translateY(-200px); }
    50% { -webkit-transform:  translateX(200px) translateY(200px); }
}

@-moz-keyframes galerijmainflower {
    0%, 100% { -moz-transform: translateX(-200px) translateY(-200px); }
    50% { -moz-transform:  translateX(200px) translateY(200px); }
}

@-o-keyframes galerijmainflower {
    0%, 100% { -o-transform: translateX(-200px) translateY(-200px); }
    50% { -o-transform:  translateX(200px) translateY(200px); }
}

@keyframes galerijmainflower {
    0%, 100% { transform: translateX(-200px) translateY(-200px); }
    50% { transform:  translateX(200px) translateY(200px); }
}










.galerij-main .gallery .uk-grid {
    margin-left: -20px;
}
.galerij-main .gallery .uk-grid > div {
    padding-left: 20px;
}

.galerij-main a.uk-inline img {
    border-radius: 10px;
}


.galerij-main {}

.mini-cart {
    position: relative;
    /*left: -15px;*/
    left: 10px;
}

.language-switcher {
    list-style: none;
}

.language-switcher-div ul li a img {
    margin-right: 0px !important;
}

.language-switcher-div ul li a img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.deegkneder-right-chevron-span {
    width: 42px;
    height: 42px;
    min-width: 42px;
    display: inline-block;
    background-color: #bf873c;
    border-radius: 10px;
    line-height: 38px;
}

.home-bakovens-slider-li-div:hover .deegkneder-right-chevron-span,
.home-bakovens-slider-li-div:focus .deegkneder-right-chevron-span {
    background: #202020;
}

.fa-square-info {
    font-family: 'Font Awesome 5 Brands';
}


.more-info {
    width: 42px;
    height: 42px;
    min-width: 42px;
    display: inline-block;
    background-color: #bf873c;
    border-radius: 10px;
    line-height: 38px;
}

.home-bakovens-slider-li-div:hover .more-info , .home-bakovens-slider-li-div:focus .more-info  {
    background: #202020;
    color: #FFF;
}

.footer-logo {
    max-width: 250px;
}

.contact-main-col2 {

}



#product-detail p.category-name {
    font-family: snell-regular, sans-serif;
    font-size: 48px;
    color: #bf873c;
    text-transform: none;
}

/*.algemene-voorwaarden-main,
.handleidingen-main {
    min-height: 40vh;
}*/

.language-switcher-div {
    display: inline-block;
    /*display: none !important;*/
}


.home-inspiration-slider-desktop-inner li img {
    opacity: 0.9;
    object-fit: cover;
    object-position: 0% 50%;
}

.home-inspiration-slider-desktop-inner li:hover img,
.home-inspiration-slider-desktop-inner li:focus img{
    opacity: 1;
}

.home-inspiration-slider-desktop-inner li:nth-child(4n + 1) img {
    border-radius: 0px 150px 0px 0px;
    width: 100% !important;
}

.home-inspiration-slider-desktop-inner li:nth-child(4n + 2) img {
    border-radius: 0px 0px 150px 0px;
    width: 100% !important;
}

.home-inspiration-slider-desktop-inner li:nth-child(4n + 3) img {
    border-radius: 150px 0px 0px 0px;
    width: 100% !important;
}

.home-inspiration-slider-desktop-inner li:nth-child(4n + 4) img {
    border-radius: 0px 0px 0px 150px;
    width: 100% !important;
}



.algemene-voorwaarden-main a,
.disclaimer-main a,
.verzenden-retourneren-main a,
.privacy-policy-main a {
    color: $black;
    text-decoration: underline;
    text-underline-position: under;
}


.algemene-voorwaarden-main a:hover, .algemene-voorwaarden-main a:focus,
.disclaimer-main a:hover, .disclaimer-main a:focus,
.verzenden-retourneren-main a:hover, .verzenden-retourneren-main a:focus,
.privacy-policy-main a:hover, .privacy-policy-main a:focus {
    color: $brown;
    text-decoration: underline;
    text-underline-position: under;
}


@media (min-width: 1200px) {

    .galerij-main .gallery .uk-grid + .uk-grid, .galerij-main .gallery .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
        margin-top: 20px;
    }

    .home-offer-slider-desktop-inner ul {
        margin-left: -70px;
    }

    .home-offer-slider-desktop-inner ul li {
        padding-left: 70px;
    }

    .home-inspiration-slider-desktop-inner ul {
        margin-left: -70px !important;
    }

    .home-inspiration-slider-desktop-inner ul li {
        padding-left: 70px;
    }

}

@media (min-width: 1440px) {

    .galerij-main a.uk-inline img {
        height: 326px;
        object-fit: cover;
    }

}



@media (min-width: 1000px) and (max-width: 1282px) {
    /*header {margin-bottom: -144px;}
    .header-general-top {padding-top: 164px;}*/
}




@media (max-width: 999px) {
    header {
        background: #bf873c;
    }
}

@media (min-width: 320px) and (max-width: 999px) {
  /*  header {margin-bottom: -85px;}
    .header-general-top {padding-top:125px;}*/
}


@media (min-width: 640px) and (max-width: 1271px) {
   .home-bakovens-products-desktop .txt-container,
    .home-popular-products-slider-inner .txt-container {
        min-height: 127px;
    }
}

@media (max-width: 1280px) {
    ul.menu-language-items li {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (max-width: 991px) {

    .header-desktop .col-5 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        padding-right: 20px;
        box-sizing:border-box;
    }

    .language-switcher-div ul {
        margin-right: 20px !important;
    }

    .language-switcher-div ul li a img {
        margin-right: 0px !important;
    }

    .home-categories ul {
        margin-left: auto;
        margin-right: auto;
        padding-left: 0px;
    }

    .home-categories ul li {
        padding-left: 20px;
        padding-right: 20px;
    }


    .webshop-categories-title + .container {
        margin-top: -25px;
    }

    .webshop-categories-container {
        padding-bottom: 40px;
    }
}

.webshop-categories-container > div > div {
    margin-bottom: 20px;
}

.webshop-categories-container img {
    height: 310px;
}












@media (max-width: 991px) {

    .home-inspiration h2 {
        padding-top: 40px;padding-bottom: 0px;
    }

    .webshop-categories-title + .container {
        margin-top: -25px;
    }

    .webshop-categories-container {
        padding-bottom: 40px;
    }
}

.webshop-categories-container > div > div {
    margin-bottom: 20px;
}

.webshop-categories-container img {
    height: 310px;
    width: 100%;
    object-fit: cover;
}

.home-categories-title + .container .col-lg-4 > div {
    height: 100%;
}


.home-categories-title + .container > .row {
    margin-left: -10px;
    margin-right: -10px;
}

.home-categories-title + .container > .row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.home-categories-title + .container > .row > div > .row {
    margin-left: -10px;
    margin-right: -10px;
}

.home-categories-title + .container > .row > div > .row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.home-categories .col-lg-8 .row + .row {
    margin-top: 20px !important;
}

.home-categories .col-lg-4 a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    /*background: $darkgrey-v1;*/
    color: $darkgrey-v1;
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    font-size: 36px;
    line-height: 36.65px;
    text-transform: uppercase;
    background: transparent;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    min-height: 250px;
    /*padding-top: 20px;
    padding-bottom: 20px;*/
    box-sizing: border-box;
}

.home-categories .col-lg-4 a:hover,
.home-categories .col-lg-4 a:focus {
    text-decoration: none;
    background: $brown-v1;
}

.home-categories .col-lg-4 a .txt-overlay {
    display: none;
}

.home-categories .col-lg-4 a:hover .txt-overlay,
.home-categories .col-lg-4 a:focus .txt-overlay {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.home-categories .col-lg-4 a img {
    z-index: 0;
    object-position: 60% 50%;
}

.home-categories .col-lg-4 a:hover img,
.home-categories .col-lg-4 a:focus img {
    z-index: -1;
}

.home-categories .txt {
    max-width: 250px;
    text-align: center;
}

.home-categories .col-lg-4 .txt-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.home-categories .col-lg-4 .txt-overlay .shop-btn {
    font-family: $font1;
    font-size: 17px;
    color: $darkgrey-v1;
    border: 1px solid $darkgrey-v1;
    display: inline-block;
    padding: 0 24px;
    margin-top: 20px;
}

.home-categories .col-lg-4 .txt-overlay .shop-btn:hover,
.home-categories .col-lg-4 .txt-overlay .shop-btn:focus {
    background-color: $darkgrey-v1;
    color: $white;
}

.home-categories img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-categories .col-lg-8 .row + .row {margin-top: 30px;}

.home-ontspannen-genieten {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 100px;
}

.home-ontspannen-genieten-row1 {padding-bottom: 170px;}
.home-ontspannen-genieten-row2 {padding-top: 170px;}

.home-ontspannen-genieten-row1-col2 img {
    position: absolute;
    top: 33.33%;
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.home-ontspannen-genieten-row2-col1 img {
    position: absolute;
    top: 67.666%;
    /*left: 0%;*/
    left: 15px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 577px;
    max-width: 465px;
    object-fit: cover;
}

.home-warmte-in-de-huiskamer {
    padding-top: 105px;
    padding-bottom: 140px;
}

.home-warmte-in-de-huiskamer-row1-col2 {
    text-align: right;
}

.home-warmte-in-de-huiskamer-row1 a {
    display: inline-block;
    background-color: $brown-v1;
    color: $white;
    font-family: $font1;
    font-size: 17px;
    padding: 0 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-warmte-in-de-huiskamer-row1 a:hover,
.home-warmte-in-de-huiskamer-row1 a:focus {
    background-color: $brown-v2;
    text-decoration: none;
}

.home-warmte-in-de-huiskamer-row1 img {
    max-height: 396px;
    max-width: 409px;
    object-fit: cover;
}

.home-warmte-in-de-huiskamer-row2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-warmte-in-de-huiskamer-row2 img {
    max-height: 490px;
    max-width: 467px;
    object-fit: cover;
    object-position: 90% 50%;
}

.home-warmte-in-de-huiskamer p {
    color: $darkgrey-v2;
}

.home-warmte-in-de-huiskamer-row1-col1, .home-ontspannen-genieten-row1-col1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.home-warmte-in-de-huiskamer-row1-col1 > div, .home-ontspannen-genieten-row1-col1 > div{
    max-width: 460px;
    padding: 20px;
    box-sizing: content-box;
}


.home-warmte-in-de-huiskamer-row2-col2, .home-ontspannen-genieten-row2-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-warmte-in-de-huiskamer-row2-col2 > div, .home-ontspannen-genieten-row2-col2 > div {
    max-width: 460px;
    padding: 20px;
    box-sizing: content-box;
}

.home-ontspannen-genieten-row2-col2 > div {
    max-width: 500px;
    padding: 20px;
    box-sizing: content-box;
}

.home-verpakken {
    /*background: url('/images/footer-bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 130px;
    padding-bottom: 110px;
}


.home-verpakken-col1 > div {
    max-width: 480px;
    text-align: justify;
    padding: 20px;
    box-sizing: content-box;
}

.home-verpakken-col1 p {margin-bottom: 0px;}

.home-verpakken-col2 > div {
    position: relative;
    z-index: 1;
    height: 100%;
}

.home-verpakken-col2 img {
    position: absolute;
    top: calc(50% - 10px);
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.home-merken h2 {
    background: -webkit-linear-gradient(#f0e9e3, #fff 75%);
    background: linear-gradient(#f0e9e3, #fff 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font2;
    font-size: 179.76px;
    line-height: 168.91px;
    letter-spacing: 20px;
    margin-left: -10px;
}

.home-merken {
    padding-top: 50px;
    padding-bottom: 100px;
}

.home-merken > div {
    padding-left: 0px;
}

.home-brands > .container {
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.home-brands .row .row + .row {
    margin-top: 90px;
}

.home-brands a {
    pointer-events: none;
    cursor: default;
}

.no-products-added-to-cart .btn {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: "GothamNarrow-Light", sans-serif;
    font-size: 17px;
    padding: 14px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.no-products-added-to-cart .btn:hover,
.no-products-added-to-cart .btn:focus {
    background-color: $brown-v2;
    text-decoration: none;
}



.category-detail .product-list-item {
    text-align: center;
    border: none;
}

.category-detail .product-list-item:hover {
    background-color: transparent;
}

.category-detail .product-list-item:before {
    display: none;
}

.category-detail .product-list-item .product-info:before {
    display: none;
}

.category-detail .product-title {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
}

.category-detail .product-list-item .product-info .product-price {
    font-family: $font2;
    color: $darkgrey-v1;
    font-size: 20px;
}

.category-detail-main {
    padding-bottom: 40px;
}

.categories-filter-title h4 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 36.65px;
    color: $brown-v1;
    /*border-bottom: 1px solid rgba(18, 14, 14, 0.2);*/
    text-transform: uppercase;
}



#product-detail {}

#product-detail > div > div {
    padding-top: 20px !important;
}

#product-detail .productgalleryslider {
    padding-left: 0px;
    padding-right: 10px;
}

#product-detail .productgallerymainimg {
    padding-left: 10px;
}

#product-detail .slick-slider .slick-track {
    margin-top: -5px;
}

#product-detail .main-image {
    width: 100%;
    height: 100%;
    /*max-height: 460px;*/
    max-height: 529px;
    text-align: center;
    border: 1px solid #C4C4C4;
    max-width: 529px;
    margin-left: 0px;
    margin-right: auto;
}

#product-detail .image-slide {
    max-width: 529px;
    margin-left: 0px;
    margin-right: auto;
}

#product-detail .main-image img {
    width: 100%;
    height: 100%;
    max-height: 460px;
    /*object-fit: cover;*/
    object-fit: contain;
}

#product-detail .product-title {
    font-family: $font2;
    font-size: 30px;
    color: $brown-v1;
    text-align: left;
    letter-spacing: -1px;
}

#product-detail .brand-name {
    font-family: $font2;
    color: $brown-v2;
    font-size: 25px;
}

#product-detail .product-detail-price {
    font-family: $font2;
    color: $darkgrey-v1;
    font-size: 20px;
}

#product-detail .description-top {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
    line-height: 36px;
}

#product-detail .description-top p {
    margin-bottom: 0px;
}

#product-detail .description-top-link {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 1px solid rgba(39,33,30,0.251);
    display: inline-block;
}

#product-detail .description-top-link:hover,
#product-detail .description-top-link:focus {
    border-bottom: 1px solid rgba(39,33,30,1);
    text-decoration: none;
}

#product-detail .variants .variant:hover .image-container,
#product-detail .variants .variant.active-variant .image-container {
    border: 2px solid rgba(39,33,30,0.251);
}

#product-detail .variants .variant {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    padding-top: 0.25rem !important;
}


#product-detail .variants .image-container img {
    width: 100%;
    height: 100%;
    max-width: 96px;
    max-height: 96px;
}

#product-detail .qty-group input {
    border: none !important;
}

#product-detail .qty-group {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    height: 45px;
}

.qty-add-to-cart-max {
    max-width: 360px;
}

.qty-group .qty-up {
    position: absolute;
    bottom: 4px;
    right: 7px;
}

/*#product-detail .qty-group .qty-down {
    position: relative;
    bottom: 0px;
    left: 0px;
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 14px;
}

#product-detail .qty-group .qty-up {
    position: relative;
    bottom: 0px;
    right: 0px;
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 14px;
}*/

/*
#product-detail .qty-group .qty-field {
    border: 1px solid rgb(214, 214, 214) !important;
    width: 120px;
    height: 34px !important;
    border-radius: 0px !important;
    margin-left: 10px;
    margin-right: 10px;
}
*/

#product-detail .order-btn {
    /* background: $brown-v1;
    color: $white;
    font-family: $font1;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0 35px;*/
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0px 35px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 45px;
}

#product-detail .order-btn:hover,
#product-detail .order-btn:focus {
    background: $brown-v2;
}

#product-detail .qty-add-to-cart .col-5 {
    padding-right: 0px;
}

#product-detail .qty-add-to-cart p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Effra;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #000;
}

#product-detail .productconfiguration-name {
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #1E1E20;
}

#product-detail .order-btn img {
    margin-right: 10px;
}

.qty-add-to-cart .qty-group {

}

#product-detail .variants {}

#product-detail .product-usps {

}

#product-detail .product-usps ul {
    list-style: none;
    padding-left: 0px;
}

#product-detail .product-usps li svg {
    margin-right: 15px;
    font-weight: bold;
}

#properties-description {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 80px;
}

#properties-description .properties h2 {
    font-family: $font2;
    font-size: 19.98px;
    line-height: 39.96px;
    color: $brown-v1;
    text-transform: none;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

#properties-description .properties h2 + p {
    margin-top: 0px !important;
}

#properties-description .description h2 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    font-size: 38px;
    line-height: 39.96px;
}

#properties-description .description span + h2 {
    margin-top: 5px !important;
}

.product-list-item .image-container {
    padding-bottom: inherit;
}

.product-list-item .image-container img {
    /*max-width: 240px;
    max-height: 240px;*/
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    height: 300px;
}


#related-products h2 {
    max-width: 480px;
    font-weight: 400;
}

section#related-products {
    /*background: $white;*/
    background: transparent;
    padding-bottom: 0px !important;
}

section#related-products h2 {
    font-family: snell-regular, sans-serif;
    font-size: 48px;
    color: #bf873c;
    text-transform: none;
}


#related-products .related-products {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}

#related-products .product-list-item {
    border: 1px solid $white;
}

#related-products .product-list-item .product-info:before {
    background-color: $white;
}

#related-products .product-list-item .image-container {
    padding-bottom: inherit;
}

#related-products .product-list-item .image-container img {
    max-width: 240px;
    max-height: 240px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

#related-products .product-list-item:before {
    display: none !important;
}

#related-products .product-list-item .product-info {
    text-align: center;
}

.category-detail-main .product-info {
    text-align: center;
}

.category-detail-main .product-info  .product-price {
    font-family: $font2;
    font-size: 20px;
    color: $darkgrey-v1;
}

#related-products .product-list-item .product-info .product-title {
    font-family: $font1;
    color: $darkgrey-v1;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
}


.back-to-webshop {
    padding-top: 20px;
    /*margin-top: 0px !important;*/
    margin-top: 3rem !important;
    padding-bottom: 20px !important;
}

.back-to-webshop a {
    background: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0 43px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.back-to-webshop a:hover,
.back-to-webshop a:focus {
    background: $brown-v2;
    text-decoration: none;
}


.privacyverklaring-main-content-table-desktop {
    overflow-x: auto;
}

.privacyverklaring-main-content-table-desktop table {
    border-collapse: collapse;
}

.privacyverklaring-main-content-table-desktop table tr {
    border-bottom: 1px solid #ccc;
}

.privacyverklaring-main-content-table-desktop table tr th,
.privacyverklaring-main-content-table-desktop table tr td {
    text-align: left;
    padding: 5px 15px 5px 5px;
}

.pager {
    margin-bottom: 40px;
}

.pager a {
    font-family: Effra;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    color: #2B2B2B;
}

.pager .my-active span {
    font-family: $font2;
    color: $brown-v1;
    font-size: 20px;
    font-weight: bold;
}

.pager .prev-btn a, .pager .next-btn a {
    background: #74C6C7;
    color: #FFF;
    padding: 8px 15px;
    box-sizing: border-box;
}

.pager .prev-btn a:hover, .pager .next-btn a:hover,
.pager .prev-btn a:focus, .pager .next-btn a:focus {
    background: #2B2B2B;
}

.pager .disabled {
    display: none;
}

/*.category-detail-header,*/ .cartnav {
                                 /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
                             /*    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
                                 background-size: cover;
                                 padding-top: 40px;
                                 padding-bottom: 40px;*/
                             }

.checkout-title-container {
    position: relative;
    z-index: 0;
    background: #74C6C7;
}

.checkout-top-usps {
    background: #2B2B2B;
}




.category-detail-header h1, .product-detail-header h1, .checkout-title-container h1 {
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
    text-transform: none;
}

.product-detail-header h1 {
    display: block;
}

.category-detail-header a,
.category-detail-header a + svg,
.category-detail-header i,
.product-detail-header a,
.product-detail-header a + svg,
.product-detail-header i {
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}



.category-detail-header > div:nth-child(1) {
    position: relative;
    z-index: 0;
    background: #74C6C7;
}

.category-detail-header > div:nth-child(1)::before,
.product-detail-header > div:nth-child(1) {
    position: relative;
    z-index: 0;
}

.product-detail-header > div:nth-child(1)::before {
    content: "";
    background: url('/images/Frame7.png') no-repeat;
    position: absolute;
    top: calc(50% + 20px);
    left: 100%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    z-index: -1;
    width: 100%;
    height: 100%;
    max-width: 610px;
    max-height: 128px;
}

.category-detail-header > div:nth-child(1) > div:nth-child(1),
.product-detail-header > div:nth-child(1) > div:nth-child(1){
    padding-top: 40px;
    padding-bottom: 20px;
}

.category-detail-header .categorie-top-usps, .product-top-usps {
    background: #2B2B2B;
}

.category-detail-header .categorie-top-usps * {

}

.category-detail-main .catprod .image-container {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
}

.category-detail-main .catprod .product-info {
    background: #2B2B2B;
    margin-left: 0px;
    margin-right: 0px;
    padding: 15px 15px !important;
    height: 113px;
}

.category-detail-main .catprod .product-info .product-title {
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.category-detail-main .catprod .product-info .product-price {
    font-family: Effra;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.category-detail-main .catprod .product-info .col-10 {
    padding-left: 0px !important;
}

/*.category-detail-main .catprod .product-info .col-2 img {
    min-width: 30px;
}*/

.category-detail-main .catprod .product-info .col-2 img {
    width: 24px;
    position: relative;
    top: -1px;
}

/*.category-detail-main .catprod .product-info .col-2 img {
    width: 30px;
    height: 30px;
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
}*/

.cartnav ul {
    margin-bottom: 0px;
}

.product-detail-header {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background-size: cover;
    padding-top: 0px;
    padding-bottom: 0px;
    background: #74C6C7;
}

.category-detail-main .category-filter-item + .category-filter-item {
    position: relative;
    z-index: 0;
}

.category-detail-main .category-filter-item + .category-filter-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    z-index: -1;
    width: calc(100% - 30px);
    border-top: 1px solid rgba(116, 198, 199, 0.5);
}

.category-detail-main .category-filters-container {
    /*background: #f6f0ea;*/
    padding: 60px 50px 60px 0px;
    box-sizing: border-box;
}

.category-detail-main .category-filters-container h4 {
    /*border-bottom: 1px solid rgba(18, 14, 14, 0.2);*/
    padding-bottom: 10px;
}

.category-detail-main .category-filters-container h4,
.category-detail-main .category-filters-container h4 * {
    /*font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 18px;
    text-transform: uppercase;*/
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 200%;
    color: #1E1E20;
    text-transform: none;
}

.category-detail-main .category-filters-container h4 > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-main .category-filters-container h4 .fal {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    color: #272727;
    font-size: 18px;
}

.filter-mob .fal {
    font-size: 18px;
}

.filter-mob h4 {
    font-family: "elegante_classica", sans-serif;
    color: #a26c35;
    font-size: 18px;
    text-transform: uppercase;
}

.filter-mob h4 .fal {
    color: #272727;
}

.filter-mob h4 div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-header a, .product-detail-header a {
    font-family: $font1;
    /*color: $brown-v2;*/
    color: #FFF;
}

.category-detail-header a:hover,
.category-detail-header a:focus,
.product-detail-header a:hover,
.product-detail-header a:focus {
    color: $brown-v1;
    text-decoration: none;
}

.product-detail-header .align-items-center > div {
    position: relative;
    z-index: 10;
}

.category-detail-main > div > div.col-xl-9 {
    padding-top: 60px;
}

.category-detail-main .category-filters-container .category-filter-item > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.category-detail-main .category-filters-container .category-filter-item a {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
}

.category-detail-main .category-filters-container .category-filter-item ul {
    list-style: none;
    padding-left: 0px;
}

/*.category-detail-main .category-filters-container .category-filter-item .sub-cat::marker {
        display: none;
    }*/

.category-detail-main .category-filters-container .category-filter-item .sub-cat a {
    display: inline-block;
}


#description-long-link {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
}

#brand-filter {
    color: $darkgrey-v1;
    font-family: $font1;
    font-size: 18px;
}

.header-home-icons {
    position: relative;
    z-index: 999;
}


.catprod {
    position: relative;
}

.catprod .lebel {
    position: absolute;
    top: 1px;
    /*left: 12px;*/
    left: 1px;
    background-color: #74C6C7;
    color: #FFF;
    box-sizing: border-box;
    padding: 5px 10px;
    font-family: $font2;
}


.over-ons-header > div > div > div,
.contact-header > div > div > div,
.algemene-voorwaarden-header > div > div > div,
.disclaimer-header > div > div > div,
.privacy-policy-header > div > div > div {
    position: relative;
    z-index: 0;
}

/*.over-ons-header > div > div > div > div:nth-child(1),
.contact-header > div > div > div > div:nth-child(1),
.algemene-voorwaarden-header > div > div > div > div:nth-child(1),
.disclaimer-header > div > div > div > div:nth-child(1),
.privacy-policy-header > div > div > div > div:nth-child(1) {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 100px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}*/

.over-ons-header h1,
.contact-header h1,
.algemene-voorwaarden-header h1,
.disclaimer-header h1,
.privacy-policy-header h1 {
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
    max-width: 800px;
    text-transform: none;
}

.over-ons-header > div,
.contact-header > div,
.algemene-voorwaarden-header > div,
.disclaimer-header > div,
.privacy-policy-header > div {
    padding-top: 40px;
    padding-bottom: 20px;
}

.over-ons-header,
.contact-header,
.algemene-voorwaarden-header,
.disclaimer-header,
.privacy-policy-header {
    position: relative;
    z-index: 0;
}

.over-ons-header::before,
.contact-header::before,
.algemene-voorwaarden-header::before,
.disclaimer-header::before,
.privacy-policy-header::before {
    content: '';
    background: url('/images/Frame7.png') no-repeat;
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    z-index:-1;
    width: 100%;
    height: 100%;
    max-width: 610px;
    max-height: 128px;
}

.over-ons-usps {
    background: #2B2B2B;
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}


.over-ons-rust {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 180px;
    padding-bottom: 70px;
}

.over-ons-rust h2 {
    max-width: 320px;
}

.over-ons-rust p {
    max-width: 460px;
}

/*
.over-ons-rust-col2 {
    position: relative;
}

.over-ons-rust-col2 img {
    position: absolute;
    top: 66%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 662px;
    max-width: 557px;
    object-fit: cover;
}*/

.over-ons-uniek-verdeler > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-kwaliteit {
    padding-top: 200px;
    padding-bottom: 140px;
}

.over-ons-kwaliteit > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-kwaliteit > div > div > div:nth-child(2) {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

}

.over-ons-kwaliteit img {
    max-height: 490px;
    max-width: 467px;
    object-fit: cover;
}

.over-ons-kwaliteit h2 {
    max-width: 340px;
}

.over-ons-kwaliteit p {
    color: $darkgrey-v2;
    max-width: 470px;
}

.over-ons-uniek-verdeler > div {
    padding-top: 90px;
    padding-bottom: 90px;
}

.over-ons-slider-section {
    background: url('/images/about-us-slider-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 90px;
}

.over-ons-slider-section .uk-slidenav {
    color: $brown-v1;
}

.over-ons-slider-section .uk-slidenav:hover,
.over-ons-slider-section .uk-slidenav:focus {
    color: $brown-v2;
}

.over-ons-slider-section .uk-slider-items img {
    max-height: 280px;
    width: 100%;
    object-fit: cover;
}

.over-ons-contact {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 140px
}

.over-ons-contact p {
    max-width: 420px;
}

/*.over-ons-contact a {
    display: inline-block;
    background-color: #a26c35;
    color: #fff;
    font-family: "GothamNarrow-Light", sans-serif;
    font-size: 17px;
    padding: 7px 15px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    border-radius: 0px;
}*/

.over-ons-contact a:hover,
.over-ons-contact a:focus {
    /*background-color: #ccb59e;*/
    text-decoration: none;
}

.over-ons-contact-col2 {
    position: relative;
    z-index: 1;
}

.over-ons-contact-col2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.over-ons-contact > div > div > div:nth-child(1) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.contact-main form input:not([type=checkbox]):not([type=radio]) {
    height: 40px !important;
}

.contact-main form input:not([type=checkbox]):not([type=radio]),
.contact-main form textarea {
    font-size: 18px !important;
}

.contact-main .privacy-notice-paragraph {
    font-size: 12px;
    line-height: 18px;
}


/*.contact-main .privacy-notice-paragraph a {
    color: $brown-v2;
}*/

.contact-main .privacy-notice-paragraph a:hover,
.contact-main .privacy-notice-paragraph a:focus {
   /* color: $brown-v1;*/
    text-decoration: none;
}

.contact-main input[type=checkbox] {
    border: 1px solid $brown-v1;
}

.contact-main textarea {
    /*border: 1px solid $brown-v1 !important;*/
}

.contact-main label::before {
    display: none !important;
}

.contact-main button {
    display: inline-block;
    background-color: $brown-v1;
    border: 1px solid $brown-v1;
    color: #fff;
    font-family: "GothamNarrow-Light", sans-serif;
    font-size: 17px;
    padding: 0 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 20px;
}

.contact-main button:hover,
.contact-main button:focus {
    background-color: $brown-v2;
    border: 1px solid $brown-v2;
    text-decoration: none;
}

/*.contact-main > div > div > div:nth-child(2) a {
    color: $main-color;
}

.contact-main > div > div > div:nth-child(2) a:hover,
.contact-main > div > div > div:nth-child(2) a:focus {
    color: $black;
}*/

.contactmap {
    margin-top: 40px;
}

.contact-main #form-group-checkbox-group-1635514227957 label {
    font-size: 18px;
    line-height: 24px;
}

.site-footer {
    /*background: url('/images/footer-bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;*/
    background: $black;
    padding-top: 70px;
    padding-bottom: 70px;
}

.site-footer * , .site-footer a {
    font-family: $font3;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $white;
}

.site-footer a:hover, .site-footer a:focus {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}

.site-footer h3 {
    font-family: $font3;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    text-transform: none;
}
/*
.footer-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.footer-col3 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}*/

.footer-col4 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    text-align: right;
}

.searchpage-content > div {
    padding-top: 70px;
    padding-bottom: 70px;
}

.searchpage-content .search-field {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.searchpage-content .search-field-input,
.searchpage-content .do-search-button {
    display: inline-block;
}

.searchpage-content .search-field-input input {min-width: 300px;padding-left: 10px;padding-right: 10px;}

.searchpage-content .do-search-button {
    background: $brown-v1;
    color: $white;
    padding: 10px 20px;
    box-sizing: border-box;
    height: 48px;
    line-height: 36px;
    cursor: pointer;
}

.searchpage-content .do-search-button:hover,
.searchpage-content .do-search-button:focus {
    background: $brown-v2;
}


.uk-offcanvas-bar {
    /*background: url(/images/bg.jpg) no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background: #FFF;
    background-size: cover;
}

.uk-offcanvas-bar .menu-main-menu li {
    display: block;
    width: 100%;
}

.offcanvas-logo {
    margin-top: 100px;
    margin-bottom: 40px;
    display: inline-block;
}

.uk-offcanvas-bar .menu-main-menu {
    height: auto !important;
}

.uk-offcanvas-bar .menu-main-menu li a {
    font-family: "GothamNarrow-Bold", sans-serif;
    font-size: 22px;
    line-height: 38px;
    color: #a26c35 !important;
    text-transform: uppercase;
}

.uk-offcanvas-bar .menu-main-menu li a:hover,
.uk-offcanvas-bar .menu-main-menu li a:focus {
    color: $brown-v2 !important;
    text-decoration: none;
}

.uk-offcanvas-close {
    /*color: #FFF;*/
    color: $brown-v2;
}

.uk-offcanvas-close:hover,
.uk-offcanvas-close:focus {
    color: $brown-v1;
}

.uk-offcanvas-close svg {
    width: 26px;
    height: 26px;
}


ul.menu-main-menu > li > a:after, ul.menu-main-menu > li > a:visited:after, ul.menu-main-menu > li > span:after, ul.menu-language-items > li > a:after, ul.menu-language-items > li > a:visited:after, ul.menu-language-items > li > span:after {
    display: none;
}

.form-steps li {
    border: none;
    transform: none;
}


.form-steps {
    padding-left: 0px;
}


.form-steps li.enabled, .form-steps li.current {
    border: none;
    background: none;
}

.form-steps li svg.fa-check-circle, .form-steps li svg.fa-circle,
.form-steps li i.fa-check-circle, .form-steps li i.fa-circle {
    margin-right: 8px;
    position: relative;
    top: 5px;
    width: 27px !important;
    height: 27px;
    color: $brown-v2;
    font-size: 27px;
}

.form-steps svg.fa-horizontal-rule, .form-steps i.fa-horizontal-rule {
    max-width: 14px;
    margin-left: 6px;
    /*margin-right: 6px;*/
    margin-right: 15px;
}

.form-steps li a, .form-steps li span {
    transform: none;
    padding: 10px 0px;
    color: #000;
}

.cart-content-section {
    padding-top: 70px;
    padding-bottom: 70px;
    /*background: #000;*/
    background: transparent;
}


.order-btn, .proceed-button a {
    background-color: $brown;
}

.order-btn:hover, .proceed-button a:hover,
.order-btn:focus, .proceed-button a:focus {
    /*background-color: $brown-v2;*/
    background-color: #2B2B2B;

}

.checkout-page {
    .invalid-feedback {
        display:block !important;
        list-style: none;
        padding-left: 0;
    }
}

.checkout-page .butn-next {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 20px;
    padding: 10px 28px;
    /*text-transform: uppercase;*/
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 25px;
}

.checkout-page .butn-next:hover,
.checkout-page .butn-next:focus {
    background-color: #2B2B2B;
    text-decoration: none;
}


.checkout-page .butn-light {
    display: inline-block;
    background-color: #F3F3F3;
    /*color: #fff;
    font-family: $font1;
    font-size: 17px;
    padding: 10px 28px;*/
    font-family: Effra;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    /*line-height: 24px;*/
    /* identical to box height */
    color: #0A0A0A;
    padding: 10px 28px;
    /*text-transform: uppercase;*/
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 25px;
}

.checkout-page .butn-light:hover,
.checkout-page .butn-light:focus {
    background-color: $brown-v1;
    text-decoration: none;
}

.login-existing-customer a {
    color: $brown-v1;
}

.login-existing-customer a:hover,
.login-existing-customer a:focus {
    color: $brown-v2;
    text-decoration: none;
}

#login-form-modal .btn {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 17px;
    padding: 10px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

#login-form-modal .btn:hover,
#login-form-modal .btn:focus {
    background-color: $brown-v2;
}

.form-control, .select2-container .selection .select2-selection {
    border-radius: 0px;
}

.select2-container .selection .select2-selection {
    border-radius: 0px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    border-radius: 0px !important;
}

.cart-item-block {
    border: 1px solid #ccc;
    padding: 10px;
    /*border-radius: 6px;*/
    border-radius: 0px;
}

.cart-item-block .image-container img {
    /*border-radius: 6px;*/
    border-radius: 0px;
}

.cart-content-row + .cart-content-row {
    border-top: none;
    margin-top: 30px;
}

.filter-mob {
    padding-left: 15px;
    padding-right: 15px;
}

.filter-mob a {
    font-family: "GothamNarrow-Light", sans-serif;
    color: #27211e;
    font-size: 18px;
}

.filter-mob .category-filter-item > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.filter-mob .subcategories-list {
    list-style: none;
    padding-left: 0px;
}

.filter-mob .subcategories-list li a {
    display: inline-block;
}

.mini-cart .badge-main {
    /*background-color: $brown-v1;*/
/*    background-color: #2b2b2b;
    color: $white;
    position: absolute;
    right: -2px;
    top: -14px;*/
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 8.28px 0.72px rgba(0, 0, 0, 0.21);
    color: #000;
    position: absolute;
    right: -4px;
    /*top: -20px;*/
    top: 2px;
    border-radius: 100%;
}


.size-box input + label {
    padding: 5px 10px;
}

.size-box input:checked + label {
    background-color: $brown-v1;
    color: $white;
}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    box-shadow: none;
}

.select2-container .selection .select2-selection {
    /*border: 1px solid #a26c35;*/
    border: 1px solid #c4c4c4;
    border-radius: 0.25rem;
    height: 45px;
}

.voorwaarden-link {color: $brown-v1;}
.voorwaarden-link:hover,
.voorwaarden-link:focus {color: $brown-v2;}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    border: 1px solid #a26c35;
    /*border-color: #a26c35;*/
    border: 1px solid #C4C4C4;
    border-color: #C4C4C4;
}

.home-brands .row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-brands .row img {
    max-height: 120px;
    object-fit: contain;
}


.algemene-voorwaarden-main a {
    color: #ccb59e;
}

.algemene-voorwaarden-main a:hover,
.algemene-voorwaarden-main a:focus {
    color: #a26c35;
    text-decoration: none;
}

.algemene-voorwaarden-main p,
.algemene-voorwaarden-main li {
    color: #fff;
}

.algemene-voorwaarden-main h3 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}

.algemene-voorwaarden-main #art1,
.algemene-voorwaarden-main #art2,
.algemene-voorwaarden-main #art3,
.algemene-voorwaarden-main #art4,
.algemene-voorwaarden-main #art5,
.algemene-voorwaarden-main #art6,
.algemene-voorwaarden-main #art7,
.algemene-voorwaarden-main #art8,
.algemene-voorwaarden-main #art9,
.algemene-voorwaarden-main #art10,
.algemene-voorwaarden-main #art11,
.algemene-voorwaarden-main #art12,
.algemene-voorwaarden-main #art13,
.algemene-voorwaarden-main #art14,
.algemene-voorwaarden-main #art15,
.algemene-voorwaarden-main #art16 {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;
}


.product_option_remarks {
    display: none;
}

.menuwebshop {margin-left: 30px;}

/*.webshop-categories > div.container {margin-bottom: 20px;}
.webshop-categories > div.container {
    padding-left: 0px;
    padding-right: 0px;
}
.webshop-categories > div.container > div.row {
    margin-left: -10px;
    margin-right: -10px;
}

.webshop-categories > div.container > div.row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.webshop-categories > div.container > div.row > div > div.row {
    margin-left: -10px;
    margin-right: -10px;
}*/



/*@media (min-width: 960px) and (max-width: 1199px) {
        .over-ons-slider-section .uk-slidenav {
            background: #FFF;
            color: #a26c35;
        }

        .home-product-slider-nav {
            display: block !important;
        }
    }*/

input, select, textarea {-webkit-appearance: none;}

.price-input:before {
    top: 2px;
}

.cart-page .qty-selector .cart-qty {
    height: 35px;
    border: none;
    width: auto;
    text-align: left !important;
}

.toggle-filter {
    border-radius: 0px !important;
}

.cart-qty-selector {
    position: relative;
    display: inline-block;
    border: 1px solid $brown-v1;
    padding: 0px 5px;
    box-sizing: border-box;
}

.qty-selector .cart-qty-select-open {
    height: auto;
    width: auto;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
    margin-left: 0px;
    padding: 0px 0;
    cursor: pointer;
}

.qty-selector .cart-qty-select-options {
    left: -1px;
}


.cart-page .add-discount-code,
.checkout-page .add-discount-code {
    display: inline-block;
    background-color: #74C6C7;
    color: #fff;
    font-family: "GothamNarrow-Light", sans-serif;
    font-size: 17px;
    padding: 10px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.cart-page .add-discount-code:hover,
.cart-page .add-discount-code:focus,
.checkout-page .add-discount-code:hover,
.checkout-page .add-discount-code:focus {
    background-color: #2B2B2B;
    text-decoration: none;
}


.home-familybusiness-col1 img {
    border-radius: 0px 0px 250px 0px;
}

@media (min-width: 960px) and (max-width: 1680px) {
    .uk-position-center-left-out {right:calc(100% - 60px);}
    .uk-position-center-right-out {left:calc(100% - 60px);}
}

@media (min-width: 992px) and (max-width: 1199px) {

    .home-usps ul {
        padding-left: 12px;
        padding-right: 12px;
    }

    .home-usps ul li {
        padding-right: 12px;
    }

    .home-ontspannen-genieten-row1-col2 img {max-width: 457px;}
    .home-ontspannen-genieten-row2-col1 img {max-width: 457px;}
    /* .home-verpakken-col2 img {max-width: 457px;}*/
    .over-ons-rust-col2 img {max-width: 457px;}
    .over-ons-contact-col2 img {max-width: 457px;}
    .over-ons-slider-section {
        padding-bottom: 150px;
    }

    .home-categories-section {padding-bottom: 100px !important;}

    .home-ontspannen-genieten-row1 {
        padding-bottom: 100px;
    }
    .home-ontspannen-genieten-row2 {
        padding-top: 100px;
    }

}


@media (min-width: 992px) and (max-width: 1239px) {
    .header-general-top-categories .category-filter-item {
        padding-left: 12px;
        padding-right: 12px;
    }
    .header-general-top-categories .category-filter-item a {
        font-size: 18px;
        padding: 9px 19px;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .header-general-top-categories .category-filter-item {
        padding-left: 6px;
        padding-right: 6px;
    }
    .header-general-top-categories .category-filter-item a {
        font-size: 16px;
        padding: 9px 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .home-ontspannen-genieten-row1-col2 img {max-width: 357px;}
    .home-ontspannen-genieten-row2-col1 img {max-width: 357px;}
    /*.home-verpakken-col2 img {max-width: 357px;}*/
    .over-ons-rust-col2 img {max-width: 357px;}
    .over-ons-kwaliteit img {max-width: 367px;}
    .over-ons-contact-col2 img {max-width: 357px;}
    .over-ons-slider-section {
        padding-bottom: 140px !important;
    }
    .home-categories-section {padding-bottom: 100px !important;}
}


.whatsappac {
    margin-left: 0;
}

@media (min-width: 768px) {

    .whatsappad {
        display: none;
    }

    .whatsappam {
        margin-left: 0;
    }


    .home-familybusiness div.container div.row > a {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .home-familybusiness div.container div.row > a div.home-familybusiness-col1 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .home-main > div > div > div:nth-child(1) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .home-bottom > div > div > div:nth-child(2) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }

    .home-bottom > div > div > div:nth-child(2) img {
        max-width: 552px;
        object-fit: contain;
    }




    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }

    .contact-main > div > div > div:nth-child(1) {padding-right: 20px;}
    .contact-main > div > div > div:nth-child(2) {padding-left: 20px;}

    /*.productgalleryslider {
        flex: 0 0 17.15%;
        max-width: 17.15%;
    }
    .productgallerymainimg {
        flex: 0 0 82.85%;
        max-width: 82.85%;
    }*/

    .catprod {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

}


@media (min-width: 992px) {

    .home-levensduur-col2 {
        text-align: right;
    }

    .home-our-ovens > .container > .row > div:nth-child(2) {
        text-align: right;
    }

    .home-warmte-in-de-huiskamer-row2 {
        padding-top: 80px;
    }

    .cart-page .cart-page-cart-content-totals {
        margin-left: 4%;
    }

    .checkout-page .cart-totals-html-desktop {
        margin-left: 4%;
    }

    .cart-totals-html-mobile {
        display: none;
    }

    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 750px;
    }

    .contact-main > div > div > div:nth-child(1) {padding-right: 40px;}
    .contact-main > div > div > div:nth-child(2) {padding-left: 40px;}

    .privacyverklaring-main-content-table-mobile {
        display: none;
    }
}



@media (min-width: 1200px) {

    .over-ons-uniek-verdeler .over-ons-rust-col1 * {max-width: 615px;}
    .over-ons-uniek-verdeler .over-ons-rust-col2 {text-align: right;}
    .over-ons-uniek-verdeler .over-ons-rust-col2 img {max-width: 555px;}

    .site-footer .col-lg-4 {
        flex: 0 0 30.3333333333%;
        max-width: 30.3333333333%;
    }

    .site-footer .col-lg-2 {
        flex: 0 0 19.6666666667%;
        max-width: 19.6666666667%;
    }

    /* .container {
        max-width: 1470px;
    }*/

    .container {
        max-width: 1280px;
    }

    .category-detail-main, .checkout-page-form {
        max-width: 1280px;
    }



    .pager {margin-top: 60px;}

    .info-header > div {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    /*.category-detail-header > div {
        padding-left: 262px;
    }*/

    .category-detail-header > div:nth-child(1) > div:nth-child(1), .category-detail-header > div:nth-child(1) > div:nth-child(1) {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .cartnav  > div {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .checkout-title-container > div {
        max-width: 1280px;
        padding-left: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .product-detail-header > div > div {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .category-detail-main > div > div.col-xl-3 {
        flex: 0 0 21.94%;
        max-width: 21.94%;
    }

    .site-footer > .container {
        max-width: 1360px;
    }


}

@media (min-width: 992px) {

    .home-unieke-collectie-homewear img {
        height: 662px;
        /*max-width: 557px;*/
        object-position: 43% 50%;
    }

    .home-categories img {
        min-height: 346px;
    }

    .home-ontspannen-genieten-row1-col2 img {
        height: 674px;
    }

    .home-ontspannen-genieten-row2-col1 img {
        height: 577px;
        object-position: 66% 50%;
    }

    .home-warmte-in-de-huiskamer-row1 img {
        height: 396px;
    }

    .home-warmte-in-de-huiskamer-row2 img {
        height: 490px;
    }

    .home-verpakken-col2 img {
        height: 674px;
    }

    /*.over-ons-rust-col2 img {
        height: 662px;
    }*/

    .over-ons-rust-col2 img {
        height: 500px;
    }

    .over-ons-kwaliteit img {
        height: 490px;
    }

    .over-ons-contact-col2 img {
        height: 674px;
    }

}

@media (max-width: 1800px) {

    .pl-5, .px-5 {
        padding-left: 40px !important;
    }

    .pr-5, .px-5 {
        padding-right: 40px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 40px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 40px !important;
    }

}

@media (max-width: 1439px) {

    .container {
        padding-left: 25px;
        padding-right: 25px;
    }



    .pl-5, .px-5 {
        padding-left: 40px !important;
    }

    .pr-5, .px-5 {
        padding-right: 40px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 40px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 40px !important;
    }


    .home-usps ul {
        margin-right: -40px;
    }

    .home-usps ul li {
        padding-right: 40px;
        font-size: 15px;
    }

    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        left: 40px;
    }

}



@media (min-width: 768px) and (max-width: 1199px) {
    .site-footer .col-lg-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .site-footer  .footer-col4 {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .site-footer  .footer-col1,
    .site-footer  .footer-col3 {
        padding-bottom: 40px;
    }

}

@media (max-width: 1199px) {


        .home-offer-slider-desktop-inner ul {
        margin-left: -40px;
    }

    .home-offer-slider-desktop-inner ul li {
        padding-left: 40px;
    }

    .home-inspiration-slider-desktop-inner ul {
        margin-left: -40px !important;
    }

    .home-inspiration-slider-desktop-inner ul li {
        padding-left: 40px;
    }

    .pl-5, .px-5 {
        padding-left: 20px !important;
    }

    .pr-5, .px-5 {
        padding-right: 20px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 20px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 20px !important;
    }

    .product-detail-header {
        padding-top: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .alert + .product-detail-header {
        padding-top: 20px;
    }

    .mini-cart .badge-main {
        /*background-color: $brown-v1;*/
/*        background-color: #2b2b2b;
        color: $white;
        position: absolute;
        right: -2px;
        top: -14px;*/
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 4px 8.28px 0.72px rgba(0, 0, 0, 0.21);
        color: #000;
        position: absolute;
        right: -4px;
        /*top: -20px;*/
        top: 2px;
        border-radius: 100%;
    }


    header > div.px-5 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .info-header .search-field {
        margin-bottom: 0px;
    }



    .home-usps ul {
        /*margin-right: -20px;*/
        margin-right: 0px;
    }

    /*.home-usps ul li {
    padding-right: 20px;
}*/

    .home-usps ul li {
        padding-right: 8px;
    }

    .container {
        max-width: 100%;
    }
}



@media (min-width: 768px) and (max-width: 991px) {
    .cart-page-cart-content .cart-page-cart-content-totals {
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: auto;
        margin-right: 0;
    }
}

.webshop-categories-container > div > div {
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 991px) {

    .footer-col1 {
        text-align: center !important;
        margin-bottom: 40px;
    }

    .menuwebshop {
        margin-left: 15px;
    }

    .header-icons .menutext {
        font-size: 17px;
    }


    .webshop-categories-container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .webshop-categories-container > div > div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .home-merken {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-categories-title + .container {
        margin-top: -20px;
    }

    #related-products h2 {
        max-width: none;
    }

    .back-to-webshop {
        padding-top: 0px;
        margin-top: 0px !important;
        padding-bottom: 20px !important;
        margin-bottom: 20px !important;
    }

    #related-products h2 {
        font-size: 24px;
    }

    #related-products .related-products > div > div {
        margin-bottom: 15px !important;
    }

    #related-products .product-list-item .product-info {
        padding-bottom: 15px !important;
    }

    #properties-description .description h2 {
        font-size: 32px;
    }

    #properties-description {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-warmte-in-de-huiskamer-row2 {
        padding-top: 40px;
    }

    .qty-add-to-cart > div {
        width: 100%;
    }

    #product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
        padding: 0px 20px;
    }

    #product-detail .qty-add-to-cart .col-5,
    #product-detail .qty-add-to-cart .col-7 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    #product-detail .qty-group .qty-field {
        width: auto;
        min-width: 34px;
        max-width: 100px;
    }

    .home-merken > div {
        padding-left: 15px;
    }

    /* #product-detail > div > div > div {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }*/

    .category-detail-main .category-filters-container {
        padding: 30px 15px;
    }

    .catprod {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .filter-mob {
        padding-top: 40px;
    }

    .category-detail-main > div > div.col-xl-9 {
        padding-top: 40px;
    }

    .checkout-page .checkout-page-form {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .cart-totals-html-desktop {
        display: none;
    }

    .cart-totals-html-mobile {

    }

    .cart-totals-html-mobile .cart-totals {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .info-header > div {
        padding-bottom: 0px !important;
    }

    .category-detail-header {
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .pl-5, .px-5 {
        padding-left: 15px !important;
    }

    .pr-5, .px-5 {
        padding-right: 15px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 15px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 15px !important;
    }


    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 500px;
    }


    .searchpage-content > div {
        padding-top: 40px;
        padding-bottom: 40px;
    }


    .over-ons-header h1, .contact-header h1, .algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .home-unieke-collectie-homewear {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-unieke-collectie-homewear-col2 > div {
        padding-top: 40px;
    }

    .privacyverklaring-main-content-table-desktop {
        display: none;
    }

    .home-usps h2, .home-categories-title h2, .webshop-categories-title h2 {
        font-size: 95.39px;
        line-height: 83.6px;
        letter-spacing: 8px;
    }

    .home-merken h2 {
        font-size: 79.76px;
        line-height: 68.91px;
        letter-spacing: 8px;
    }

    .home-usps h2 {
        margin-bottom: 0px;
    }

    .home-usps ul {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .home-categories-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ontspannen-genieten {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ontspannen-genieten-row1 {
        padding-bottom: 20px;
    }

    .home-ontspannen-genieten-row2 {
        padding-top: 20px;
    }

    .home-categories img {
        max-height: 300px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 30px;
    }


    .over-ons-rust {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-kwaliteit {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .over-ons-kwaliteit > div > div > div:nth-child(2) {
        padding-top: 40px;
    }

    .over-ons-slider-section {
        padding-bottom: 40px;
    }

    .over-ons-contact {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-contact-col2 {
        padding-top: 20px;
    }

    /*    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        z-index: 1;
        position: absolute;
        top: 66%;
    }*/

    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        left: 30px;
    }

    .over-ons-usps ul {
        padding-left: 0px;
    }

    .over-ons-usps ul li + li {
        margin-top: 15px;
    }

    .over-ons-rust-col2 {
        padding-top: 40px;
    }

    .over-ons-header img,
    .contact-header img,
    .algemene-voorwaarden-header img,
    .disclaimer-header img,
    .privacy-policy-header img {
        min-height: 300px;
        object-fit:cover;
    }


    .cart-contents .col-md-8 {
        padding-top: 40px;
    }

    .cart-page-cart-content .cart-totals {
        padding-left: 0px !important;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-content-main,
    .cart-page-cart-content .cart-content-proceed {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cart-page-cart-content .cart-content-header {border-bottom: none;}


}


@media (max-width: 1050px) {
    .home-familybusiness-col2 h3 span {
        display: inline;
        padding-left: 0px;
    }

}

.fashion-inspiration-main h1 {
    text-align: center;
    padding-bottom: 40px;
}

.fashion-inspiration-main {
    margin-bottom:100px;
}

@media (max-width: 959px) {

    .fashion-inspiration-main {
        margin-bottom:40px;
    }

    .bottom-usps, .fashion-inspiration-main {
        padding-top: 40px;
    }


    .home-aanbod > div {
        padding-top: 40px;
    }

    .home-fashion-slider-desktop {
        padding-top: 20px;
        /*max-width: 93.42vw;*/
    }

    .home-snelle-levering-col2 {
        padding-bottom: 40px;
    }

    .home-inspiration {
        padding-bottom: 20px;
    }

    .home-familybusiness-col2 {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .home-familybusiness > div > div > div > div > p {
        padding-top: 0px;
        margin-top: 0px;
    }

        .home-offer-slider-desktop-inner ul {
        margin-left: -20px;
    }

    .home-offer-slider-desktop-inner ul li {
        padding-left: 20px;
    }

    .home-inspiration-slider-desktop-inner ul {
        margin-left: -20px !important;
    }

    .home-inspiration-slider-desktop-inner ul li {
        padding-left: 20px;
    }

    .home-inspirerende-merken-col2 {
        padding-top: 40px;
    }

    .home-snelle-levering-col1 > div {
        padding-top: 40px;
    }

    .home-snelle-levering-col2 {
        padding-top: 20px;
    }

    .home-header .header-col2 {
        padding-top: 40px;
    }

    .page-home .home-header > div {
        padding-bottom: 0px;
    }

    .home-aanbod-titel h2 {
        padding-bottom: 0px;
        margin-bottom:15px;
    }

    .home-inspirerende-merken > div.container {
        position: relative;
    }

    .home-inspirerende-merken > div.container::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        background: url('/storage/media/images/dsc07670.webp') no-repeat 50% 50%;
        width: 100%;
        max-width: 98px;
        height: 131px;
        background-size: cover;
    }

/*    .home-fashion-slider-desktop {
        max-width: 100vw;
    }*/

    .no-products-in-cart {
        margin-top: 0.5rem !important;
    }

    .no-products-in-cart .py-5 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

   /* .page-home header .header-logo {
        display: none;
    }*/

    .shoppingcartnav .cartnavsteps > div {
        padding-left: 0px;
        padding-right: 0px;
    }

    header > div.py-4 {
        padding-top: 15px !important;
        padding-bottom: 0px !important;
    }

    header .mini-cart {
        position: relative;
       /* top: -25px;
        left: -30px;*/
        top: 0px;
        left: -15px;
    }

    .mobile-menu-block-open-bottom .mini-cart {
        position: relative;
        /* top: -25px;
         left: -30px;*/

    }

    header .search-field {
        position: relative;
        top: -25px;
        left: -30px;
    }

   .mobile-menu-block-open-bottom .search-field {
        position: relative;
        top: 0px;
        left: 0px;
    }

    .over-ons-slider-section .uk-slidenav {
        background: #FFF;
    }

}



@media (min-width: 640px) and (max-width: 991px) {

    /*    .home-usps ul li {
        padding-top: 20px;
    }*/

    .home-usps-col1, .home-usps-col2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}



@media (max-width: 767px) {

    .whatsappad {
        display: none;
    }

    .home-aanbod > div {
        padding-top: 0px;
    }

 /*   .home-snelle-levering-col1 > div > p {
        text-align:center;
    }

    .home-familybusiness > div > div > div > div > p {
        text-align:center;
    }*/

    .home-header-img > div:nth-child(2) {
        position: relative !important;
    }

    .home-header-button-webshop {
        max-width: none !important;
        text-align: center;
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .header-general-top {
        padding-bottom: 0px;
    }



    .home-deegkneders-row2 * {
        text-align: left;
    }


    .header-general-top + .category-detail {
        padding-top: 40px;
    }

    .header-general-top-categories {
        padding-top: 40px;
    }


    .home-our-ovens > div > div > div:nth-child(2) {padding-top: 40px;}

    .home-deegkneders > div > div:nth-child(2) > div:nth-child(2) {
        padding-left: 15px;
        padding-top: 40px;
    }

    .home-levensduur-col1 {
        order: 2;-webkit-order: 2;
        padding-top: 40px;
    }

    .home-levensduur-col2 {
        order: 1;-webkit-order: 1;
    }

    .home-podium-op-maat > div > div > div:nth-child(2) {
        padding-top: 40px;
    }


    /*    header > div > div > div {
        text-align: center;
    }

*/
    .home-main > div > div > div:nth-child(2) {
        margin-top: 40px;
    }

    .home-bottom > div > div > div:nth-child(2) {
        margin-top: 40px;
    }

    section#related-products {
        padding-top: 0px !important;
    }

    .home-unieke-collectie-homewear-col2 > div,
    .home-warmte-in-de-huiskamer-row1-col1 > div,
    .home-ontspannen-genieten-row1-col1 > div,
    .home-ontspannen-genieten-row2-col2 > div,
    .home-warmte-in-de-huiskamer-row1-col1 > div,
    .home-ontspannen-genieten-row1-col1 > div,
    .home-warmte-in-de-huiskamer-row2-col2 > div,
    .home-ontspannen-genieten-row2-col2 > div,
    .home-verpakken-col1 > div,
    .over-ons-rust h2,
    .over-ons-rust p,
    .over-ons-kwaliteit h2,
    .over-ons-kwaliteit p {
        max-width: none;
    }

    .home-unieke-collectie-homewear-col2 > div {
        padding-left: 0px;
        paddding-right: 0px;
    }

    .over-ons-contact p {
        max-width: none;
    }

    .order-btn, .proceed-button a {
        padding: 10px 20px;
    }

    #product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
        padding: 0px 20px;
    }

    .home-merken h2 {
        font-size: 69.76px;
        line-height: 58.91px;
        letter-spacing: 4px;
    }

    .home-usps h2, .home-categories-title h2, .webshop-categories-title h2 {
        font-size: 65.39px;
        line-height: 53.6px;
        letter-spacing: 4px;
    }

    .contact-main > div > div > div:nth-child(2) {
        padding-top: 40px;
    }



    h2 {
        font-size: 32px;
        letter-spacing: 0px;
    }

    h3 {
        letter-spacing: 0px;
    }


    .algemene-voorwaarden-main h2, .disclaimer-main h2, .privacy-policy-main h2 {font-size: 24px;}
    .algemene-voorwaarden-main h3, .disclaimer-main h3, .privacy-policy-main h3 {font-size: 20px;letter-spacing: 0px;}

    .algemene-voorwaarden-main, .disclaimer-main, .privacy-policy-main, .contact-main {
        padding: 40px 10px;
        box-sizing: border-box;
    }

    #product-detail > div > div {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .cart-content-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .header-logo {
        position: relative;
        top: -28px;
        max-width: 220px;
    }

    .header-logo img {
        max-width: 220px;
    }

    .page-home  .header-logo {max-width: 80vw;}


    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 300px;
    }

    .over-ons-header h1, .contact-header h1, .algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1 {
        font-size: 24px;
        line-height: 44px;
    }

    .home-categories .txt {
        padding: 10px;
        font-size: 24px;
    }


    .footer-col1, .footer-col2 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .footer-col3 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .footer-col1, .footer-col2, .footer-col3, .footer-col4 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        -webkit-justify-content: center;
        text-align: center !important;
    }

    .home-unieke-collectie-homewear img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 662px;
        max-width: 557px;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row1-col2 img {
        position: relative;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 674px;
        max-width: 577px;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row2-col1 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 577px;
        max-width: 465px;
        object-fit: cover;
    }

    .home-verpakken-col2 img {
        position: relative;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 674px;
        max-width: 577px;
        object-fit: cover;
    }

    .over-ons-rust-col2 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        max-height: 662px;
        max-width: 557px;
        object-fit: cover;
    }

    .over-ons-contact-col2 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        max-height: 674px;
        max-width: 100%;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row2 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    .home-ontspannen-genieten-row2 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    .home-warmte-in-de-huiskamer-row2 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    .home-warmte-in-de-huiskamer-row1-col1 > div, .home-ontspannen-genieten-row1-col1 > div {
        padding: 0px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 30px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 20px;
    }

    .home-warmte-in-de-huiskamer {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-warmte-in-de-huiskamer-row1-col2 {
        text-align: center;
        padding-top: 40px;
    }

    .home-ontspannen-genieten-row1-col2 {
        padding-top: 20px;
    }

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(2) {
        padding-top: 40px;
    }

    .home-warmte-in-de-huiskamer-row2-col2 > div, .home-ontspannen-genieten-row2-col2 > div {
        padding: 0px;
    }

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(1) {
        padding-top: 20px;
    }

    .home-verpakken {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-verpakken-col1 > div {
        padding: 0px;
    }

    .home-verpakken-col2 {
        padding-top: 20px;
    }

    .cart-contents .col-md-8 {
        padding-top: 40px;
    }

    .cart-page-cart-content .cart-totals {
        padding-left: 0px !important;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-content-main,
    .cart-page-cart-content .cart-content-proceed {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cart-page-cart-content .cart-content-header {border-bottom: none;}

    /*.home-categories .col-lg-8 .row + .row {
        margin-top: 0px;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        margin-bottom: 20px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 0px;
    }*/

    .home-unieke-collectie-homewear img,
    .home-ontspannen-genieten-row1-col2 img,
    .home-ontspannen-genieten-row2-col1 img,
    .home-warmte-in-de-huiskamer-row1 img,
    .home-warmte-in-de-huiskamer-row2 img,
    .home-verpakken-col2 img,
    .over-ons-rust-col2 img,
    .over-ons-kwaliteit img {
        max-width: none;
    }


}



@media (max-width: 699px) {
    .form-steps li {
        margin-right: 0px;
        display: block;
    }

    .form-steps li + svg, .form-steps li + i {
        display: none;
    }
}


@media (max-width: 639px) {


    .info-header .header-logo {
        max-width: 120px;
    }

    .info-header > div > div > div:nth-child(1) {
        padding-right: 0px;
    }

    .info-header > div > div > div:nth-child(2) {
        padding-left: 0px;
    }

    .header-icons .search-field {
        margin-left: 15px;
        margin-right: 15px;
    }


    .home-usps ul li {
        /*    padding-top: 15px;
    padding-bottom: 15px;*/
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 20px;
        /*display: block;*/
    }

    .home-header-logo > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }


}


@media (max-width: 600px) {
    .checkout-page .butn-next {
        width: 100%;margin-bottom: 30px;
    }
    .checkout-page .butn-light {
        width: 100%;
    }

    .catprod {
        margin-bottom: 15px !important;
    }

    .category-detail-main .product-info {
        padding-bottom: 15px !important;
    }

}



.header-desktop > div {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    max-width: calc(1366px + 6rem);
    margin-left: auto;
    margin-right: auto;
}


@media (min-width: 481px) and (max-width: 991px) {
    .header-desktop .col-6 {
        flex: 0 0 45%;
        max-width: 45%;
    }
    .header-desktop .col-5 {
        flex: 0 0 46.6666666667%;
        max-width: 46.6666666667%;
    }
}

@media (max-width: 480px) {

    .header-home .header-icons .menutext {
        display: block;
    }

    .home-categories .col-lg-8 .row + .row {
        margin-top: 0px;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        margin-bottom: 20px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 20px;
    }

    .home-categories-title + .container > .row > div > .row {
        margin-top: 0px !important;
    }

    .home-categories-section > div > div > div > section > div:nth-child(2) > div > div.col-lg-8 > div:nth-child(2) {
        margin-top: 0px !important;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(1) {
        order: 2;-webkit-order: 2;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        order: 1;-webkit-order: 1;
    }

    .alert .btn {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 30px !important;
        display: block;
    }

    #product-detail .product-usps > div {
        padding-left: 0px;
        padding-right: 0px;
    }

    #product-detail .product-usps ul li {
        font-size: 15px;
    }

    .qty-add-to-cart > div {
        max-width: none;
        width: 100%;
    }

    #product-detail .qty-add-to-cart .col-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #product-detail .qty-add-to-cart .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
    }


    /*  .header-logo {
        position: relative;
        top: -45px;
        max-width: 180px;
        left: -15px;
    }*/

    .header-home .header-logo {
        position: relative;
        top: -50px;
        max-width: 177px;
        left: -45px;
    }

    .header-home > div.py-4 {
        padding-top: 40px !important;
        padding-bottom: 60px !important;
    }

    .header-home #offcanvas-menu .header-logo {
        position: relative;
        top: 0px;
        max-width: 177px;
        left: 0px;
    }


    .info-header #offcanvas-menu .header-logo {
        position: relative;
        top: 0px;
        max-width: 177px;
        left: 0px;
    }

    .footer-col1, .footer-col2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .footer-col2 {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        margin-top: 2.5rem !important;
    }

    .home-categories-section .home-categories .col-lg-8 .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .home-categories-section .home-categories .brown-bg {
        margin-bottom: 20px;
    }

    .home-categories-section .home-categories .darkgrey-bg {
        margin-top: 20px;
    }

    .searchpage-content .search-field-input,
    .searchpage-content .do-search-button {
        display: block;
        width: 100%;
    }

    .searchpage-content .search-field-input input {
        min-width: auto;
        width: 100%;
    }


}




.home-categories-title + .container .col-lg-4 > div {
    height: 100%;
}


.home-categories-title + .container > .row {
    margin-left: -10px;
    margin-right: -10px;
}

.home-categories-title + .container > .row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.home-categories-title + .container > .row > div > .row {
    margin-left: -10px;
    margin-right: -10px;
}

.home-categories-title + .container > .row > div > .row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.home-categories .col-lg-8 .row + .row {
    margin-top: 20px !important;
}

.home-categories .col-lg-4 a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    /*background: $darkgrey-v1;*/
    color: $darkgrey-v1;
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    font-size: 36px;
    line-height: 36.65px;
    text-transform: uppercase;
    background: transparent;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    min-height: 250px;
    /*padding-top: 20px;
    padding-bottom: 20px;*/
    box-sizing: border-box;
}

.home-categories .col-lg-4 a:hover,
.home-categories .col-lg-4 a:focus {
    text-decoration: none;
    background: $brown-v1;
}

.home-categories .col-lg-4 a .txt-overlay {
    display: none;
}

.home-categories .col-lg-4 a:hover .txt-overlay,
.home-categories .col-lg-4 a:focus .txt-overlay {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.home-categories .col-lg-4 a img {
    z-index: 0;
    object-position: 60% 50%;
}

.home-categories .col-lg-4 a:hover img,
.home-categories .col-lg-4 a:focus img {
    z-index: -1;
}

.home-categories .txt {
    max-width: 250px;
    text-align: center;
}

.home-categories .col-lg-4 .txt-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.home-categories .col-lg-4 .txt-overlay .shop-btn {
    font-family: $font1;
    font-size: 17px;
    color: $darkgrey-v1;
    border: 1px solid $darkgrey-v1;
    display: inline-block;
    padding: 0 24px;
    margin-top: 20px;
}

.home-categories .col-lg-4 .txt-overlay .shop-btn:hover,
.home-categories .col-lg-4 .txt-overlay .shop-btn:focus {
    background-color: $darkgrey-v1;
    color: $white;
}

.home-categories img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-categories .col-lg-8 .row + .row {margin-top: 30px;}

.home-ontspannen-genieten {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 100px;
}

.home-ontspannen-genieten-row1 {padding-bottom: 170px;}
.home-ontspannen-genieten-row2 {padding-top: 170px;}

.home-ontspannen-genieten-row1-col2 img {
    position: absolute;
    top: 33.33%;
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.home-ontspannen-genieten-row2-col1 img {
    position: absolute;
    top: 67.666%;
    /*left: 0%;*/
    left: 15px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 577px;
    max-width: 465px;
    object-fit: cover;
}

.home-warmte-in-de-huiskamer {
    padding-top: 105px;
    padding-bottom: 140px;
}

.home-warmte-in-de-huiskamer-row1-col2 {
    text-align: right;
}

.home-warmte-in-de-huiskamer-row1 a {
    display: inline-block;
    background-color: $brown-v1;
    color: $white;
    font-family: $font1;
    font-size: 17px;
    padding: 0 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-warmte-in-de-huiskamer-row1 a:hover,
.home-warmte-in-de-huiskamer-row1 a:focus {
    background-color: $brown-v2;
    text-decoration: none;
}

.home-warmte-in-de-huiskamer-row1 img {
    max-height: 396px;
    max-width: 409px;
    object-fit: cover;
}

.home-warmte-in-de-huiskamer-row2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-warmte-in-de-huiskamer-row2 img {
    max-height: 490px;
    max-width: 467px;
    object-fit: cover;
    object-position: 90% 50%;
}

.home-warmte-in-de-huiskamer p {
    color: $darkgrey-v2;
}

.home-warmte-in-de-huiskamer-row1-col1, .home-ontspannen-genieten-row1-col1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.home-warmte-in-de-huiskamer-row1-col1 > div, .home-ontspannen-genieten-row1-col1 > div{
    max-width: 460px;
    padding: 20px;
    box-sizing: content-box;
}


.home-warmte-in-de-huiskamer-row2-col2, .home-ontspannen-genieten-row2-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-warmte-in-de-huiskamer-row2-col2 > div, .home-ontspannen-genieten-row2-col2 > div {
    max-width: 460px;
    padding: 20px;
    box-sizing: content-box;
}

.home-ontspannen-genieten-row2-col2 > div {
    max-width: 500px;
    padding: 20px;
    box-sizing: content-box;
}

.home-verpakken {
    /*background: url('/images/footer-bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 130px;
    padding-bottom: 110px;
}


.home-verpakken-col1 > div {
    max-width: 480px;
    text-align: justify;
    padding: 20px;
    box-sizing: content-box;
}

.home-verpakken-col1 p {margin-bottom: 0px;}

.home-verpakken-col2 > div {
    position: relative;
    z-index: 1;
    height: 100%;
}

.home-verpakken-col2 img {
    position: absolute;
    top: calc(50% - 10px);
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.home-merken h2 {
    background: -webkit-linear-gradient(#f0e9e3, #fff 75%);
    background: linear-gradient(#f0e9e3, #fff 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font2;
    font-size: 179.76px;
    line-height: 168.91px;
    letter-spacing: 20px;
    margin-left: -10px;
}

.home-merken {
    padding-top: 50px;
    padding-bottom: 100px;
}

.home-merken > div {
    padding-left: 0px;
}

.home-brands > .container {
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.home-brands .row .row + .row {
    margin-top: 90px;
}

.home-brands a {
    pointer-events: none;
    cursor: default;
}

.no-products-added-to-cart .btn {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: "GothamNarrow-Light", sans-serif;
    font-size: 17px;
    padding: 14px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.no-products-added-to-cart .btn:hover,
.no-products-added-to-cart .btn:focus {
    background-color: $brown-v2;
    text-decoration: none;
}



.category-detail .product-list-item {
    text-align: center;
    border: none;
}

.category-detail .product-list-item:hover {
    background-color: transparent;
}

.category-detail .product-list-item:before {
    display: none;
}

.category-detail .product-list-item .product-info:before {
    display: none;
}

.category-detail .product-title {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
}

.category-detail .product-list-item .product-info .product-price {
    font-family: $font2;
    color: $darkgrey-v1;
    font-size: 20px;
}


.categories-filter-title h4 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 36.65px;
    color: $brown-v1;
    /*border-bottom: 1px solid rgba(18, 14, 14, 0.2);*/
    text-transform: uppercase;
}



#product-detail {}

#product-detail > div > div {
    padding-top: 20px !important;
}

#product-detail .slick-slider .slick-track {
    margin-top: -5px;
}

#product-detail .main-image {
    width: 100%;
    height: 100%;
    /*max-height: 460px;*/
    max-height: 400px;
    height: 400px;
    text-align: center;
    border: 0px solid #C4C4C4;
    max-width: 559px;
    margin-left: 0px;
    margin-right: auto;
    border-radius: 10px;
}

#product-detail .image-slide {
    max-width: 529px;
    margin-left: 0px;
    margin-right: auto;
}

#product-detail .main-image img {
    width: 100%;
    height: 100%;
    max-height: 400px;
    /*object-fit: cover;*/
    object-fit: cover;
    border-radius: 10px;
}

#product-detail .slick-slide {
    margin: 10px !important;
}

#product-detail .product-title {
    font-family: $font2;
    font-size: 26px;
    color: #efefef;
    text-align: left;
    letter-spacing: -1px;
    text-transform: uppercase;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
}

#product-detail p.category-name {
    margin-bottom: 5px !important;
}

#product-detail .brand-name {
    font-family: $font2;
    color: $brown-v2;
    font-size: 25px;
}


#product-detail .spec-name {
    display: inline-block;
    min-width: 180px;
}

#product-detail  input[type=checkbox]:checked + label:before {
    color: #bf873c;
}


#product-detail .vat-label {
    font-size: 20px;
    color: #efefef;
}

/*

#product-detail input[type=checkbox] + label:before {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #bf873c;
    border-radius: 50%;
}
*/

#product-detail input[type=checkbox]:checked + label:before {
    /*content: "\f058";*/
    content: "\F14A";
}

#product-detail .product-detail-price {
    /*font-family: $font2;
    color: $darkgrey-v1;
    font-size: 20px;*/
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 39px;
    /*color: #2B2B2B;*/
    color: #bf873c;
    margin-top: 10px;
}


#product-detail .qty-group {
    border-radius: 10px;
}

#product-detail .qty-group input {
    color: #FFF;
}

#product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
    background: #bf873c;
}


#product-detail .order-btn:hover, #product-detail .proceed-button a:hover, .proceed-button #product-detail a:hover,
#product-detail .order-btn:focus, #product-detail .proceed-button a:focus, .proceed-button #product-detail a:focus {
    background: #202020 !important;
    color: #fff !important;
}

.header-general-top .header-general-top-categories-product {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.header-general-top .header-general-top-categories-product a {
    color: #FFF;
    display: inline-block;
    margin-right: 5px;
    font-weight: 300;
}

.header-general-top .header-general-top-categories-product a:hover,
.header-general-top .header-general-top-categories-product a:focus {
    /*text-decoration: none;*/
    text-underline-position: under;
}

.header-general-top .header-general-top-categories-product strong {
    margin-left: 5px;
    font-weight: 700;
}

#product-detail .description-top {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
    line-height: 36px;
}

#product-detail .description-top p {
    margin-bottom: 0px;
}

#product-detail .description-top-link {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 1px solid rgba(39,33,30,0.251);
    display: inline-block;
}

#product-detail .description-top-link:hover,
#product-detail .description-top-link:focus {
    border-bottom: 1px solid rgba(39,33,30,1);
    text-decoration: none;
}

#product-detail .variants .variant:hover .image-container,
#product-detail .variants .variant.active-variant .image-container {
    border: 2px solid rgba(39,33,30,0.251);
}

#product-detail .variants .variant {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    padding-top: 0.25rem !important;
}


#product-detail .variants .image-container img {
    width: 100%;
    height: 100%;
    max-width: 96px;
    max-height: 96px;
}

#product-detail .qty-group input {
    border: none !important;
}

#product-detail .qty-group {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    height: 45px;
    min-width: 117px;
}

.qty-add-to-cart-max {
    max-width: 370px;
}

.qty-group .qty-up {
    position: absolute;
    bottom: 4px;
    right: 7px;
}

#toebehoren {
    background: url('/images/ambachtelijk-bakplezier-bg.png') no-repeat 50% 50%;
    background-color: #000;
    background-size: cover;
    text-align: center;
}

#toebehoren {
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;
    z-index: 1;
}


.product-contact-section {
    padding-top: 110px;
}

.home-contact-section > div.container {
    position: relative;
    z-index: 0;
}

.home-contact-section > div.container::before {
    content: "";
    background: url('/images/contact-after-bg.png') no-repeat 100% 50%;
    position: absolute;
    z-index: -1;
    /* top: -50%; */
    top: 0%;
    right: 0%;
    width: 100%;
    height: 100%;
    -webkit-animation: productcontactflower 30s linear infinite;
    -moz-animation: productcontactflower 30s linear infinite;
    -o-animation: productcontactflower 30s linear infinite;
    animation: productcontactflower 30s linear infinite;
}



@-webkit-keyframes productcontactflower {
    0%, 100% { -webkit-transform: translateY(-50px); }
    50% { -webkit-transform:  translateY(200px) ; }
}

@-moz-keyframes productcontactflower {
    0%, 100% { -moz-transform: translateY(-50px); }
    50% { -moz-transform:  translateY(200px) ; }
}

@-o-keyframes productcontactflower {
    0%, 100% { -o-transform: translateY(-50px); }
    50% { -o-transform:  translateY(200px) ; }
}

@keyframes productcontactflower {
    0%, 100% { transform: translateY(-50px); }
    50% { transform:  translateY(200px) ; }
}

    /*#product-detail .qty-group .qty-down {
        position: relative;
        bottom: 0px;
        left: 0px;
        font-family: $font1;
        color: $darkgrey-v1;
        font-size: 14px;
    }

    #product-detail .qty-group .qty-up {
        position: relative;
        bottom: 0px;
        right: 0px;
        font-family: $font1;
        color: $darkgrey-v1;
        font-size: 14px;
    }*/

/*
#product-detail .qty-group .qty-field {
    border: 1px solid rgb(214, 214, 214) !important;
    width: 120px;
    height: 34px !important;
    border-radius: 0px !important;
    margin-left: 10px;
    margin-right: 10px;
}
*/

#product-detail .order-btn {
    /* background: $brown-v1;
    color: $white;
    font-family: $font1;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0 35px;*/
/*    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;*/
   /* background: black;
    border: 1px solid black;
    color: white;
    font-family: aviano-serif, serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0px 35px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    height: 45px;
    line-height: 45px;
    box-sizing: border-box;*/
    font-family: aviano-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    /*line-height: 16px;*/
    line-height: 16px;
    padding: 15px 55px;
    color: #FFF;
    display: inline-block;
    margin-top: 20px;
    position: relative;
    z-index: 0;
}

.related-prodcuts .order-btn {
    line-height: 45px !important;
}

#product-detail .qty-add-to-cart-max .order-btn {
    /*padding: 15px 45px;*/
    padding: 15px 30px;
    color: #FFF;
    display: inline-block;
    margin-top: 0px;
}

#product-detail .order-btn:hover,
#product-detail .order-btn:focus {
    /*background: $brown-v2;*/
    /*background: #2B2B2B;*/
   /* background: white !important;
    border: 1px solid black;
    color: black !important;*/
    color: #FFF;
    text-decoration: none;
}



#product-detail .order-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000;
    transition: all 0.3s;
}

#product-detail .order-btn:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
}

#product-detail .order-btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: #AF8A4E;
    border: 1px solid #AF8A4E;
    transform: scale(1.2, 1.2);
}

#product-detail .order-btn:hover::after {
    opacity: 1;
    transform: scale(1, 1);
}

#product-detail  .qty-add-to-cart > div {
    margin-left: -15px;
    margin-right: -15px;
}


#product-detail .qty-add-to-cart .col-5 {
    padding-right: 0px;
}

#product-detail .qty-add-to-cart p {
    margin-top: 0px;
    margin-bottom: 0px;
    /*font-family: aviano-serif, serif;*/
    font-family: $main-font2;
    font-style: normal;
    font-weight: 300;
    /*font-size: 12px;*/
    font-size: 14px;
    color: white;
}

#product-detail .order-btn p {
    transition: 0.25s ease-in-out;
}

#product-detail .order-btn:hover p {
    color: black;
}

#product-detail .qty-add-to-cart-max .order-btn:hover p,
#product-detail .qty-add-to-cart-max .order-btn:focus p {
    color: #FFF;
}

#product-detail .productconfiguration-name {
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #1E1E20;
    margin-top: 20px;
    display: inline-block;
}

.product_configurations select {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
}

#product-detail .order-btn img {
    margin-right: 10px;
    position: relative;
    top: -1px;
}

#product-detail .order-btn:hover img,
#product-detail .order-btn:focus img {
    filter: invert(52%) sepia(36%) saturate(685%) hue-rotate(355deg) brightness(99%) contrast(95%);
}


.btn-outline-success {
    border-radius: 10px;
}


.qty-add-to-cart .qty-group {

}

#product-detail .variants {}

#product-detail .product-usps {

}

#product-detail .product-usps ul {
    list-style: none;
    padding-left: 0px;
}

#product-detail .product-usps li svg {
    margin-right: 15px;
    font-weight: bold;
}

#properties-description {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 80px;
}

#properties-description .properties h2 {
    font-family: $font2;
    font-size: 19.98px;
    line-height: 39.96px;
    color: $brown-v1;
    text-transform: none;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

#properties-description .properties h2 + p {
    margin-top: 0px !important;
}

#properties-description .description h2 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    font-size: 38px;
    line-height: 39.96px;
}

#properties-description .description span + h2 {
    margin-top: 5px !important;
}

.product-list-item .image-container {
    padding-bottom: inherit;
}

.product-list-item .image-container img {
    /*max-width: 240px;
    max-height: 240px;*/
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    height: 330px;
}

.related-prodcuts [class*=uk-position-center-left], .related-prodcuts [class*=uk-position-center-right] {
    top: calc(50% - 34px);
}


#related-products h2 {
    max-width: 480px;
    font-weight: 400;
}

section#related-products {
    /*background: $white;*/
    padding-bottom: 0px !important;
}

#related-products .related-products {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
    background: transparent;
}

/*
#related-products .product-list-item {
    border: 1px solid $white;
}

#related-products .product-list-item {
    border: 1px solid #ececec;
}
*/

#related-products .product-list-item {
    background: #202020;
    border: 1px solid #202020;
}

#related-products .product-list-item .product-info:before {
    background-color: transparent;
}

#related-products .product-list-item:hover .product-info:before {
    background: transparent;
}

#related-products .product-list-item .image-container {
    padding-bottom: inherit;
    background-color: transparent;
}

#related-products .product-list-item:hover .image-container {
    background: transparent;
}

#related-products .product-list-item .image-container img {
    max-width: 240px;
    max-height: 240px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    background: #202020;
}

#related-products .product-list-item:before {
    display: none !important;
}

#related-products .product-list-item .product-info {
    text-align: center;
}

.category-detail-main .product-info {
    text-align: center;
}

.category-detail-main .product-info  .product-price {
    font-family: $font2;
    font-size: 20px;
    color: $darkgrey-v1;
}

#related-products .product-list-item .product-info .product-title {
    font-family: $font1;
    color: #fff;
    max-width: 200px;
    margin-left: 0px;
    margin-right: auto;

    font-family: roboto-condensed, sans-serif;
    font-size: 21px;
    line-height: 1.2;
    /*color: #efefef;*/
    text-align: center;
    letter-spacing: -1px;
    text-transform: uppercase;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    text-align: left;

}

#related-products .product-list-item .product-info .product-price {
    color: #FFF;
    text-align: left;
}

.back-to-webshop {
    padding-top: 20px;
    /*margin-top: 0px !important;*/
    margin-top: 3rem !important;
    padding-bottom: 20px !important;
}

.back-to-webshop a {
    background: #bf873c;
    color: #fff;
    font-family: $font1;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0 43px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.back-to-webshop a:hover,
.back-to-webshop a:focus {
    background: #FFF;
    color: #bf873c;
    text-decoration: none;
}


.privacyverklaring-main-content-table-desktop {
    overflow-x: auto;
}

.privacyverklaring-main-content-table-desktop table {
    border-collapse: collapse;
}

.privacyverklaring-main-content-table-desktop table tr {
    border-bottom: 1px solid #ccc;
}

.privacyverklaring-main-content-table-desktop table tr th,
.privacyverklaring-main-content-table-desktop table tr td {
    text-align: left;
    padding: 5px 15px 5px 5px;
}

.pager {
    margin-bottom: 40px;
}

.pager a {
    font-family: $main-font;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    /*color: #2B2B2B;*/
    color: $black;
}


.pager a:hover,
.pager a:focus {
    color: $brown;
    text-decoration: none;
}

.pager .my-active span {
    font-family: $main-font3;
    color: #bf873c;
    font-size: 20px;
    font-weight: 400;
}

.pager .prev-btn a, .pager .next-btn a {
    background: $brown;
    color: #FFF;
    /*padding: 8px 15px;*/
    padding: 0px;
    box-sizing: border-box;
    font-family: $main-font3;
}

.pager .prev-btn-div {
    margin-left: auto;
    padding: 0;
}

.pager .text-end {
    padding: 0;
    text-align: right;
}

.pager .next-btn {
    margin-left: auto;
    padding: 0;
}

.pager .prev-btn a:hover, .pager .next-btn a:hover,
.pager .prev-btn a:focus, .pager .next-btn a:focus {
    background: #fff;
    color: $brown;
}

.pager .disabled {
    display: none;
}

/*.category-detail-header,*/ .cartnav {
                                 /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
                               /*  background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
                                 background-size: cover;
                                 padding-top: 40px;
                                 padding-bottom: 40px;*/
                             }

.checkout-title-container {
    position: relative;
    z-index: 0;
    background: #74C6C7;
}

.checkout-top-usps {
    background: #2B2B2B;
}




.category-detail-header h1, .product-detail-header h1, .checkout-title-container h1 {
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
    text-transform: none;
}

.product-detail-header h1 {
    display: block;
}

.category-detail-header a,
.category-detail-header a + svg,
.category-detail-header i,
.product-detail-header a,
.product-detail-header a + svg,
.product-detail-header i {
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}

.category-detail-header *,
.product-detail-header * {
    color: #FFFFFF;
}

.category-detail-header i,
.product-detail-header i {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
}



.category-detail-header > div:nth-child(1) {
    position: relative;
    z-index: 0;
    background: #74C6C7;
}

.category-detail-header > div:nth-child(1),
.product-detail-header > div:nth-child(1) {
    position: relative;
    z-index: 0;
}

.category-detail-header > div:nth-child(1)::before,
.product-detail-header > div:nth-child(1)::before {
    content: "";
    background: url('/images/Frame7.png') no-repeat;
    position: absolute;
    top: calc(50% + 20px);
    left: 100%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    z-index: -1;
    width: 100%;
    height: 100%;
    max-width: 610px;
    max-height: 128px;
}

.category-detail-header > div:nth-child(1) > div:nth-child(1),
.product-detail-header > div:nth-child(1) > div:nth-child(1){
    padding-top: 40px;
    padding-bottom: 20px;
}

.category-detail-header .categorie-top-usps, .product-top-usps {
    background: #2B2B2B;
}

.category-detail-header .categorie-top-usps * {

}

.category-detail-main .catprod .image-container {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
}

.category-detail-main .catprod .product-info {
    background: #2B2B2B;
    margin-left: 0px;
    margin-right: 0px;
    padding: 15px 15px !important;
    height: 113px;
}

.category-detail-main .catprod .product-info .product-title {
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.category-detail-main .catprod .product-info .product-price {
    /*font-family: Effra;*/
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
    /*font-family: $main-font1 !important;*/
    font-family: 'Calibri', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
}

.category-detail-main .catprod .product-info .col-10 {
    padding-left: 0px !important;
}

/*.category-detail-main .catprod .product-info .col-2 img {
    width: 30px;
    height: 30px;
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
}
*/
.cartnav ul {
    margin-bottom: 0px;
}

.product-detail-header {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background-size: cover;
    padding-top: 0px;
    padding-bottom: 0px;
    background: #74C6C7;
}

.category-detail-main .category-filter-item + .category-filter-item {
    position: relative;
    z-index: 0;
}

.category-detail-main .category-filter-item + .category-filter-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    z-index: -1;
    width: calc(100% - 30px);
    border-top: 1px solid $brown;
}

.category-detail-main .category-filters-container {
    /*background: #f6f0ea;*/
    padding: 60px 50px 60px 0px;
    box-sizing: border-box;
}

.category-detail-main .category-filters-container h4 {
    /*border-bottom: 1px solid rgba(18, 14, 14, 0.2);*/
    padding-bottom: 10px;
}

.category-detail-main .category-filters-container h4,
.category-detail-main .category-filters-container h4 * {
    /*font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 18px;
    text-transform: uppercase;*/
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 200%;
    color: #1E1E20;
    text-transform: none;
}

.category-detail-main .category-filters-container h4 > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-main .category-filters-container h4 .fal {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    color: #272727;
    font-size: 18px;
}

.filter-mob .fal {
    font-size: 18px;
}

.filter-mob h4 {
    font-family: "elegante_classica", sans-serif;
    color: #a26c35;
    font-size: 18px;
    text-transform: uppercase;
}

.filter-mob h4 .fal {
    color: #272727;
}

.filter-mob h4 div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-header a, .product-detail-header a {
    font-family: $font1;
    /*color: $brown-v2;*/
    color: #FFF;
}

.category-detail-header a:hover,
.category-detail-header a:focus,
.product-detail-header a:hover,
.product-detail-header a:focus {
    /*color: $brown-v1;**/
    color: #2b2b2b;
    text-decoration: none;
}

.product-detail-header .align-items-center > div {
    position: relative;
    z-index: 10;
}

.category-detail-main > div > div.col-xl-9 {
    padding-top: 60px;
}

.category-detail-main .category-filters-container .category-filter-item > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.category-detail-main .category-filters-container .category-filter-item a {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
}

.category-detail-main .category-filters-container .category-filter-item ul {
    list-style: none;
    padding-left: 0px;
}

/*.category-detail-main .category-filters-container .category-filter-item .sub-cat::marker {
        display: none;
    }*/

.category-detail-main .category-filters-container .category-filter-item .sub-cat a {
    display: inline-block;
}


#description-long-link {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
}

#brand-filter {
    color: $darkgrey-v1;
    font-family: $font1;
    font-size: 18px;
}

.header-home-icons {
    position: relative;
    z-index: 999;
}


.catprod {
    position: relative;
}

.catprod .lebel {
    position: absolute;
    top: 0px;
    /*left: 12px;*/
    left: 0px;
    background-color: $brown;
    color: #FFF;
    box-sizing: border-box;
    padding: 5px 10px;
    font-family: $font2;
}

.category-detail-main .catprod .product-list-item:hover .lebel,
.category-detail-main .catprod .product-list-item:focus .lebel {
    background: #202020;
}

.over-ons-header > div > div > div,
.contact-header > div > div > div,
.algemene-voorwaarden-header > div > div > div,
.disclaimer-header > div > div > div,
.privacy-policy-header > div > div > div {
    position: relative;
    z-index: 0;
}

/*.over-ons-header > div > div > div > div:nth-child(1),
.contact-header > div > div > div > div:nth-child(1),
.algemene-voorwaarden-header > div > div > div > div:nth-child(1),
.disclaimer-header > div > div > div > div:nth-child(1),
.privacy-policy-header > div > div > div > div:nth-child(1) {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 100px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}*/

.over-ons-header h1,
.contact-header h1,
.algemene-voorwaarden-header h1,
.disclaimer-header h1,
.privacy-policy-header h1 {
    font-family: Effra;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
    max-width: 800px;
    text-transform: none;
    margin-bottom: 0px;
}

.over-ons-header > div,
.contact-header > div,
.algemene-voorwaarden-header > div,
.disclaimer-header > div,
.privacy-policy-header > div {
    padding-top: 40px;
    padding-bottom: 20px;
    max-height: 128px;
}

.over-ons-header,
.contact-header,
.algemene-voorwaarden-header,
.disclaimer-header,
.privacy-policy-header {
    position: relative;
    z-index: 0;
}

.over-ons-header::before,
.contact-header::before,
.algemene-voorwaarden-header::before,
.disclaimer-header::before,
.privacy-policy-header::before,
.checkout-title-container::before {
    content: '';
    background: url('/images/Frame7.png') no-repeat;
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    z-index:-1;
    width: 100%;
    height: 100%;
    max-width: 610px;
    max-height: 128px;
}

.over-ons-usps {
    background: #2B2B2B;
    font-family: Effra;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}


.over-ons-rust {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 180px;
    padding-bottom: 70px;
}

.over-ons-rust h2 {
    max-width: 320px;
}

.over-ons-rust p {
    max-width: 460px;
}

/*
.over-ons-rust-col2 {
    position: relative;
}

.over-ons-rust-col2 img {
    position: absolute;
    top: 66%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 662px;
    max-width: 557px;
    object-fit: cover;
}*/

.over-ons-uniek-verdeler > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-kwaliteit {
    padding-top: 200px;
    padding-bottom: 140px;
}

.over-ons-kwaliteit > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-kwaliteit > div > div > div:nth-child(2) {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

}

.over-ons-kwaliteit img {
    max-height: 490px;
    max-width: 467px;
    object-fit: cover;
}

.over-ons-kwaliteit h2 {
    max-width: 340px;
}

.over-ons-kwaliteit p {
    color: $darkgrey-v2;
    max-width: 470px;
}

.over-ons-uniek-verdeler > div {
    padding-top: 90px;
    padding-bottom: 90px;
}

.over-ons-slider-section {
    background: url('/images/about-us-slider-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 90px;
}

.over-ons-slider-section .uk-slidenav {
    color: $brown-v1;
}

.over-ons-slider-section .uk-slidenav:hover,
.over-ons-slider-section .uk-slidenav:focus {
    color: $brown-v2;
}

.over-ons-slider-section .uk-slider-items img {
    max-height: 280px;
    width: 100%;
    object-fit: cover;
}

.over-ons-contact {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background: #000;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 140px
}

.over-ons-contact p {
    max-width: 420px;
}

.over-ons-contact a {
   /* display: inline-block;
    background-color: #a26c35;
    color: #fff;
    font-family: "GothamNarrow-Light", sans-serif;
    font-size: 17px;
    padding: 7px 15px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    border-radius: 0px;*/
}

.over-ons-contact a:hover,
.over-ons-contact a:focus {
    /*background-color: #ccb59e;*/
    text-decoration: none;
}

.over-ons-contact-col2 {
    position: relative;
    z-index: 1;
}

.over-ons-contact-col2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.over-ons-contact > div > div > div:nth-child(1) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.contact-main form input:not([type=checkbox]):not([type=radio]) {
    height: 40px !important;
}

.contact-main form input:not([type=checkbox]):not([type=radio]),
.contact-main form textarea {
    font-size: 18px !important;
}

.contact-main .privacy-notice-paragraph {
    font-size: 12px;
    line-height: 18px;
}

/*.contact-main .privacy-notice-paragraph a {
    color: $brown-v2;
}*/

.contact-main .privacy-notice-paragraph a:hover,
.contact-main .privacy-notice-paragraph a:focus {
    /*color: $brown-v1;*/
    text-decoration: none;
}

.contact-main input[type=checkbox] {
    border: 1px solid $brown-v1;
}

.contact-main textarea {
    /*border: 1px solid $brown-v1 !important;*/
}

.contact-main label::before {
    display: none !important;
}

.contact-main button {
    display: inline-block;
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    font-family: "GothamNarrow-Light", sans-serif;
    font-size: 17px;
    padding: 0 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 20px;
}

.contact-main .upload-button button {
    margin-top: 0px;
}

.contact-main button:hover,
.contact-main button:focus {
    background-color: $brown;
    border: 1px solid $brown;
    text-decoration: none;
}

/*.contact-main > div > div > div:nth-child(2) a {
    color: $main-color;
}

.contact-main > div > div > div:nth-child(2) a:hover,
.contact-main > div > div > div:nth-child(2) a:focus {
    color: $black;
}*/

.contactmap {
    margin-top: 40px;
}

.contact-main #form-group-checkbox-group-1635514227957 label {
    font-size: 18px;
    line-height: 24px;
}

.site-footer {
    /*background: url('/images/footer-bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;*/
    /*background: $black;*/
    background: $white;
    padding-top: 0px;
    padding-bottom: 70px;
}

.site-footer * , .site-footer a {
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $white;
}

.site-footer a:hover, .site-footer a:focus {
    color: $brown;
    text-decoration: none;
}

.site-footer h3 {
    font-family: $main-font2;
    color: $brown;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.site-footer div,
.site-footer li,
.site-footer a {

    font-family: $main-font1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    text-transform: uppercase;
    color: $black;
}
    /*
    .footer-col2 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .footer-col3 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }*/

.footer-col4 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    text-align: right;
}

.searchpage-content > div {
    padding-top: 70px;
    padding-bottom: 70px;
}

.searchpage-content .search-field {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.searchpage-content .search-field-input,
.searchpage-content .do-search-button {
    display: inline-block;
}

.searchpage-content .search-field-input input {min-width: 300px;padding-left: 10px;padding-right: 10px;padding-top: 8px;padding-bottom: 8px;}

.searchpage-content .do-search-button {
    background: $brown-v1;
    color: $white;
    padding: 10px 20px;
    box-sizing: border-box;
    height: 52px;
    /*line-height: 36px;*/
    line-height: 30px;
    cursor: pointer;
}

.searchpage-content .do-search-button .far {
    position: relative;
    top: 2px;
    left: 2px;
}

.searchpage-content .do-search-button:hover,
.searchpage-content .do-search-button:focus {
    /*background: $brown-v2;*/
    background: #2b2b2b;
}


.uk-offcanvas-bar {
    /*background: url(/images/bg.jpg) no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background: #FFF;
    background-size: cover;
}

.uk-offcanvas-bar .menu-main-menu li {
    display: block;
    width: 100%;
}

.offcanvas-logo {
    margin-top: 100px;
    margin-bottom: 40px;
    display: inline-block;
}

.uk-offcanvas-bar .menu-main-menu {
    height: auto !important;
}

.uk-offcanvas-bar .menu-main-menu li a {
    font-family: "GothamNarrow-Bold", sans-serif;
    font-size: 22px;
    line-height: 38px;
    color: #a26c35 !important;
    text-transform: uppercase;
}

.uk-offcanvas-bar .menu-main-menu li a:hover,
.uk-offcanvas-bar .menu-main-menu li a:focus {
    color: $brown-v2 !important;
    text-decoration: none;
}

.uk-offcanvas-close {
    /*color: #FFF;*/
    color: $brown-v2;
}

.uk-offcanvas-close:hover,
.uk-offcanvas-close:focus {
    color: $brown-v1;
}

.uk-offcanvas-close svg {
    width: 26px;
    height: 26px;
}


ul.menu-main-menu > li > a:after, ul.menu-main-menu > li > a:visited:after, ul.menu-main-menu > li > span:after, ul.menu-language-items > li > a:after, ul.menu-language-items > li > a:visited:after, ul.menu-language-items > li > span:after {
    display: none;
}

.form-steps li {
    border: none;
    transform: none;
}


.form-steps {
    padding-left: 0px;
}


.form-steps li.enabled, .form-steps li.current {
    border: none;
    background: none;
}

.form-steps li svg.fa-check-circle, .form-steps li svg.fa-circle,
.form-steps li i.fa-check-circle, .form-steps li i.fa-circle {
    margin-right: 8px;
    position: relative;
    top: 5px;
    width: 27px !important;
    height: 27px;
    /*color: $brown-v2;*/
    color: #FFF;
    font-size: 27px;
}

.form-steps svg.fa-horizontal-rule, .form-steps i.fa-horizontal-rule {
    max-width: 14px;
    margin-left: 6px;
    /*margin-right: 6px;*/
    margin-right: 15px;
}

.form-steps li a, .form-steps li span {
    transform: none;
    padding: 10px 0px;
    /*color: #000;*/
    color: #000;
}

.cart-content-section {
    padding-top: 70px;
    padding-bottom: 70px;
}


.order-btn, .proceed-button a {
    /*background-color: #bf873c;*/
 /*   background: #bf873c;
    border: 1px solid #bf873c;
    color: #efefef;
    font-family: roboto-condensed, sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
!*    padding: 9px 38px;*!
    padding: 9px 20px;
    box-sizing: border-box;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;*/
    font-family: $main-font1;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    /*background-color: $black;*/
    padding: 15px 55px;
    color: $white;
    display: inline-block;
    margin-top: 0px;
    position: relative;
    z-index: 0;
}

.order-btn:hover, .proceed-button a:hover,
.order-btn:focus, .proceed-button a:focus {
    /*background-color: $brown-v2;*/
/*    background: #efefef;
    color: #bf873c;
    border: 1px solid #efefef;
    text-decoration: none;*/
    color: $white;
    text-decoration: none;
}



.proceed-button a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $black;
    transition: all 0.3s;
}

.proceed-button a:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
}
.proceed-button a::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: $brown;
    border: 1px solid $brown;
    transform: scale(1.2,1.2);
    /*color: $white;*/
}
.proceed-button a:hover::after {
    opacity: 1;
    transform: scale(1,1);
}


.checkout-page {
    .invalid-feedback {
        display:block !important;
        list-style: none;
        padding-left: 0;
    }
}

.checkout-page .butn-next, .offerte-container .butn-next {
   /* display: inline-block;
    !*background-color: $brown-v1;*!
    background-color: #bf873c;
    border: 1px solid #bf873c;
    color: #fff;
    font-family: $font2;
    font-size: 20px;
    padding: 10px 28px;
    !*text-transform: uppercase;*!
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 25px;
    border-radius: 10px;*/
    /*background: #bf873c;
    border: 1px solid #bf873c;
    color: #efefef;
    font-family: roboto-condensed, sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 9px 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;*/
    font-family: $main-font1;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    background-color: $black;
    padding: 9px 38px;
    color: $white;
    display: inline-block;
    margin-top: 0px;
    position: relative;
    z-index: 0;
    border: none !important;
}

.checkout-page .butn-next:hover,
.checkout-page .butn-next:focus,
.offerte-container .butn-next:hover,
.offerte-container .butn-next:focus {
    /*background-color: #2B2B2B;*/
  /*  background-color: #fff;
    border: 1px solid #fff;
    color: #bf873c;
    text-decoration: none;*/
/*    background: #efefef;
    color: #bf873c;
    border: 1px solid #efefef;
    text-decoration: none;*/
    color: $white;
    text-decoration: none;
}




.checkout-page .butn-next::before,
.offerte-container .butn-next::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $black;
    transition: all 0.3s;
}

.checkout-page .butn-next:hover::before,
.offerte-container .butn-next:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
}
.checkout-page .butn-next::after,
.offerte-container .butn-next::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: $brown;
    border: 1px solid $brown;
    transform: scale(1.2,1.2);
    /*color: $white;*/
}
.checkout-page .butn-next:hover::after,
.offerte-container .butn-next:hover::after {
    opacity: 1;
    transform: scale(1,1);
}



.checkout-page .butn-light {
/*    display: inline-block;
    background-color: #F3F3F3;
    border: 1px solid #F3F3F3;
    border-radius: 10px;
    !*color: #fff;
    font-family: $font1;
    font-size: 17px;
    padding: 10px 28px;*!
    font-family: Effra;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    !*line-height: 24px;*!
    !* identical to box height *!
    color: #bf873c;
    padding: 10px 28px;
    !*text-transform: uppercase;*!
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 25px;*/
    /*background: #efefef;
    border: 1px solid #efefef;
    color: #bf873c;
    font-family: roboto-condensed, sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 9px 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;*/
    font-family: $main-font1;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    background-color: $brown;
    padding: 9px 38px;
    color: $white;
    display: inline-block;
    margin-top: 0px;
    position: relative;
    z-index: 0;
    border: none !important;

}

input[type=checkbox]:checked + label:before {
    color: $brown;
}

.checkout-page .butn-light:hover,
.checkout-page .butn-light:focus {
/*    background-color: #bf873c;
    border: 1px solid #bf873c;
    color: #FFF;
    text-decoration: none;*/
   /* background: #bf873c;
    color: #efefef;
    border: 1px solid #bf873c;
    text-decoration: none;*/
    /*background-color: $brown;*/
    background-color: #000;
    color: $white;
    text-decoration: none;
}



.checkout-page .butn-light::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $black;
    transition: all 0.3s;
}

.checkout-page .butn-light:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
}
.checkout-page .butn-light::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: $brown;
    border: 1px solid $brown;
    transform: scale(1.2,1.2);
    /*color: $white;*/
}
.checkout-page .butn-light:hover::after {
    opacity: 1;
    transform: scale(1,1);
}


.login-existing-customer a {
    /*color: $brown-v1;*/
    color: $brown;
}

.login-existing-customer a:hover,
.login-existing-customer a:focus {
    /*color: $brown-v2;*/
    color: #000;
    text-decoration: none;
}

input[type=radio]:checked + label:before {
    color: $brown;
}

#login-form-modal label {
    color: #000;
}


#login-form-modal .btn {
    /*background: #bf873c;
    border: 1px solid #bf873c;
    color: #efefef;
    font-family: roboto-condensed, sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 9px 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;*/
    font-family: aviano-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    padding: 9px 38px;
    color: #FFF;
    display: inline-block;
    margin-top: 20px;
    position: relative;
    z-index: 0;
}

#login-form-modal .btn:hover,
#login-form-modal .btn:focus {
/*    background: #efefef;
    color: #bf873c;
    border: 1px solid #efefef;
    text-decoration: none;*/
    color: #FFF;
    text-decoration: none;
}


#login-form-modal .btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000;
    transition: all 0.3s;
}

#login-form-modal .btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: #AF8A4E;
    border: 1px solid #AF8A4E;
    transform: scale(1.2, 1.2);
}

#login-form-modal .btn:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
}

#login-form-modal .btn:hover::after {
    opacity: 1;
    transform: scale(1, 1);
}

.form-control, .select2-container .selection .select2-selection {
    border-radius: 0px;
}

.select2-container .selection .select2-selection {
    border-radius: 0px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    border-radius: 0px !important;
}

.cart-item-block {
    border: 1px solid #ccc;
    padding: 10px;
    /*border-radius: 6px;*/
    border-radius: 0px;
}

.cart-item-block .image-container img {
    /*border-radius: 6px;*/
    border-radius: 0px;
    width: 120px !important;
    height: 120px;
    object-fit: cover;
}

.cart-content-row + .cart-content-row {
    border-top: none;
    margin-top: 30px;
}

.filter-mob {
    padding-left: 15px;
    padding-right: 15px;
}

.filter-mob a {
    font-family: "GothamNarrow-Light", sans-serif;
    color: #27211e;
    font-size: 18px;
}

.filter-mob .category-filter-item > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.filter-mob .subcategories-list {
    list-style: none;
    padding-left: 0px;
}

.filter-mob .subcategories-list li a {
    display: inline-block;
}

.mini-cart .badge-main {
    /*background-color: $brown-v1;*/
    /*background: #2b2b2b;
    color: $white;*/
    background-color: $brown;
    box-shadow: 0px 4px 8.28px 0.72px rgba(0, 0, 0, 0.21);
    color: $white;
    position: absolute;
    right: -4px;
    /*top: -20px;*/
    top: 2px;
    border-radius: 100%;
}


.size-box input + label {
    padding: 5px 10px;
}

.size-box input:checked + label {
    background-color: $brown-v1;
    color: $white;
}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    box-shadow: none;
}

.select2-container .selection .select2-selection {
    /*border: 1px solid #a26c35;*/
    border: 1px solid #c4c4c4;
    border-radius: 0.25rem;
    height: 45px;
}

.image-thumb-container {
    border: 2px solid rgba(191, 135, 60, 0.3) !important;
}

.image-thumb-container:hover {
    border: 2px solid rgba(191, 135, 60, 1) !important;
}

.voorwaarden-link {/*color: $brown-v1;*/color: $brown;}
.voorwaarden-link:hover,
.voorwaarden-link:focus {/*color: $brown-v2;*/color: #000;}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    border: 1px solid #a26c35;
    /*border-color: #a26c35;*/
    border: 1px solid #C4C4C4;
    border-color: #C4C4C4;
}

.home-brands .row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-brands .row img {
    max-height: 120px;
    object-fit: contain;
}


.algemene-voorwaarden-main a {
    color: #ccb59e;
}

.algemene-voorwaarden-main a:hover,
.algemene-voorwaarden-main a:focus {
    color: #a26c35;
    text-decoration: none;
}

.algemene-voorwaarden-main p,
.algemene-voorwaarden-main li {
    color: #000;
}

.algemene-voorwaarden-main h3 {
    font-family: $font3;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}

.algemene-voorwaarden-main #art1,
.algemene-voorwaarden-main #art2,
.algemene-voorwaarden-main #art3,
.algemene-voorwaarden-main #art4,
.algemene-voorwaarden-main #art5,
.algemene-voorwaarden-main #art6,
.algemene-voorwaarden-main #art7,
.algemene-voorwaarden-main #art8,
.algemene-voorwaarden-main #art9,
.algemene-voorwaarden-main #art10,
.algemene-voorwaarden-main #art11,
.algemene-voorwaarden-main #art12,
.algemene-voorwaarden-main #art13,
.algemene-voorwaarden-main #art14,
.algemene-voorwaarden-main #art15,
.algemene-voorwaarden-main #art16 {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;
}


.product_option_remarks {
    display: none;
}

.menuwebshop {margin-left: 30px;}

/*.webshop-categories > div.container {margin-bottom: 20px;}
.webshop-categories > div.container {
    padding-left: 0px;
    padding-right: 0px;
}
.webshop-categories > div.container > div.row {
    margin-left: -10px;
    margin-right: -10px;
}

.webshop-categories > div.container > div.row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.webshop-categories > div.container > div.row > div > div.row {
    margin-left: -10px;
    margin-right: -10px;
}*/



/*@media (min-width: 960px) and (max-width: 1199px) {
        .over-ons-slider-section .uk-slidenav {
            background: #FFF;
            color: #a26c35;
        }

        .home-product-slider-nav {
            display: block !important;
        }
    }*/

input, select, textarea {-webkit-appearance: none;}

.price-input:before {
    top: 2px;
}

.cart-page .qty-selector .cart-qty {
    height: 35px;
    border: none;
    width: auto;
    text-align: left !important;
}

.toggle-filter {
    border-radius: 0px !important;
}

.cart-qty-selector {
    position: relative;
    display: inline-block;
    /*border: 1px solid $brown-v1;*/
    border: 1px solid $brown;
    padding: 0px 5px;
    box-sizing: border-box;
}

.qty-selector .cart-qty-select-open {
    height: auto;
    width: auto;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
    margin-left: 0px;
    padding: 0px 0;
    cursor: pointer;
}

.qty-selector .cart-qty-select-options {
    left: -1px;
}


.cart-page .add-discount-code,
.checkout-page .add-discount-code {
    display: inline-block;
    /*background-color: #74C6C7;*/
    background-color: #bf873c;
    color: #fff;
    font-family: "GothamNarrow-Light", sans-serif;
    font-size: 17px;
    padding: 10px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.cart-page .add-discount-code:hover,
.cart-page .add-discount-code:focus,
.checkout-page .add-discount-code:hover,
.checkout-page .add-discount-code:focus {
    background-color: #2B2B2B;
    text-decoration: none;
}

.cart-page .discount-code + .add-discount-code,
.checkout-page .discount-code + .add-discount-code {
    /*background-color: #bf873c; */
font-family: aviano-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    padding: 15px 55px;
    color: #FFF;
    display: inline-block;
    margin-top: 0px;
    position: relative;
    z-index: 0;
}

.cart-page .discount-code + .add-discount-code::before,
.checkout-page .discount-code + .add-discount-code::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000;
    transition: all 0.3s;
}

.cart-page .discount-code + .add-discount-code::after,
.checkout-page .discount-code + .add-discount-code::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    background-color: #AF8A4E;
    border: 1px solid #AF8A4E;
    transform: scale(1.2, 1.2);
}

.cart-page .discount-code + .add-discount-code:hover::before,
.checkout-page .discount-code + .add-discount-code:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
}

.cart-page .discount-code + .add-discount-code:hover::after,
.checkout-page .discount-code + .add-discount-code:hover::after {
    opacity: 1;
    transform: scale(1, 1);
}

.checkout-title-container {
    max-height: 128px;
}


.price-input:before {
    color: #666 !important;
    top: 5px;
}


@media (min-width: 992px) and (max-width: 1440px) {
    .checkout-page .cart-totals-html-desktop {
        margin-left: 2% !important;
        flex: 0 0 30%;
        max-width: 30%;
    }
}

@media (min-width: 992px) and (max-width: 1680px) {
    .uk-position-center-left-out {right:calc(100% - 60px);}
    .uk-position-center-right-out {left:calc(100% - 60px);}
}

@media (max-width: 1680px) {
    .algemene-voorwaarden-header > div, .disclaimer-header > div, .privacy-policy-header > div, .contact-header > div {
        padding-left: 15px;
        padding-right: 15px;
    }

    .checkout-title-container > div {
        padding-left: 15px;
        padding-right: 15px;
    }

}


@media (min-width: 992px) and (max-width: 1199px) {

    .home-usps ul {
        padding-left: 12px;
        padding-right: 12px;
    }

    .home-usps ul li {
        padding-right: 12px;
    }

    .home-ontspannen-genieten-row1-col2 img {max-width: 457px;}
    .home-ontspannen-genieten-row2-col1 img {max-width: 457px;}
    /* .home-verpakken-col2 img {max-width: 457px;}*/
    .over-ons-rust-col2 img {max-width: 457px;}
    .over-ons-contact-col2 img {max-width: 457px;}
    .over-ons-slider-section {
        padding-bottom: 150px;
    }

    .home-categories-section {padding-bottom: 100px !important;}

    .home-ontspannen-genieten-row1 {
        padding-bottom: 100px;
    }
    .home-ontspannen-genieten-row2 {
        padding-top: 100px;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .home-ontspannen-genieten-row1-col2 img {max-width: 357px;}
    .home-ontspannen-genieten-row2-col1 img {max-width: 357px;}
    /*.home-verpakken-col2 img {max-width: 357px;}*/
    .over-ons-rust-col2 img {max-width: 357px;}
    .over-ons-kwaliteit img {max-width: 367px;}
    .over-ons-contact-col2 img {max-width: 357px;}
    .over-ons-slider-section {
        padding-bottom: 140px !important;
    }
    .home-categories-section {padding-bottom: 100px !important;}
}

@media (min-width: 768px) {

    .home-main > div > div > div:nth-child(1) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .home-bottom > div > div > div:nth-child(2) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }

    .home-bottom > div > div > div:nth-child(2) img {
        max-width: 552px;
        object-fit: contain;
    }




    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }

    .contact-main > div > div > div:nth-child(1) {padding-right: 20px;}
    .contact-main > div > div > div:nth-child(2) {padding-left: 20px;}

    /*.productgalleryslider {
        flex: 0 0 17.15%;
        max-width: 17.15%;
    }
    .productgallerymainimg {
        flex: 0 0 82.85%;
        max-width: 82.85%;
    }*/

    .catprod {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

}


@media (min-width: 992px) {


    .related-prodcuts .order-btn {
        line-height: 16px !important;
    }

    .home-warmte-in-de-huiskamer-row2 {
        padding-top: 80px;
    }

    .cart-page .cart-page-cart-content-totals {
        margin-left: 4%;
    }

    .checkout-page .cart-totals-html-desktop {
        margin-left: 4%;
    }

    .cart-totals-html-mobile {
        display: none;
    }

    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 750px;
    }

    .contact-main > div > div > div:nth-child(1) {padding-right: 40px;}
    .contact-main > div > div > div:nth-child(2) {padding-left: 40px;}

    .privacyverklaring-main-content-table-mobile {
        display: none;
    }
}



@media (min-width: 1200px) {

    .over-ons-uniek-verdeler .over-ons-rust-col1 * {max-width: 615px;}
    .over-ons-uniek-verdeler .over-ons-rust-col2 {text-align: right;}
    .over-ons-uniek-verdeler .over-ons-rust-col2 img {max-width: 555px;}

    .site-footer .col-lg-4 {
        flex: 0 0 30.3333333333%;
        max-width: 30.3333333333%;
    }

    .site-footer .col-lg-2 {
        flex: 0 0 19.6666666667%;
        max-width: 19.6666666667%;
    }

    /*.container {
        max-width: 1470px;
    }*/
   /* .container {
        max-width: 1240px;
    }*/

    .container {
        max-width: 1366px;
    }

    .home-inspirerende-merken > div.container {
        max-width: none;
    }

    .home-inspirerende-merken > div.container > div.row {
        max-width: 1240px;
        margin-left: auto;
        margin-right:auto;
    }

    .home-inspirerende-merken > div.container {
        position: relative;
    }

    .home-inspirerende-merken > div.container {
        position: relative;
    }

  /*  .home-inspirerende-merken > div.container::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        background: url('/images/left1.png') no-repeat 0% 0%;
        width: 100%;
        max-width: 280px;
        height: 373px;
    }*/

    /*
    .category-detail-main, .checkout-page-form {
        max-width: 1240px;
    }
    */



    .pager {margin-top: 60px;}

    .info-header > div {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    /*.category-detail-header > div {
        padding-left: 262px;
    }*/

    .category-detail-header > div:nth-child(1) > div:nth-child(1), .category-detail-header > div:nth-child(1) > div:nth-child(1) {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .cartnav  > div {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .checkout-title-container > div {
        max-width: 1280px;
        padding-left: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .product-detail-header > div > div {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .category-detail-main > div > div.col-xl-3 {
        flex: 0 0 21.94%;
        max-width: 21.94%;
    }

    .site-footer > .container {
        max-width: 1360px;
    }


}

/*
.product-detail-header > div > div {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}
*/


@media (min-width: 992px) {

    .home-unieke-collectie-homewear img {
        height: 662px;
        /*max-width: 557px;*/
        object-position: 43% 50%;
    }

    .home-categories img {
        min-height: 346px;
    }

    .home-ontspannen-genieten-row1-col2 img {
        height: 674px;
    }

    .home-ontspannen-genieten-row2-col1 img {
        height: 577px;
        object-position: 66% 50%;
    }

    .home-warmte-in-de-huiskamer-row1 img {
        height: 396px;
    }

    .home-warmte-in-de-huiskamer-row2 img {
        height: 490px;
    }

    .home-verpakken-col2 img {
        height: 674px;
    }

    /*.over-ons-rust-col2 img {
        height: 662px;
    }*/

    .over-ons-rust-col2 img {
        height: 500px;
    }

    .over-ons-kwaliteit img {
        height: 490px;
    }

    .over-ons-contact-col2 img {
        height: 674px;
    }

}

@media (max-width: 1800px) {

    .pl-5, .px-5 {
        padding-left: 40px !important;
    }

    .pr-5, .px-5 {
        padding-right: 40px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 40px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 40px !important;
    }

}

@media (min-width: 1283px) and (max-width: 1530px) {
   /* ul.menu-main-menu {
        max-width: 700px;
        margin: 0 auto;
    }*/
}

@media (max-width: 1439px) {

    .container {
        padding-left: 30px;
        padding-right: 30px;
    }



    .pl-5, .px-5 {
        padding-left: 40px !important;
    }

    .pr-5, .px-5 {
        padding-right: 40px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 40px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 40px !important;
    }


    .home-usps ul {
        margin-right: -40px;
    }

    .home-usps ul li {
        padding-right: 40px;
        font-size: 15px;
    }

    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        left: 40px;
    }

}

@media (max-width: 1282px) {


    .menu-container-mobile {
        display: none;
    }


    .header-general-top > div > div {
        justify-content: flex-start !important;
        -webkit-justify-content: flex-start !important;
    }

    .header-general-top .col-3 {
        display: none;
    }

    .header-general-top .col-9 {
        padding-left: 30px;
    }

    .mobile-menu-block {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 75vw;
        max-width: 350px;
        background: #fff;
        pointer-events: none;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 300ms ease;
    }

    .mobile-menu-block.open {
        z-index: 9999;
        opacity: 1;
        transform: translateX(0);
        pointer-events: all;
    }
}


@media (max-width: 1199px) {

    .site-footer {
        background-position: 100% 100% !important;
    }

    .offerte-container .cart-contents > div.row {
        margin-left: 0px;
        margin-right: 0px;
    }


    /*    .home-header-logo + .menu-container {
        display: none;
    }
*/

    .mobile-menu-block {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 75vw;
        background: #fff;
        pointer-events: none;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 300ms ease;
    }

    .mobile-menu-block.open {
        z-index: 9999;
        opacity: 1;
        transform: translateX(0);
        pointer-events: all;
    }

    /*    .menu-main-menu {
        display: none !important;
    }*/

    .pl-5, .px-5 {
        padding-left: 20px !important;
    }

    .pr-5, .px-5 {
        padding-right: 20px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 20px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 20px !important;
    }

    .product-detail-header {
        padding-top: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .alert + .product-detail-header {
        padding-top: 20px;
    }

    .mini-cart .badge-main {
        /*background-color: $brown-v1;*/
/*        background-color: #2b2b2b;
        color: $white;
        position: absolute;
        right: -2px;
        top: -14px;*/
        background-color: $brown;
        box-shadow: 0px 4px 8.28px 0.72px rgba(0, 0, 0, 0.21);
        color: $white;
        position: absolute;
        right: -12px;
        /*top: -20px;*/
        top: 0px;
        border-radius: 100%;
    }


    header > div.px-5 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .info-header .search-field {
        margin-bottom: 0px;
    }



    .home-usps ul {
        /*margin-right: -20px;*/
        margin-right: 0px;
    }

    /*.home-usps ul li {
    padding-right: 20px;
}*/

    .home-usps ul li {
        padding-right: 8px;
    }

    .container {
        max-width: 100%;
    }
}



@media (min-width: 768px) and (max-width: 991px) {
    .cart-page-cart-content .cart-page-cart-content-totals {
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: auto;
        margin-right: 15px;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-page-cart-content-totals > div:nth-child(1) > div {
        margin-left: 0px;
        margin-right: 0px;
    }

}

.webshop-categories-container > div > div {
    padding-left: 10px;
    padding-right: 10px;
}


#product-detail .options {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: column;
    -webkit-flex-direction: column;
}


#product-detail .options > div:nth-child(1) {
    order: 3;-webkit-order: 3;
}

#product-detail .options > div:nth-child(2) {
    order: 1;-webkit-order: 1;
}

#product-detail .options > div:nth-child(3) {
    order: 2;-webkit-order: 2;
}

.bakoven-model-5 #product-detail .options > div:nth-child(1) {
    display: none;
}



.do-search:hover {
    cursor: pointer;
}

.user-login-link:hover {
    text-decoration: none;
}


@media (max-width: 1100px) {
    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        padding: 15px 20px;
    }
}


@media (max-width: 1088px) {
    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        margin-top:20px;
    }
}

/*@media (min-width: 480px) and (max-width: 1088px) {
    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        margin-left:20px;
    }
}

@media (min-width: 960px) and (max-width: 1088px) {
    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        margin-left:0px;
    }
}*/


@media (max-width: 991px) {

    .offerte-container .cart-totals {
        padding-left: 0px !important;
    }

    .offerte-container .overzicht-title {
        margin-top: 40px;
        text-align: center;
    }

    .home-contact form > div:last-child {
        text-align: left;
    }

    .pager .prev-btn a, .pager .next-btn a {
        font-size: 16px;
    }

    .home-header h1 span {
        font-size: 36px;
    }
    .home-header .title-bottom {
        font-size: 26px;
    }

        .header-logo > div:nth-child(2) {
        padding-top: 20px;
    }

    .category-detail-header h1, .product-detail-header h1, .checkout-title-container h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .home-usps ul li, .over-ons-usps ul li, .categorie-top-usps ul li, .product-top-usps ul li,
    .category-detail-main .catprod .product-info .product-title, .full-offer {
        font-size: 18px;
    }

    .home-header p, .home-categories ul li, .site-footer *, .site-footer a {
        font-size: 18px;
        line-height: 28px;
    }


    .home-header > div {
        padding-top: 75px;
        padding-bottom: 60px;
    }

    .home-main > div, .home-popular-products > div, .home-logos > div, .site-footer, .over-ons-uniek-verdeler > div {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-slider-section {
        padding-top: 40px;
    }

    .site-footer {
        padding-top: 40px;
    }

    .home-main > div, .home-podium-op-maat {
        padding-bottom: 20px;
    }

    .home-bottom > div {
        padding-top: 0px;
    }

    .home-usps ul {
        margin-bottom: 20px !important;
    }

    .footer-col1 {
        text-align: center !important;
        margin-bottom: 40px;
    }

    .footer-col2 {
        margin-top: 0 !important;
    }

    .menuwebshop {
        margin-left: 15px;
    }

    .header-icons .menutext {
        font-size: 17px;
    }


    .webshop-categories-container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .webshop-categories-container > div > div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .home-merken {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-categories-title + .container {
        margin-top: -20px;
    }

    #related-products h2 {
        max-width: none;
    }

    .back-to-webshop {
        padding-top: 0px;
        margin-top: 0px !important;
        padding-bottom: 20px !important;
        margin-bottom: 20px !important;
    }

    #related-products h2 {
        font-size: 24px;
    }

    #related-products .related-products > div > div {
        margin-bottom: 15px !important;
    }

    #related-products .product-list-item .product-info {
        padding-bottom: 15px !important;
    }

    #properties-description .description h2 {
        font-size: 32px;
    }

    #properties-description {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-warmte-in-de-huiskamer-row2 {
        padding-top: 40px;
    }

    .qty-add-to-cart > div {
        width: 100%;
    }

    #product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
        padding: 0px 20px;
    }

    #product-detail .qty-add-to-cart .col-5,
    #product-detail .qty-add-to-cart .col-7 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    #product-detail .qty-group .qty-field {
        width: auto;
        min-width: 34px;
        max-width: 100px;
    }

    .home-merken > div {
        padding-left: 15px;
    }

    /* #product-detail > div > div > div {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }*/

    .category-detail-main .category-filters-container {
        padding: 30px 15px;
    }

    .catprod {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    /* .filter-mob {
        padding-top: 40px;
    }

    .category-detail-main > div > div.col-xl-9 {
        padding-top: 40px;
    }*/

    .filter-mob {
        padding-top: 20px;
    }

    .category-detail-main > div > div.col-xl-9 {
        padding-top: 20px;
    }

    .checkout-page .checkout-page-form {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .cart-totals-html-desktop {
        display: none;
    }

    .cart-totals-html-mobile {

    }

    .cart-totals-html-mobile .cart-totals {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .info-header > div {
        padding-bottom: 0px !important;
    }

    .category-detail-header {
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .pl-5, .px-5 {
        padding-left: 15px !important;
    }

    .pr-5, .px-5 {
        padding-right: 15px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 15px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 15px !important;
    }


    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 500px;
    }


    .searchpage-content > div {
        padding-top: 40px;
        padding-bottom: 40px;
    }


    .over-ons-header h1, .contact-header h1, .algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .home-unieke-collectie-homewear {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-unieke-collectie-homewear-col2 > div {
        padding-top: 40px;
    }

    .privacyverklaring-main-content-table-desktop {
        display: none;
    }

    .home-usps h2, .home-categories-title h2, .webshop-categories-title h2 {
        font-size: 95.39px;
        line-height: 83.6px;
        letter-spacing: 8px;
    }

    .home-merken h2 {
        font-size: 79.76px;
        line-height: 68.91px;
        letter-spacing: 8px;
    }

    .home-usps h2 {
        margin-bottom: 0px;
    }

    .home-usps ul {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .home-categories-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ontspannen-genieten {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ontspannen-genieten-row1 {
        padding-bottom: 20px;
    }

    .home-ontspannen-genieten-row2 {
        padding-top: 20px;
    }

    .home-categories img {
        max-height: 300px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 30px;
    }


    .over-ons-rust {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-kwaliteit {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .over-ons-kwaliteit > div > div > div:nth-child(2) {
        padding-top: 40px;
    }

    .over-ons-slider-section {
        padding-bottom: 40px;
    }

/*    .over-ons-contact {
        padding-top: 40px;
        padding-bottom: 40px;
    }*/

    .over-ons-contact {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .over-ons-contact-col2 {
        padding-top: 20px;
    }

    /*   .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        z-index: 1;
        position: absolute;
        top: 66%;
    }*/

    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        left: 30px;
    }

    .over-ons-header img,
    .contact-header img,
    .algemene-voorwaarden-header img,
    .disclaimer-header img,
    .privacy-policy-header img {
        min-height: 300px;
        object-fit:cover;
    }


    .cart-contents .col-md-8 {
        padding-top: 40px;
    }

    .cart-page-cart-content .cart-totals {
        padding-left: 0px !important;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-content-main,
    .cart-page-cart-content .cart-content-proceed {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cart-page-cart-content .cart-content-header {border-bottom: none;}


}

.header-general-top > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}


.home-bakovens-products-desktop {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-bakovens-products-desktop-inner {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    margin-bottom: 40px;
}

.home-deegkneders-row2-widget > div:nth-child(1) {
    display: none;
}

.home-deegkneders-row2-widget > div:nth-child(2) img {
    max-width: 473px;
    max-height: 338px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-deegkneders-row2-content * {
    text-align: left !important;
}

.home-deegkneders-row2 {
    margin-top: 40px;
}

.home-deegkneders-row2 .home-deegkneders-row2-widget {
    text-align: left;
}

.home-deegkneders-slider .txt-container {
    min-height: 134px;
}

.home-deegkneders-slider .txt-container .title {
    font-size: 20px;
}

.home-inspirerende-merken > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-inspirerende-merken-col1 > div + div {
    padding-top: 40px;
}

.home-snelle-levering div.container div.row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-inspiration {
    background: #F9F5EF;
}

.home-familybusiness div.container div.row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}


.site-footer > div.container > div.row {
    border-top: 1px solid #AF8A4E;
    padding-top: 50px;
}

.site-footer ul {
    list-style: none;
    padding-left: 0px;
    margin-top: 0px;
}

.footer-col4 img {
    margin-top: 40px;
}

.site-footer {
    background: url('/images/watermerk.png') no-repeat 93.80% calc(50% + 20px);
}

.top-menu-container-bottom {
    display: inline-block;
    /*border-bottom: 1px solid $brown;*/
    padding-bottom: 30px;
    width: 100%;
    max-width: 968px;

}

.top-menu-container-bottom ul {
    justify-content: flex-end !important;
    border-bottom: 1px solid $brown;
    display: inline-flex;
    margin-right: 0px
}

.top-menu-container-bottom ul li:first-child {
    padding-left: 0px;
}


.top-menu-container-bottom ul li:last-child {
    padding-right: 0px;
}

.footer-logo-mobile + h3 {
    margin-top: 0px;
}

.page-btn {
    border: none !important;
}

.filter-mob > div {
    padding-left: 0px;
}

#mobile-filter > div {
    padding-left: 0px;
    padding-right: 0px;
}


.wasinstructiessection + .wasinstructiessection {
    margin-top: 40px;
}

table.wasinstructiestable {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

table.wasinstructiestable caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

table.wasinstructiestable tr:nth-child(odd) {
    /*background-color: #f8f8f8;*/
    background-color: transparent;
    border: 1px solid #ddd;
    padding: 0 .35em;
}

table.wasinstructiestable tr:nth-child(even) {
    /*background-color: #f1f1f1;*/
    background-color: transparent;
    border: 1px solid #ddd;
    padding: 0 .35em;
}


table.wasinstructiestable tr.empty-row:nth-child(odd),
table.wasinstructiestable tr.empty-row:nth-child(even) {
    background: transparent;
    border-left: 1px solid #FFF;
    border-right: 1px solid #FFF;
}

table.wasinstructiestable tr.blanco + tr.empty-row {
    border-bottom: 1px solid #FFF;
}

table.wasinstructiestable tr.empty-row + tr.empty-row {
    border-top: 1px solid #FFF;
}

table.wasinstructiestable th,
table.wasinstructiestable td {
    padding: .625em;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
}

table.wasinstructiestable td {
    line-height: 26px;
    min-height: 26px;
    box-sizing: content-box;
}

table.wasinstructiestable th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    table.wasinstructiestable {
        border: 0;
    }

    table.wasinstructiestable caption {
        font-size: 1.3em;
    }

    table.wasinstructiestable thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table.wasinstructiestable tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    table.wasinstructiestable tr.blanco {
        margin-bottom: 0px;
    }

    table.wasinstructiestable td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    table.wasinstructiestable tr.first-tr td {
        border-bottom: none;
    }

    table.wasinstructiestable tr.empty-row,
    table.wasinstructiestable td.blanco {
        display: none;
    }

    table.wasinstructiestable tr:not(.blanco) + tr.blanco {
        margin-top: -.625em;
    }

    table.wasinstructiestable td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table.wasinstructiestable td:last-child {
        border-bottom: 0;
    }
}



@media (min-width: 960px) {

    .prod-header-content {
        padding-bottom: 90px;
    }

    .bottom-usps {
        padding-top: 40px;
        padding-bottom: 70px;
    }

    .pager .text-end {
        padding-right: 15px;
    }

    .over-ons-bottom {
        padding-top: 60px;
    }

    .over-ons-bottom-col1 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .over-ons-bottom-col2 {
        text-align: right;
        padding-bottom: 130px;
    }

    .over-ons-bottom-col2 img {
        height: 659px;
        width: 100%;
        object-fit: cover;
        border-radius: 250px 0px 0px 0px;
        max-width: 496px;
    }


    .home-inspirerende-merken-col1 > div {
        width: 100%;
    }

   /* .home-inspirerende-merken-col2 {
        padding-left: 100px;
    }*/

    .home-inspirerende-merken > div.container {
        position: relative;
        z-index:0;
    }


    .home-inspirerende-merken > div.container::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        /*background: url('/images/left1.png') no-repeat 0% 0%;*/
        background: url('/storage/media/images/dsc07670.webp') no-repeat 50% 50%;
        width: 100%;
        max-width: 280px;
        height: 373px;
        background-size: cover;
    }

    .copyright-mobile {
        display: none;
    }

    .footer-logo-mobile {
        display: none;
    }

    .home-familybusiness {
        padding-top: 95px;
        padding-bottom: 140px;
    }

    .home-familybusiness-col1 img {
        max-width: 496px;
        height: 659px;
        object-fit: cover;
        object-position: 38% 50%;
    }

    .home-inspiration > div.container {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .home-snelle-levering {
        padding-top: 40px;
        padding-bottom: 120px;
    }

    .home-snelle-levering-col2, .over-ons-bottom-col2 {
        text-align:right;
    }

    .home-snelle-levering-col2 img {
        object-position: 70% 50%;
    }

    .home-snelle-levering-col2 img, .over-ons-bottom-col2 img {
        width: 496px;
        height: 659px;
        border-radius: 250px 0px 0px 0px;
        object-fit: cover;
        max-width: 496px;
    }

    .home-image-slider {
        padding-top: 50px;
    }

    .home-inspirerende-merken > div.container {
        padding-top: 100px;
        padding-bottom: 40px;
    }

    .home-inspirerende-merken-col1 img {
        width: 496px;
        height: 659px;
        object-fit: cover;
        max-width: 496px;
        object-position: 70% 50%;
    }


    .home-header .header-col1 img {
        height: 710px;
        border-radius: 0px 500px 0px 0px;
    }

    .over-ons-main-col1 img {
        height: 710px;
        border-radius: 0px 500px 0px 0px;
    }

    .home-header .header-col2 > div {
        padding-left: 75px;
    }

    .home-aanbod > div {
        padding-top: 40px;
    }

    .home-deegkneders-row2 {
        margin-bottom: 100px;
    }

    .home-product-slider-mobile {
        display: none;
    }

    .faq-main + .faq-contact {
        padding-top: 110px;
    }

    .container-fluid-mobile {
        display: none !important;
    }

    .galerij-main {
        padding-bottom: 110px;
    }
}

.mobile-menu-icon {
    padding-left: 15px;
}


@media (min-width: 1100px) {
    .language-switcher-div ul {margin-right: 12px;}
    .language-switcher-div ul.menu-language-items > li {
        padding-left: 12px;
    }

/*    .mobile-menu-block {
        display: none !important;
    }
    .mobile-menu-block-open-bottom {
        display: none !important;
    }*/

}

@media (min-width: 960px) and (max-width: 1099px) {
    .container-fluid-desktop {
        display: none !important;
    }
    .container-fluid-mobile {
        display: block !important;
    }
}


@media (max-width: 767px) {
    //.header-logo {max-width: 50px !important;}
    .header-logo {max-width: 120px !important;}
}


@media (min-width: 960px) and (max-width: 1282px) {
   /* .container-fluid-desktop {
        display: none !important;
    }
    .container-fluid-mobile {
        display: block !important;
    }*/
    .container-fluid-mobile > div > div.col-5.d-lg-none {
        display: block !important;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex !important;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .container-fluid-mobile > div > div.col-5.d-lg-none .language-switcher-div {
        margin-right: 30px;
    }
    .container-fluid-mobile > div > div.col-5.d-lg-none .mobile-menu-icon {
        display: inline-block !important;
    }
    .container-fluid-mobile > div > div.col-5.d-lg-none + div.menu-container {
        display: none !important;
    }
    .container-fluid-mobile > div > div.main-nav-container {
        display: block !important;
    }


}


ul.menu-main-menu > li > a {
    padding: 12px 6px;
}

.product-header-general-top {
    padding-bottom: 40px;
}

.alert {
    position: relative;
    z-index: 9999;
}

.productpricespan {
    display: block;
}

.category-detail + .home-contact-section {
    padding-top: 50px;
    padding-bottom: 50px;
}

.home-logos-slider-desktop-inner li,
.home-logos-slider-desktop-inner li div {
    min-height: 84px;
}

.home-logos-slider-desktop-inner li img {
    /*min-height: 100%;*/
    width: 100% !important;
    height: auto !important;
    max-height: 100%;
    object-fit: contain;
}


.home-logos-slider-desktop-inner ul.uk-slider-items {
    margin-top: 20px;
    margin-left: -20px;
}

.home-logos-slider-desktop-inner ul.uk-slider-items li {
    padding-left: 20px;
}

.sort-order {
    height: 50px !important;
    margin-top: 20px !important;
}


@media (max-width: 959px) {




    .home-fashion-slider-desktop * {
        max-width: 100%;
    }

    .home-fashion-slider-desktop .uk-slider-items li img {
        max-width: 100%;
        object-fit: cover;
    }

    .footer-logo-mobile {
        max-width: 150px;
        max-height: 150px;
        object-fit: contain;
    }

    .bottom-usps {
        padding-bottom: 40px;
    }

    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        margin-top:0px;
    }

    .pager .next-btn {
        padding-right: 15px;
    }

    .home-aanbod-cta a.cta, .home-snelle-levering a.cta, .home-familybusiness a.cta, .over-ons-bottom-col1 a.cta {
        padding: 9px 38px;
    }

    .over-ons-bottom-col1 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        padding-bottom: 20px;
    }



   /* .home-inspirerende-merken-col1 {
    flex: 0 0 67.44%;
    max-width: 67.44%;
    }*/


    .home-inspirerende-merken-col1 {
       /* width: 67.44%;
        padding-left: 32.56%;*/
        /*width: 67.44%;*/
        /*padding-left: 16.28%;*/
    }

    .home-inspirerende-merken-col1 img {
        min-height: 313px;
        object-fit: cover;
    }

    .home-inspirerende-merken > div.container::before {
        width: 32.56% !important;
        bottom: auto;
        /*top: -35px;*/
        top: 55%;
    }

    .home-aanbod-cta a.cta {
        margin-top:0px;
    }

    .home-header .header-col1 img {
        height: 710px;
        border-radius: 0px 500px 0px 0px;
    }

    .home-snelle-levering-col2 img,
    .over-ons-bottom-col2 img {
        width: 496px;
        height: 659px;
        border-radius: 250px 0px 0px 0px;
        object-fit: cover;
        max-width: 496px;
    }

    .footer-logo-desktop {
        display: none;
    }

    .copyright-desktop {
        display: none;
    }

    .mobile-order-1 {
        order: 1;
        -webkit-order: 1;
    }

    .mobile-order-2 {
        order: 2;
        -webkit-order: 2;
    }

    .mobile-order-3 {
        order: 3;
        -webkit-order: 3;
    }

    .mobile-order-4 {
        order: 4;
        -webkit-order: 4;
    }

    .home-deegkneders-row2 {
        margin-bottom: 40px;
    }


    .home-bakovens-products-desktop {
        display: none;
    }

    .category-detail + .home-contact-section {
        padding-top: 0px;
    }

    .category-detail + .home-contact-section .home-contact-col1 > div {
        padding-top: 0px;
    }

    .over-ons-contact .home-contact-col1 > div {
        padding-top: 20px;
    }

    .over-ons-main {
        padding-bottom: 20px;
    }

.page-home header {
    background-color: rgba(191, 135, 60, 0.91);
}


   /* .page-home .home-header {
        margin-top: -175px;
    }*/

    .deegkneder-right-chevron-span {
        margin-left: -15px;
    }


    .home-over-mavhek > div {
        padding-top: 100px;
        padding-bottom: 20px;
    }

    .home-our-ovens > div {
        margin-top: -40px;
    }

    .home-deegkneders > div.container > div.row:nth-child(1) {
        padding-top: 40px;
    }

    .home-deegkneders .container > .row:nth-child(2) {
        padding-bottom: 40px;
    }

    .home-levensduur {
        padding-bottom: 0px;
    }




        .header-general-top > div > div {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .header-general-top .col-3 {
        display: none;
    }

    .header-general-top .col-9 {
        padding-left: 30px;
    }


    .product-header-general-top > div > div {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .product-header-general-top .col-2 {
        display: none;
    }

    .product-header-general-top .col-2 + .col-5 {
        padding-left: 30px;
    }


    .product-header-general-top .col-2 + .col-5 + .col-5 {
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        flex: 0 0 58.3333333337%;
        max-width: 58.3333333337%;
    }



    .product-contact-section {
        padding-top: 40px;
        overflow: hidden;
    }

    #product-detail::before {
        bottom: 0px;
    }

    .faq-main + .faq-contact {
        padding-top: 40px;
    }

    .galerij-main {
        padding-bottom: 40px;
    }


    .container-fluid-desktop {
        display: none !important;
    }

    .over-ons-slider-section .uk-slidenav {
        background: #FFF;
    }


    .home-inspirerende-merken > div.container::before {
        width: 100% !important;
        height: calc(100vw /1.6);
        object-fit: cover;
        min-width: 100%;
        top: 40px !important;
        background-position: 50% 10% !important;
    }

    .home-inspirerende-merken > div > div {
        /*padding-top: calc(100vw /1.6 + 40px) ;*/
    }

    .home-inspirerende-merken-col1 {
        width: 100% !important;
        padding-left: 0% !important;
        margin-top: 40px;
    }

    .home-inspirerende-merken-col2 {
        padding-bottom: 15px !important;
    }

}

@media (min-width: 640px) {
    .searchpage-content .search-field-input input {min-width: 400px;}
}

@media (min-width: 640px) and (max-width: 991px) {

    /*.home-usps ul li {
        padding-top: 20px;
    }*/

    .home-usps-col1, .home-usps-col2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}




@media (max-width: 1692px) {

    .page-home .header-logo > div:nth-child(1) {
        padding-top: 190px;
    }
    .page-home .header-logo > div:nth-child(2) {
        padding-top: 50px;
    }

}



@media (min-width: 768px) and (max-width: 939px) {
    .deegkneder-right-chevron-span {
        margin-left: 35px;
        margin-top: 15px;
    }
}

.bottom-usps .row {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    -webkit-align-items: stretch !important;
    align-items: stretch !important;
}


.bottom-usps .row > div {
    display: -webkit-box;
    box-sizing: border-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    align-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-flow: row wrap;
    flex-basis: 100%;
    flex: 1;
}

.bottom-usps-img {
    height: 120px;
}

.bottom-usps-text .p1 {
    font-family: $main-font2;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #AF8A4E;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-transform: uppercase
}

.bottom-usps-text .p2 {
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
    min-height: 102px;
}


@media (max-width: 768px) {
    .page-home .header-logo {
        max-width: 100vw;
        text-align: center;
    }

  /*  .page-home .home-header h1 {
        text-align: center;
    }*/
}


/*
@media (min-width: 1283px) and (max-width: 1692px) {
    .page-home header {
        margin-bottom: -122px;
    }
}*/


.copyright-desktop,
.copyright-mobile {
    margin-top: 20px;
}

.copyright-desktop *,
.copyright-mobile * {
    color: rgba(0,0,0,0.5) !important;
}


@media (min-width: 640px) and (max-width: 767px) {
    .bottom-usps-text .p1 {
        height: 60px;
    }
}

.catippdiv {
    text-align:right;
}

.catippdiv select {
    border: 1px solid #AF8A4E;
    background-color: #AF8A4E;
    color: #FFF;
    padding: 5px 10px;
    text-align: center;
}

@media (max-width: 767px) {

    .home-inspirerende-merken-col1 {
        margin-top: 0px;
    }

    .footer-col1 .category-filter > div > div:nth-child(1) a {
        position: relative;
        left: 5px;
    }

    .footer-col1 .category-filter > div > div:nth-child(2) a {
        position: relative;
        left: 8px;
    }

    .footer-col1 .category-filter > div > div:nth-child(3) a {
        position: relative;
        left: 5px;
    }

    .footer-col1 .category-filter > div > div:nth-child(4) a {
        position: relative;
        left: 7px;
    }

    .footer-col1 .category-filter > div > div:nth-child(5) a {
        position: relative;
        left: -2px;
    }

    .footer-col1 .category-filter > div > div:nth-child(6) a {
        position: relative;
        left: 7px;
    }

    #overview-products > div.col-12 {
        flex: 0 0 50%;
        max-width: 50%;
    }


   #product-detail  .related-prodcuts > div.col-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .category-detail .category-detail-main #overview-products .product-list-item .product-info .product-title {
        font-size: 16px !important;
    }

    .category-detail .category-detail-main #overview-products .catprod .product-info .product-price,
    .category-detail .category-detail-main #overview-products .catprod .product-info .product-price small {
        font-size: 15px !important;
    }

    .filter-mob i {
        font-size: 0px;
        display: none;
    }

    .bottom-usps {
        padding-bottom: 10px;
    }

    .site-footer {
        padding-top: 10px;
    }

    .site-footer > div.container > div.row {
        padding-top: 20px;
    }

    .header-logo {
        height: unset;
    }

    .bottom-usps-img, .bottom-usps-img > img { height: 50px; }
    .bottom-usps-text .p1 {
        font-family: "bagnardregular", serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #AF8A4E;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        text-transform: uppercase;
        margin-bottom: 0px !important;
    }
    .bottom-usps-text .p2 {
        max-width: 345px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.3;
        margin-top: 10px;
        min-height: auto;
    }

    .order-position {
        order: -1;
    }

 .site-footer *, .site-footer a, .site-footer div, .site-footer li {
        font-size: 14px;
        line-height: 34px;
    }

    .site-footer h3 {
        padding-top: 0px;
        margin-top: 20px;
        font-size: 14px;
        line-height: 19px;
    }

    .home-inspirerende-merken-col1 {
        order: 2;-webkit-order: 2;
    }

    .home-inspirerende-merken-col2 {
        order: 1;-webkit-order: 1;
    }

    .home-fashion-slider-desktop .uk-slider-items li, .home-fashion-slider-desktop .uk-slider-items li > div, .home-fashion-slider-desktop .uk-slider-items li img {
        /*height: 260px;*/
        height: 300px !important;
    }

    /*
    .home-inspirerende-merken-col1 {
        display: none;
    }*/

    .home-inspirerende-merken-col1 > div + div {
        display: none;
    }

    .home-inspirerende-merken-col1 {
        /*width: 67.44%;
        padding-left: 32.56%;*/
    }

    .home-inspirerende-merken > div.container::before {
        width: 32.56% !important;
        bottom: auto;
        /*top: -35px;*/
        /*top: 55%;*/
        top: 60%;
    }

    .home-header .header-col1 img {
        height: 372px;
        border-radius: 0px 250px 0px 0px;
    }

    .home-snelle-levering-col2 img,
    .over-ons-bottom-col2 img {
        width: 100%;
        height: 372px;
        border-radius: 0px 0px 0px 250px;
        object-fit: cover;
        max-width: 100%;
    }

    .over-ons-main-col1 img {
        width: 100%;
        height: 372px;
        border-radius: 0px 250px 0px 0px;
        object-fit: cover;
        max-width: 100%;
    }

    .home-familybusiness div.container div.row > div.home-familybusiness-col1 {
        order: 2;-webkit-order:2;
        width: 100%;
    }

    .home-familybusiness div.container div.row > div.home-familybusiness-col2 {
        order: 1;-webkit-order:1;
    }

    .home-deegkneders-row2-content {
        margin-top: 40px;
    }

    .home-contact .container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .accordion {
        line-height: 30px;
        margin-top: 5px;
        padding-bottom: 10px;
    }

    .home-over-mavhek-col2,
    .home-contact-col2,
    .over-ons-main-col2 {
        padding-top: 40px;
    }

    .home-header h1 {
        /*font-size: 44px;*/
        /*line-height: 56px;*/
        /*line-height: 48px;*/
        font-size: 20px;
        line-height: 27px;
    }

    .home-aanbod-titel h2 {
        font-size: 20px;
        line-height: 27px;
    }

    .uk-h2, h2, .home-inspiration h2, .home-familybusiness-col2 h2 {
        font-size: 22px !important;
        line-height: 30px !important;
    }

    .home-familybusiness-col2 h3 {
        font-size: 41px;
        line-height: 32px;
    }

    .home-familybusiness-col2 h3 span {
        display: inline;
        padding-left: 0px;
    }

    .home-familybusiness-col1 img {
        max-height: 372px;
        object-fit: cover;
    }


    .cart-page-cart-content .cart-page-cart-content-totals {
        /* flex: 0 0 50%;
        max-width: 50%;
        margin-left: auto;
        margin-right: 15px;*/
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-page-cart-content-totals > div:nth-child(1) > div {
        margin-left: 0px;
        margin-right: 0px;
    }


    /*    header > div > div > div {
        text-align: center;
    }

*/
    .home-main > div > div > div:nth-child(2) {
        margin-top: 40px;
    }

    .home-bottom > div > div > div:nth-child(2) {
        margin-top: 40px;
    }

    section#related-products {
        padding-top: 0px !important;
    }

    .home-unieke-collectie-homewear-col2 > div,
    .home-warmte-in-de-huiskamer-row1-col1 > div,
    .home-ontspannen-genieten-row1-col1 > div,
    .home-ontspannen-genieten-row2-col2 > div,
    .home-warmte-in-de-huiskamer-row1-col1 > div,
    .home-ontspannen-genieten-row1-col1 > div,
    .home-warmte-in-de-huiskamer-row2-col2 > div,
    .home-ontspannen-genieten-row2-col2 > div,
    .home-verpakken-col1 > div,
    .over-ons-rust h2,
    .over-ons-rust p,
    .over-ons-kwaliteit h2,
    .over-ons-kwaliteit p {
        max-width: none;
    }

    .home-unieke-collectie-homewear-col2 > div {
        padding-left: 0px;
        paddding-right: 0px;
    }

    .over-ons-contact p {
        max-width: none;
    }

    .order-btn, .proceed-button a {
        padding: 10px 20px;
    }

    #product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
        padding: 0px 20px;
    }

    .home-merken h2 {
        font-size: 69.76px;
        line-height: 58.91px;
        letter-spacing: 4px;
    }

    .home-usps h2, .home-categories-title h2, .webshop-categories-title h2 {
        font-size: 65.39px;
        line-height: 53.6px;
        letter-spacing: 4px;
    }

    .contact-main > div > div > div:nth-child(2) {
        padding-top: 40px;
    }



    h2 {
        font-size: 32px;
        letter-spacing: 0px;
    }

    .over-ons-bottom-col1 h2 {
        font-size: 50px !important;
        line-height: 32px;
    }

    .over-ons-bottom-col1 h2 span {
        padding-left: 40px;
    }

    h3 {
        letter-spacing: 0px;
    }


    .algemene-voorwaarden-main h2, .disclaimer-main h2, .privacy-policy-main h2 {font-size: 24px;}
    .algemene-voorwaarden-main h3, .disclaimer-main h3, .privacy-policy-main h3 {font-size: 20px;letter-spacing: 0px;}

    .algemene-voorwaarden-main, .disclaimer-main, .privacy-policy-main, .contact-main {
        padding: 40px 10px;
        box-sizing: border-box;
    }

    #product-detail > div > div {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .cart-content-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

 /*   .header-logo {
        position: relative;
        !*top: -28px;*!
        top: 0px;
        max-width: 220px;
    }*/

    .header-logo {
        position: relative;
        top: 0px;
    }

    .header-logo img {
        position: relative;
        /*top: -28px;*/
        top: 0px;
        max-width: 220px;
    }


    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 300px;
    }

    .over-ons-header h1, .contact-header h1, .algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1 {
        font-size: 24px;
        line-height: 44px;
    }

    .home-categories .txt {
        padding: 10px;
        font-size: 24px;
    }


    .footer-col1, .footer-col2 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .footer-col3 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .footer-col1, .footer-col2, .footer-col3, .footer-col4 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        -webkit-justify-content: center;
        text-align: center !important;
    }

    .home-unieke-collectie-homewear img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 662px;
        max-width: 557px;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row1-col2 img {
        position: relative;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 674px;
        max-width: 577px;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row2-col1 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 577px;
        max-width: 465px;
        object-fit: cover;
    }

    .home-verpakken-col2 img {
        position: relative;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 674px;
        max-width: 577px;
        object-fit: cover;
    }

    .over-ons-rust-col2 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        max-height: 662px;
        max-width: 557px;
        object-fit: cover;
    }

    .over-ons-contact-col2 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        max-height: 674px;
        max-width: 100%;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row2 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    .home-ontspannen-genieten-row2 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    .home-warmte-in-de-huiskamer-row2 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    .home-warmte-in-de-huiskamer-row1-col1 > div, .home-ontspannen-genieten-row1-col1 > div {
        padding: 0px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 30px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 20px;
    }

    .home-warmte-in-de-huiskamer {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-warmte-in-de-huiskamer-row1-col2 {
        text-align: center;
        padding-top: 40px;
    }

    .home-ontspannen-genieten-row1-col2 {
        padding-top: 20px;
    }

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(2) {
        padding-top: 40px;
    }

    .home-warmte-in-de-huiskamer-row2-col2 > div, .home-ontspannen-genieten-row2-col2 > div {
        padding: 0px;
    }

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(1) {
        padding-top: 20px;
    }

    .home-verpakken {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-verpakken-col1 > div {
        padding: 0px;
    }

    .home-verpakken-col2 {
        padding-top: 20px;
    }

    .cart-contents .col-md-8 {
        padding-top: 40px;
    }

    .cart-page-cart-content .cart-totals {
        padding-left: 0px !important;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-content-main,
    .cart-page-cart-content .cart-content-proceed {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cart-page-cart-content .cart-content-header {border-bottom: none;
    margin-left: 0px;
    margin-right: 0px;
    }

    /*.home-categories .col-lg-8 .row + .row {
        margin-top: 0px;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        margin-bottom: 20px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 0px;
    }*/

    .home-unieke-collectie-homewear img,
    .home-ontspannen-genieten-row1-col2 img,
    .home-ontspannen-genieten-row2-col1 img,
    .home-warmte-in-de-huiskamer-row1 img,
    .home-warmte-in-de-huiskamer-row2 img,
    .home-verpakken-col2 img,
    .over-ons-rust-col2 img,
    .over-ons-kwaliteit img {
        max-width: none;
    }


}

@media (max-width: 750px) {
    .home-categories ul li {display: block;}
    .home-categories ul li + li {padding-top: 15px;}
}

@media (max-width: 699px) {
    .form-steps li {
        margin-right: 0px;
        display: block;
    }

    .form-steps li + svg, .form-steps li + i {
        display: none;
    }
}


@media (max-width: 639px) {



    .home-inspiration-slider-desktop-inner ul.uk-child-width-1-2>* {
        width: 100%;
    }

    .home-offer-slider-desktop-inner ul.uk-slider-items li {
        width: 100%;
    }

    .bottom-usps .row > div {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        flex-direction: column;
        -webkit-flex-direction: column;
        -webkit-align-content: flex-start;
        align-content: center;
    }

  /*  .header-general-top-categories {
        margin-top: 40px;
    }*/

    .header-general-top-categories .category-filter-item {
        flex: 0 1 auto;
        width: 100%;
        display: block;
    }

    .header-general-top-categories .category-filter-item a {
        width: 100%;
        text-align: center;
    }

    .header-general-top-categories .category-filter-item + .category-filter-item {
        margin-top: 20px;
    }

    .detail-tab-title a {
        padding: 20px 30px;
        font-size: 18px;
        line-height: 22px;
    }

    .info-header .header-logo {
        max-width: 120px;
    }

    .info-header > div > div > div:nth-child(1) {
        padding-right: 0px;
    }

    .info-header > div > div > div:nth-child(2) {
        padding-left: 0px;
    }

    .header-icons .search-field {
        margin-left: 15px;
        margin-right: 15px;
    }


    .home-usps ul li {
        /*    padding-top: 15px;
    padding-bottom: 15px;*/
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 20px;
        /*display: block;*/
    }

    .home-header-logo > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }


}


@media (max-width: 600px) {
    .checkout-page .butn-next {
        width: 100%;margin-bottom: 30px;
    }
    .checkout-page .butn-light {
        width: 100%;
    }

    .catprod {
        margin-bottom: 15px !important;
    }

    .category-detail-main .product-info {
        padding-bottom: 15px !important;
    }

}


@media (max-width: 520px) {
    .detail-tab-title a {
        padding: 20px 10px;
        font-size: 16px;
        line-height: 22px;
    }
}


@media (min-width: 1089px) {
    #product-detail .qty-add-to-cart-max .order-btn {
        margin-left: 20px;
    }
}

@media (min-width: 463px) {
    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        margin-left:20px !important;
    }
}

@media (min-width: 480px) and (max-width: 1088px) {
    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        margin-left:20px !important;
    }
}

@media (min-width: 960px) and (max-width: 1088px) {
    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        margin-left:0px !important;
    }
}

@media (max-width: 480px) {

    #product-detail .qty-add-to-cart-max .order-btn, #product-detail .qty-add-to-cart-max .proceed-button a, .proceed-button #product-detail .qty-add-to-cart-max a {
        margin-top:20px;
    }


    .home-header h1 span {
        font-size: 26px;
    }
    .home-header .title-bottom {
        font-size: 20px;
    }

    .home-header .title-top {
        left: 5px;
    }


    html, body {
        font-size: 18px;
        line-height: 28px;
    }

    .privacy-policy-main p,
    .privacy-policy-main li,
    .privacy-policy-main td,
    .privacy-policy-main p a,
    .privacy-policy-main li a,
    .privacy-policy-main td a {
        font-size: 16px;
    }

    .home-logos > div > div > div > div + div {
        padding-top: 15px;
    }

    h2,
    .home-main h2,
    .home-main h2 span,
    .home-popular-products h2,
    .home-popular-products h2 span,
    .page-contact h2,
    .home-bottom h2,
    .page-offerteaanvraag h2 {
        font-size: 26px;
        line-height: 33px;
    }

    .home-usps ul li::before, .over-ons-usps ul li::before, .categorie-top-usps ul li::before, .product-top-usps ul li::before {
        position: absolute;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(50%, 50%);
        -moz-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
        -o-transform: translate(50%, 50%);
        transform: translate(50%, 50%);
    }

    /*.home-categories ul li {
        display: block;
        padding-left: 70px;
    }*/


    header .container-fluid-mobile > div > div:nth-child(1),
    header .container-fluid-mobile > div > div:nth-child(2) {
        /*flex: 0 0 100%;
        max-width: 100%;*/
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: space-around;
        -webkit-justify-content: space-around;
    }

    /*   header .container-fluid-mobile > div > div:nth-child(2) {
        padding-top: 40px;
    }*/

    header .container-fluid-mobile > div > div:nth-child(2) {
        padding-top: 15px;
    }

    .page-home header .container-fluid-mobile > div > div:nth-child(2) {
        /*padding-top: 25px;*/
        padding-top: 0px;
    }

    .menu-container-mobile + .main-nav-container {
        padding-top: 0px !important;
    }

    header .search-field,
    header .mini-cart {top: 0px;left: auto;}
    header .mini-cart {top: 0px;left: -20px;}

    header .search-field {
        margin-right: 10px;
    }

    .detail-tab-title a {
        padding: 20px 8px;
        font-size: 15px;
        line-height: 22px;
    }

    .header-home .header-icons .menutext {
        display: block;
    }

    .home-categories .col-lg-8 .row + .row {
        margin-top: 0px;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        margin-bottom: 20px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 20px;
    }

    .home-categories-title + .container > .row > div > .row {
        margin-top: 0px !important;
    }

    .home-categories-section > div > div > div > section > div:nth-child(2) > div > div.col-lg-8 > div:nth-child(2) {
        margin-top: 0px !important;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(1) {
        order: 2;-webkit-order: 2;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        order: 1;-webkit-order: 1;
    }

    .alert .btn {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 30px !important;
        display: block;
    }

    #product-detail .product-usps > div {
        padding-left: 0px;
        padding-right: 0px;
    }

    #product-detail .product-usps ul li {
        font-size: 15px;
    }

    .qty-add-to-cart > div {
        max-width: none;
        width: 100%;
    }

    #product-detail .qty-add-to-cart .col-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #product-detail .qty-add-to-cart .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
    }


    /*  .header-logo {
        position: relative;
        top: -45px;
        max-width: 180px;
        left: -15px;
    }*/

    .header-home .header-logo {
        position: relative;
        top: -50px;
        max-width: 177px;
        left: -45px;
    }

    .header-home > div.py-4 {
        padding-top: 40px !important;
        padding-bottom: 60px !important;
    }

    .header-home #offcanvas-menu .header-logo {
        position: relative;
        top: 0px;
        max-width: 177px;
        left: 0px;
    }


    .info-header #offcanvas-menu .header-logo {
        position: relative;
        top: 0px;
        max-width: 177px;
        left: 0px;
    }

    .footer-col1, .footer-col2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .footer-col2 {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        /*margin-top: 2.5rem !important;*/
        margin-top: 0 !important;
    }

    .home-categories-section .home-categories .col-lg-8 .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .home-categories-section .home-categories .brown-bg {
        margin-bottom: 20px;
    }

    .home-categories-section .home-categories .darkgrey-bg {
        margin-top: 20px;
    }

    .searchpage-content .search-field-input,
    .searchpage-content .do-search-button {
        display: block;
        width: 100%;
    }

    .searchpage-content .search-field-input input {
        min-width: auto;
        width: 100%;
    }


}




.select2-container,
.select2-dropdown {
    background-color: #000 !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #bf873c !important;
}


.checkout-form .select2-container,
.checkout-form .select2-dropdown {
    background-color: transparent !important;
}

.checkout-form .select2-container--default .select2-results__option--highlighted[aria-selected],
.checkout-form .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: transparent !important;
}

.checkout-form .select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: #000;
}

.invalid-feedback * {
    color: #dc3545 !important;
}


body.filter-open {overflow: hidden;}

.galerij-main a.uk-inline img,
.gallery a.uk-inline {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.gallery a.uk-inline {
    display: flex;
}

:focus-visible {
    outline: 0px solid #C4C4C4 !important;
}

.logincontainer .butn-login {
    font-family: aviano-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    background-color: $brown;
    padding: 9px 38px;
    color: #FFF;
    display: inline-block;
    margin-top: 0px;
    position: relative;
    z-index: 0;
    border: none !important;
}

.logincontainer .butn-login:hover,
.logincontainer .butn-login:focus {
    background-color: #000;
}

.logincontainer .btn-link {
    color: $brown;
}

.logincontainer .btn-link:hover,
.logincontainer .btn-link:focus {
    color: #000;
}

.category-detail-main .category-filters-container .category-filter-item a:hover,
.category-detail-main .category-filters-container .category-filter-item a:focus,
.category-detail-main .category-filters-container .category-filter-item a.active {
    color: $brown;
    text-decoration: none;
}

@media(min-width:1099px){
/*    .mobile-menu-block {
        display: none;
    }*/
}
.menu-fixed {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    width: 100vw;
    touch-action: none;
}
.mobile-menu-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(249 245 239 / 45%);
    opacity: 0;
    transition: opacity .15s linear;
}
.mobile-menu-overlay.menu-fixed::before {
    opacity: 1;
}
.mobile-menu-block .search-field {
    margin-right: 20px;
    position: relative;
    top: 2px;
}



.invoerrechten-row {
    border: 1px solid #af8a4e;
    background-color: rgba(175, 138, 78, 0.2);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 20px 10px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
}

.invoerrechten-row.col-1 {
    text-align: center;
    padding-right: 5px;
}

.invoerrechten-row img {
    width: 47px;
    height: 52px;
}

.invoerrechten-row .col-11 {
    padding-left: 5px;
}

.invoerrechten-row p {
    color: #000 !important;
}

.invoerrechten-row p.invoerrechten-p1 {
    text-decoration: underline;
    text-underline-position: under;
}

.invoerrechten-row p#invoerrechten-p1:hover {
    color: #af8a4e !important;
    cursor: pointer;
}


#invoerrechten-p1 {
    margin-bottom: 0px;
}

#invoerrechten-p2 {
    display: none;
    font-size: 18px;
    line-height: 28px;
}

#invoerrechten-p1 + #invoerrechten-p2 {
    margin-top: 20px;
}

#invoerrechten-p1 svg {
    font-family: 'Font Awesome 5 Pro';
    position: relative;
    left: 0px;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
}

#invoerrechten-p1:hover svg {
    position: relative;
    left: 6px;
    color: #AF8A4E;
}

#invoerrechten-p1:hover svg path {
    fill: #af8a4e;
}

@media (min-width: 768px) {
    .invoerrechten-row {
        margin-left: 0px;
    }
}

.socialmediaicons {
    text-align: right;
}

@media (max-width: 767px) {

    #product-detail {
        padding-top: 30px;
    }

    .algemene-voorwaarden-main h1, .disclaimer-main h1, .privacy-policy-main h1 {
        font-size: 24px;
        letter-spacing: 0;
    }

    .cat-rel-img-div {
        display: none !important;
    }

    .socialmediaicons {
        text-align: center;
    }

    .invoerrechten-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .invoerrechten-row .col-1 {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }

    .invoerrechten-row .col-11 {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        margin-top: 30px;
    }

}



@media(min-width:1099px) {
    .mobile-menu-overlay,
    .mobile-menu-block {
        display: none;
    }
}

.home-inspirerende-merken > div.container::before {
    display: none !important;
}

.home-inspirerende-merken-col1 {
    padding-left: 15px !important;
}

@media (min-width: 1200px) {
    .home-inspirerende-merken > div.container > div.row {
        max-width: 1366px;
    }
}



.socialmediaicons a svg {
    color: #AF8A4E;
    width: 24px;
    height: 24px;
}

.socialmediaicons a svg path {
    fill: #AF8A4E;
}

.socialmediaicons a + a {
    margin-left: 10px;
}


.cat-rel-img-div {
    display: inline-block;
}

.cat-rel-img-div:after {
    content: ".";
    display: block;
    height: 0;
    font-size: 0;
    clear: both;
    visibility: hidden;
}

.cat-rel-img-a, .cat-rel-img  {
    display: inline-block;
}

.cat-rel-img-a {
    float:left;
    margin-right: 10px;
    margin-bottom: 10px;
}


.cat-rel-img-a, .cat-rel-img {
    width: 70px !important;
    height: 70px !important;
}

.cat-rel-img img {
    width: 70px !important;
    height: 70px !important;
    object-fit: cover !important;
}

.category-detail-main .product-list-item {
    height: auto !important;
}




