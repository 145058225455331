.site-footer {

    .footer-logo-mobile-div {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    padding: {
        top: 80px;
        bottom: 80px;
    }

    @media (max-width: 959px) {
        .footer-col3 .footer-logo-mobile {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .footer-col1 > div {
            text-align: left;
        }
    }

    @media (max-width: 767px) {
        .footer-col1 {
            margin-bottom: 0;
        }
    }
}
