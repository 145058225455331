


$main-font: aviano-sans, sans-serif;
$main-font1: aviano-sans, sans-serif;
//$main-font2: aviano-serif, serif;
$main-font2: 'bagnardregular', serif;
$main-font3: calibri, sans-serif;
//$main-font4: 'Allison', cursive;
$main-font4: 'Conv_Renatta_Signature', cursive;
$bold-font:  'Caladea', serif;
$h-font: 'Roboto', sans-serif;
$hand-font: 'Dream  Catcher Regular', serif;


.handwriting {
    font-family: $hand-font;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\e900";
    transform: translateY(2px);
    display: inline-block;
}
.icon-user:before {
    content: "\e901";
    transform: translateY(2px);
    display: inline-block;
}
.icon-shopping-cart:before {
    content: "\e902";
    transform: translateY(2px);
    display: inline-block;
}
.icon-heart:before {
    content: "\e903";
    transform: translateY(2px);
    display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
    font: {
        family: $h-font;
        weight:600;
    }

}
h1 {
    text-transform: uppercase;
}

strong, b {
    font-family: $bold-font;
}
