#product-detail {
    .breadcrumbs a {
        color: $black;
    }

    @media (max-width: 767px) {
        .breadcrumbs > p {
            margin-bottom: 0;
        }
    }

    .breadcrumbs a:last-child {
        font-weight: bold;
    }

    .product-info-row {

        > div:first-child > .row {
            height: 100%;

            img {
                object-fit: cover;
            }
        }
    }

    @media (max-width: 767px) {
        .product-info-row {
            padding-top: 0 !important;
        }
    }

    .product-detail-price {
        font-family: aviano-serif, serif !important;
        font-style: normal;
        font-weight: 400;
        color: $black !important;
        font-size: 24px;
    }

    .qty-group {
        border: 1px solid black !important;
        border-radius: 0 !important;

        input {
            color: $black !important;
            font-family: aviano-serif, serif;
        }
    }

    .productgallerymainimg {
        padding-left: 0 !important;
        padding-right: 0;
    }

    .image-container-shadow {
        /*filter: drop-shadow(1px 314px 126px rgba(0, 0, 0, 0.01)) drop-shadow(0px 177px 106px rgba(0, 0, 0, 0.03)) drop-shadow(0px 79px 79px rgba(0, 0, 0, 0.04)) drop-shadow(0px 20px 43px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));*/
        box-shadow: 1px 314px 126px rgba(0, 0, 0, 0.01), 0px 177px 106px rgba(0, 0, 0, 0.03), 0px 79px 79px rgba(0, 0, 0, 0.04), 0px 20px 43px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
    }



    .product-info {
        background: var(--white);
        box-shadow: 1px 314px 126px rgba(0, 0, 0, 0.01), 0px 177px 106px rgba(0, 0, 0, 0.03), 0px 79px 79px rgba(0, 0, 0, 0.04), 0px 20px 43px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
        padding: 40px;

        .product-title {
            /*font-family: aviano-serif, serif !important;*/
            font-family: $main-font2 !important;
            font-style: normal;
            font-weight: 400;
            color: $brown !important;
            margin-bottom: 0 !important;
        }

        .product-brand {
            margin-top: 0 !important;
        }

        .qty-group input {
            min-width: unset;
            max-width: unset;
            width: 100%;
        }

        @media (min-width: 960px) and (max-width: 1088px) {
            .qty-add-to-cart-max div {
                max-width: unset;
                flex: unset;

                .qty-group {
                    width: 70%;
                    text-align: center;
                }

                .butn.order-btn {
                    width: 70%;
                }
            }
        }

        @media (min-width: 1089px) {
            .qty-add-to-cart-max div.col-8 {
                padding-left: 8px;
            }
        }

        @media (max-width: 462px) {
            .qty-add-to-cart-max {
                flex-direction: column;
                max-width: 100%;
                margin: auto;

                > div {
                    max-width: 100%;
                }
            }
        }

    }

    @media (min-width: 960px) {
        .category-details {
            padding-top: 120px !important;
        }
    }

    @media (max-width: 959px) {
        .category-details {
            padding-top: 80px !important;
        }
    }

    .detailed-fabric-image-row {
        height: 100%;
        background: #FFF;

        > div {
            padding-left: 0;
            padding-right: 0;
        }

        .detailed-fabric-image {
            object-fit: cover;
        }
    }

    .product-square {

        .product-info {

            .product-title {
                color: $black !important;
            }
        }
    }

    .related-products {
        padding-left: 0;
        padding-right: 0;

        .to-webshop-btn {
            display: flex !important;
            margin: 10px auto 110px auto;
            font-size: 16px;
        }

        #related-products {
            padding-top: 20px !important;

            .new-content {
                padding-left: 0;
                padding-right: 0;

                .container {
                    padding-left: 0;
                    padding-right: 0;
                }

                .related-products {

                    .uk-slider-container {

                        .uk-slidenav-previous,
                        .uk-slidenav-next {
                            background: none;
                            color: $black;
                        }

                        .uk-slidenav-previous {
                            transform: translateX(40%) translateY(0);
                        }

                        .uk-slidenav-next {
                            transform: translateX(-40%) translateY(0);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 959px) {
    #product-detail .product-info-row {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(4, 1fr);

        .product-main-img {
            grid-row: 2 / 3;
            grid-column: 1 / 5;
            max-width: unset;
        }

        .product-info {
            grid-row: 1 / 2;
            grid-column: 1 / 5;
            max-width: unset;
        }

        .detailed-fabric-image-div {
            display: none;
/*            grid-row: 1 / 2;
            grid-column: 4 / 5;
            max-width: unset;*/
        }
    }
}

@media (min-width: 768px) {
    .uk-thumbnav-div {
        display: none !important;
    }
}

@media (max-width: 767px) {
    #product-detail .product-info-row {

        .product-main-img {
            /*grid-row: 2 / 3;*/
            grid-row: 1 / 2;
            grid-column: 1 / 5;
            max-width: unset;

            .productgallerymainimg {
                height: 100%;
                /*height: calc(100% - 60px);*/
            }

            .productgallerymainimgnoimg {
                height: calc(100% - 100px);
            }

            .productgallerymainimgwiththumbs {
                height: calc(100% - 100px);
            }
            .productgallerymainimg .uk-slider > div:nth-child(2) {
                display: none !important;
            }

            .productgallerymainimg .uk-slider > div:nth-child(2).uk-thumbnav-div-no-img {
                display: block !important;
                margin-bottom: 100px;
            }


            .productgallerymainimg + div {
                display: none !important;
            }
        }

        .uk-thumbnav-div > .uk-thumbnav {
            margin-left: 0 !important;
        }

        .uk-thumbnav-div > .uk-thumbnav > li {
            border: 1px solid #AF8A4E;
            background: #FFF;
            margin: 10px 5px;
            padding: 0 !important;
        }

        .uk-thumbnav-div > .uk-thumbnav > li img {
            height: 70px;
            object-fit: contain;
        }

        .product-info {
            /*grid-row: 1 / 2;*/
            grid-row: 2 / 3;
            grid-column: 1 / 5;
            /*grid-column: 1 / 4;*/
            max-width: unset;
        }

        .detailed-fabric-image-div {
            grid-row: 2 / 3;
            grid-column: 4 / 5;
            max-width: unset;
        }

        .product-info-row .image-container img {
            min-height: 100%;
            height: 300px !important;
        }

    }

 /*   #product-detail .product-info {
        padding: 20px;
    }*/

    .cart-item-block .image-container {
        text-align: center;
    }

    .cart-item-block .image-container img {
        /*max-height: 200px;
        object-fit: contain;*/
        width: 120px !important;
        height: 120px;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
    }


}




